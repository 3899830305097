import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PublicWebsiteComponent } from './public-website.component';
import { PublicSharedModule } from './public-shared/public-shared.module';
import { PublicWebsiteRoutingModule } from './public-website-routing.module';

@NgModule({
  declarations: [
    PublicWebsiteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    PublicSharedModule,
    PublicWebsiteRoutingModule,
  ],
})
export class PublicWebsiteModule { }
