<div *ngIf="!fieldObject.isStaticUI" class="form-group row m-0 mb-3">
  <div class="col-12">
    <label>Taxes</label>
    <input type="number" class="form-control" 
           [formControl]="formControlRef"
           (change)="valueChange($event)" />
    <small class="form-error form-error-hidden"
           [ngClass]="{'form-error-hidden': !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
                     'form-error-visible': fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
      {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
    </small>
  </div>
</div>

<div class="form-group row m-0 mb-3" *ngIf="fieldObject.isStaticUI">
  <input type="number" class="form-control" min="1"
         [formControl]="formControlRef" (change)="valueChange($event)" />
  <small class="form-error form-error-hidden"
         [ngClass]="{'form-error-hidden': !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible': fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
