import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class PaymentNotifierService {
  //Service Created To Let The Contract Verification Step Recieve The Changes When The Contract Form is Submitted

  //Subject To Works as Emiter
  PaymentPicked=new Subject<string>();
  qassatlyPikced=new BehaviorSubject<boolean>(false);
  amanPikced=new BehaviorSubject<boolean>(false);

  //Function To Emit Value Or Push Value
  pushPaymentNotification(paymentChoosed:string){

    this.PaymentPicked.next(paymentChoosed);
  }
  pushQassatlyNotification(isQassatlyPicked:boolean){
    this.qassatlyPikced.next(isQassatlyPicked);
  }
  pushAmanNotification(isAmanPicked:boolean){
    this.amanPikced.next(isAmanPicked);
  }

  //Function To Receive Value When Value is Emited
  getPaymentNotification():Observable<string>{
    return this.PaymentPicked;
  }
  getQassatlyPaymentNotification():Observable<boolean>{
    return this.qassatlyPikced;
  }
  getAmanPaymentNotification():Observable<boolean>{
    return this.amanPikced;
  }
}
