import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-tel',
  templateUrl: './tel.component.html',
  styles: []
})
export class TelComponent extends BaseField implements OnInit {
  edit = false;
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
     super();
  }
  async ngOnInit() {
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      if (fieldObject.fieldNameAr) {
        return fieldObject.fieldNameAr;
      } else {
        return fieldObject.fieldName;
      }
    } else {
      return fieldObject.fieldName;
    }
  }
}
