import { Injectable } from "@angular/core";
import constantURL from "../constantURL";
import { BaseLoggedInPostService } from "./base-logged-in-post.service";

@Injectable({
  providedIn: "root"
})
export class SendMobileVerifyService extends BaseLoggedInPostService {
  _path = constantURL.settings.profile.basicInfo.sendMobileVerify;
  processResult() {
    if (this._result.token) {
      localStorage.setItem("token", this._result.token);
    }
  }
}
