<div class="form-group" *ngIf="arrayOfOptions">
  <app-select-box-search [defaultValue]="defaultValue" [list]="arrayOfOptions" [searchKey]="searchKey"
                         [placeholder]="translateFieldName(fieldObject) +' ..'"
                         (valueChange)="selectedValue($event)" >
  </app-select-box-search>
  <mat-error class="text-danger" *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>

</div>
