import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-no-label-text-area',
  templateUrl: './no-label-text-area.component.html',
  styleUrls: ['./no-label-text-area.component.scss']
})
export class NoLabelTextAreaComponent extends BaseField implements OnInit {
  edit = false;

  constructor( private activatedRoute: ActivatedRoute) {
    super();
  }

  async ngOnInit() {
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
  }
}
