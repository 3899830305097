import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetBusinessProfileService } from '../../services/get-business-profile.service';
import { BehaviorSubjectConfirmationService } from '../../services/behavior-subject-confirmation.service';
import { ContractStatusNotifierService } from 'src/app/dashboard/seller/my-account/services/contractStatusNotifier.service';
import { AuthService } from '../../services/auth.service';
import { OpenContractService } from '../../services/open-contract.service';

@Component({
  selector: 'app-seller-contract-confirmation',
  templateUrl: './seller-contract-confirmation.component.html',
  styleUrls: ['./seller-contract-confirmation.component.scss']
})
export class SellerContractConfirmationComponent implements OnInit {
  isListOpened = true;
  canOpenList = true;
  contractConfirmed = false;
  isOwner = false;
  business: any = {};

  constructor(
    private route: Router,
    private BehaviorSubjectActivations: BehaviorSubjectConfirmationService,
    private businessProfile: GetBusinessProfileService,
    private contractServNotifier:ContractStatusNotifierService,
    private auth:AuthService,
    private openContractService: OpenContractService,
  ) {
    route.events.subscribe((value) => {
      this.isListOpened = true
    })
    this.BehaviorSubjectActivations.renderData.subscribe(async (ele) => {
      await this.ngOnInit();
    })
  }

  async ngOnInit() {
    this.contractServNotifier.getStatusNotification().subscribe(res=>{
      this.business['contractStatus']=res;
      this.contractConfirmed = this.business['contractStatus'] === 'confirmed';
    })
    setTimeout(async () => {
      this.isOwner = await this.auth.isOwner()
      let business: any = await this.businessProfile.make();
      if (!!this.isOwner && !!business && business.Business) {
        this.business = business.Business;
        this.contractConfirmed = this.business['contractStatus'] === 'confirmed';
        if(this.business['contractStatus']=='shipped'){
          this.isListOpened=false;
        }
      }
    }, 1000);
  }

  toggelList() {
    if (this.canOpenList) {
      this.isListOpened = !this.isListOpened;
    }
  }

  openContract() {
    if (this.business['contractStatus'] !== 'confirmed') {
      this.route.navigate(['seller/dashboard']);
      this.openContractService.contractStatus = true;
    }
  }

  calculatePercentage() {
    switch (this.business['contractStatus']) {
      case 'empty':
        return 0;
      case 'submitted':
        return 50;
      default:
        return 100;
    }
  }
}
