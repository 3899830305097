import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-taxes-type',
  templateUrl: './taxes-type.component.html',
  styleUrls: ['./taxes-type.component.scss']
})
export class TaxesTypeComponent extends BaseField implements OnInit {
  arrayOfOptions: string[] = [];

  constructor(public translate: TranslateService,
    private languageService: LanguageService) {
    super();
  }

  async ngOnInit() {
    if (localStorage.getItem("language") === "ar") {
      this.arrayOfOptions = [
        'نسبة',
        'ثابتة'
      ];
    } else {
      this.arrayOfOptions = [
        'Percentage',
        'Flat'
      ];
    }
    this.languageService.languageUpdated.subscribe((lang) => {
      if (lang === "ar") {
        this.arrayOfOptions = [
          'نسبة',
          'ثابتة'
        ];
      } else {
        this.arrayOfOptions = [
          'Percentage',
          'Flat'
        ];
      }
    })
  }
}
