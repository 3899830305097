import { Component } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styles: []
})
export class FormBuilderComponent extends BaseField {
  addingFormBuilderStep(formBuilderValue: any) {
    const formBuilderStep: any = [];
    formBuilderValue.ctrls.forEach((ctrl: any) => {
      if(ctrl.fieldLabel) {
      formBuilderStep.push(
        {
          id: ctrl.id,
          fieldName: ctrl.fieldLabel,
          fieldType: ctrl.fieldTypes,
          value: ctrl.selectionGroup.option,
          fieldRequired: ctrl.fieldRequired
        }
      );
      }
    });
    this.formControlRef.setValue(formBuilderStep);
  }
}
