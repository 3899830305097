<div class="overlay" id="org-popup" style="display: none" (click)="closePopup()">
  <div class="collapse-menu card border border-primary shadow-sm" id="org-collapseMenu">
    <div class="card-header">
      <h4 class="text-primary">
        {{ currentBusinessName }}
      </h4>
    </div>
    <div class="list-group listgrup-flush" role="group">
      <button *ngFor="let org of data" type="button"
        class="list-group-item list-group-item-action rounded-0 {{ org.businessName === 'Main Business' ? 'text-primary' : '' }}"
        (click)="switchBassiness(org)">
        {{org.businessName}}
      </button>
    </div>
  </div>
</div>
