import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language.service';
import {Router, Event,NavigationEnd } from '@angular/router';
import { RoutingService } from './shared/services/routing.service';
import Cookies, { CookieAttributes }  from 'js-cookie';
import { DeleteUserSessionService } from './public-website/public/services/delete-user-session.service';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'my-app';
  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private router :Router,
    private routeService : RoutingService,
    private deleteUserSessionService: DeleteUserSessionService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformID
  ) {
    if(isPlatformBrowser(this.platformID)){
      const lng = localStorage.getItem('language');
      if (!lng) {
        this.translate.use(this.translate.getDefaultLang());
        localStorage.setItem('language', this.translate.getDefaultLang());
      }
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.routeService.saveRoute(event.url)
    }
    })
    if(this.authService.getToken() && !this.authService.authListener.observers.length){
      this.deleteUserSessionService.deleteSessionOnLogoutSubscription();
    }
  }
}
