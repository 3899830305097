import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class MandatoryFieldsService {
  isMandatory(mandatoryFields: any[], field: any): boolean {
    for (const mandatoryField of mandatoryFields) {
      if (field == mandatoryField) {
        return true;
      }
    }
    return false;
  }
}
