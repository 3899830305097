import { Injectable } from '@angular/core';
import constantURL from '../../../shared/constantURL';
import { BaseLoggedInPostService } from 'src/app/shared/services/base-logged-in-post.service';

import { take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DeleteUserSessionService extends BaseLoggedInPostService {
  _path = constantURL.auth.session.delete;

  _make(): Promise<any> {
      this._headers = new HttpHeaders({
        'x-url': this.router.url.split('?')[0],
      });
    return super._make();
  }

  deleteSessionOnLogoutSubscription() {
    this.authService.authListener
      .pipe(
        take(1) // Take only the first emitted value and then unsubscribe
      )
      .subscribe(async (isLoggedIn: boolean) => {
        if (isLoggedIn) return;
        try {
          await this.make();
        } catch (error) {
          console.log("Error could't delete your session", error);
        }
      });
  }
}
