import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent extends BaseField implements OnInit {
  taxesValue = null;
  taxesType: any;
  outPutJson: any;
  Error = false;
  valueComponent: any;

  ngOnInit() {
    this.finalizeOutPut();
    this.formControlRef.value
  }

  valueChange(event: any) {
    this.setValue(event.target.value);
  }

  typeChange(event: any) {
    this.setType(event.value);
  }

  setValue(value: any) {
    this.taxesValue = value;
    if (value == '') {
      this.taxesValue = null;
    }
    if (this.isValid(value)) {

      this.Error = false;
      this.finalizeOutPut();
    } else {

      // this.valueComponent.setErrors({ 'invalid': 'invalid' });
      // this.Error = true;
    }
  }

  setType(value: any) {
    this.taxesType = value;
    this.finalizeOutPut();
  }

  hasErrors() {
    return this.Error;
  }

  finalizeOutPut() {
    this.outPutJson = {
      value: this.taxesValue,
      type: this.taxesType
    };

  }

  isValid(value: any) {
    if (value < 0 || value == '') {

      return false;
    } else {
      return true;
    }
  }
}
