import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BuyerInfoEditService } from '../../services/buyer-info-edit.service';
import { FetchFormfieldstypesService } from '../../services/fetch-form-fields-types.service';
import { BaseField } from '../../product/baseField';
import { TranslateService } from '@ngx-translate/core';
import { ProductStepHandlingService } from 'src/app/dashboard/seller/payment-pages/services/product-step-handling.service';

@Component({
  selector: 'app-form-builder-fielditem',
  templateUrl: './form-builder-fielditem.component.html',
  styleUrls: ['./form-builder-fielditem.component.scss']
})
export class FormBuilderFielditemComponent extends BaseField implements OnInit {
  @Output() formBuilderSave = new EventEmitter();
  fieldTypesArray: any[] = [];
  dublicetIndex: number = -1;
  errorType: string = 'basic';
  formBuilderItemForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private getFormfieldstypes: FetchFormfieldstypesService,
    private editBuyerInfoService: BuyerInfoEditService,
    private translate: TranslateService,
    public stepHandlerService: ProductStepHandlingService,
  ) { super(); }

  async ngOnInit() {
    this.formBuilderItemForm = this.fb.group({
      ctrls: this.fb.array([])
    });
    if(this.formFieldControls.length==0){
      this.addControl();
    }
    const value = this.editBuyerInfoService.getValue();
    this.setValueToForms(value);

    const availableFieldTypes = await this.getFormfieldstypes.make();
    availableFieldTypes.availableFieldTypes.forEach((el: any) => {
      switch (el.id) {
        case 1:
          el.displayNameAr = 'نص';
          break;
        case 2:
          el.displayNameAr = 'نص مطول';
          break;
        case 3:
          el.displayNameAr = 'تبديل';
          break;
        case 4:
          el.displayNameAr = 'رقم';
          break;
        case 18:
          el.displayNameAr = 'إختيارات';
          break;
        case 19:
          el.displayNameAr = 'مربعات الاختيار';
          break;
        case 33:
          el.displayNameAr = 'تحميل ملف';
          break;
        case 34:
          el.displayNameAr = 'التاريخ والوقت';
          break;
        case 35:
          el.displayNameAr = 'وقت فقط';
          break;
        case 36:
          el.displayNameAr = 'تاريخ الآن';
          break;
        default:
          break;
      }
      this.fieldTypesArray.push({
        id: el.id,
        name: el.displayName,
        nameAr: el.displayNameAr
      });
    });
    this.checkRecreatedFields();
  }

  getFieldTypeName(fieldType: any) {
    if (this.translate.currentLang === 'ar') {
      return fieldType.nameAr;
    } else {
      return fieldType.name;
    }
  }

  checkRecreatedFields() {
    this.formFieldControls.valueChanges.subscribe((event: any) => {
      let formValues = this.formFieldControls.value;
      let fieldNameMap: any = {};
      this.dublicetIndex = -1;
      this.errorType = 'basic';
      let defualtFieldNameMap: any = { Name: true, Email: true, Phone: true }
      for (let i = 0; i < formValues.length; i++) {
        if (fieldNameMap[formValues[i].fieldLabel]) {
          this.dublicetIndex = i;
          this.errorType = 'extra';
        } else if (defualtFieldNameMap[formValues[i].fieldLabel]) {
          this.dublicetIndex = i;
          this.errorType = 'basic';
        } else {
          fieldNameMap[formValues[i].fieldLabel] = true;
        }
      }
    });
  }

  setValueToForms(value: any) {
    if (value) {
      // create as many controls as there are Fields
      for (let val of value)
        this.addControl();

      // fill the forms
      this.formBuilderItemForm.controls.ctrls.patchValue(value)

      // notify listeners
      this.onSave({})
    } else {
      // this.addControl()
    }
  }

  get formFieldControls(): any {
    return this.formBuilderItemForm.get('ctrls') as FormArray;
  }

  addControl() {
    this.formFieldControls.push(
      this.fb.group({
        id: this.fb.control(''),
        fieldTypes: this.fb.control('', [Validators.required]),
        fieldLabel: this.fb.control('', [Validators.required]),
        selectionGroup: this.fb.group({
          option: this.fb.array([this.fb.group({
            id: this.fb.control(''),
            name: this.fb.control('')
          })])
        }),
        fieldRequired: this.fb.control(false)
      })
    );
  }

  deleteControl(index: number) {
    if (this.formFieldControls.length > 1) {
      this.formFieldControls.removeAt(index);
    }
  }

  addOptionControl(outerIndex: number) {
    (<FormArray>(
      this.formFieldControls.controls[outerIndex]['controls'].selectionGroup[
        'controls'
      ].option
    )).push(this.fb.group({
      id: this.fb.control(''),
      name: this.fb.control('')
    }));
  }

  deleteOptionControl(outerIndex: number, innerIndex: number) {
    if ((<FormArray>(this.formFieldControls.controls[outerIndex]['controls']
          .selectionGroup['controls'].option)).length > 1) {
      (<FormArray>(
        this.formFieldControls.controls[outerIndex]['controls'].selectionGroup[
          'controls'
        ].option
      )).removeAt(innerIndex);
    }
  }

  onSave(event: any) {
    this.formBuilderSave.emit(this.formBuilderItemForm.value);
    this.stepHandlerService.isBuyerInfoFormValid.emit(this.formBuilderItemForm.valid)
  }

  shouldShow(ctrl: any) {
    const label = ctrl.value.fieldLabel;
    return label != "Name" && label != "Email" && label != "Phone";
  }
}
