import { Injectable } from '@angular/core';
import { ACTIVATION_STEPS } from '../activationSteps';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ActivationStepsService {
  constructor(private auth:AuthService){}
  private businessProfileStep!: ActivationStep;
  private verifyFacebookStep!: ActivationStep;
  private phoneAndEmailVerifyStep!: ActivationStep;
  private withdrawalActivationStep!: ActivationStep;
  private activationSteps: any;
  private progressPeersentage = 0;

  async setActivationSteps(activationSteps: any = null) {
    let steps = await this.auth.getBusiness() as any;
    steps = steps ? steps.activationSteps : null;
    if (activationSteps) {
      this.activationSteps = activationSteps;
    } else if (steps) {
      this.activationSteps = JSON.parse(steps);
    } else {
      this.activationSteps = [
        {
          activationStep: ACTIVATION_STEPS.BUSINESS_PROFILE,
          activationStatus: false
        },
        {
          activationStep: ACTIVATION_STEPS.VERIFY_FACEBOOK_PAGE,
          activationStatus: false
        },
        {
          activationStep: ACTIVATION_STEPS.PHONE_EMAIL_VERIFICATION,
          activationStatus: false
        },
        {
          activationStep: ACTIVATION_STEPS.WITHDRAWAL_ACTIVATION,
          activationStatus: false
        },
        {
          activationStep: ACTIVATION_STEPS.FACEBOOK_ACTIVATION,
          activationStatus: false
        }
      ]
    }
    //console.log(this.activationSteps, 'this.activationSteps');
    this.calcProgressPeersentage();
  }
  set setBusinessProfileStep (businessProfileStep: ActivationStep) {
    this.businessProfileStep = businessProfileStep
    this.activationSteps.find((activation: any) => activation.activationStep == businessProfileStep.activationStep).activationStatus = this.businessProfileStep.activationStatus;
    this.updateLocalStorage();
  }
  get getBusinessProfileStep(): ActivationStep {
    return this.activationSteps && this.activationSteps.find((activation: any) => activation.activationStep == ACTIVATION_STEPS.BUSINESS_PROFILE).activationStatus;
  }

  set setVerifyFacebookStep(verifyFacebookStep: ActivationStep) {
    this.verifyFacebookStep = verifyFacebookStep
    this.activationSteps.find((activation: any) => activation.activationStep == verifyFacebookStep.activationStep).activationStatus = this.verifyFacebookStep.activationStatus;
    this.updateLocalStorage();
  }
  get getVerifyFacebookStep(): boolean {
    return this.activationSteps && this.activationSteps.find((activation: any) => activation.activationStep == ACTIVATION_STEPS.FACEBOOK_ACTIVATION).activationStatus;
  }

  set setPhoneAndEmailVerifyStep(phoneAndEmailVerifyStep: ActivationStep) {
    this.phoneAndEmailVerifyStep = phoneAndEmailVerifyStep
    if (this.phoneAndEmailVerifyStep && this.activationSteps) {
      this.activationSteps.find((activation: any) => activation.activationStep == phoneAndEmailVerifyStep.activationStep).activationStatus = this.phoneAndEmailVerifyStep.activationStatus;
      this.updateLocalStorage();
    }
  }
  get getPhoneAndEmailVerifyStep(): ActivationStep {
    return this.activationSteps && this.activationSteps.find((activation: any) => activation.activationStep == ACTIVATION_STEPS.PHONE_EMAIL_VERIFICATION).activationStatus;
  }

  set setWithdrawalActivationStep(withdrawalActivationStep: ActivationStep) {
    this.withdrawalActivationStep = withdrawalActivationStep
    this.activationSteps.find((activation: any) => activation.activationStep == withdrawalActivationStep.activationStep).activationStatus = this.withdrawalActivationStep.activationStatus;
    this.updateLocalStorage();
  }
  get getWithdrawalActivationStep(): ActivationStep {
    return this.activationSteps && this.activationSteps.find((activation: any) => activation.activationStep == ACTIVATION_STEPS.WITHDRAWAL_ACTIVATION).activationStatus;
  }

  get persentage(): number {
    return this.progressPeersentage;
  }

  calcProgressPeersentage() {
    this.progressPeersentage = 0;
    this.activationSteps.forEach((step: any) => {
      if (step.activationStatus) this.progressPeersentage++;
    });
    this.progressPeersentage = (this.progressPeersentage / 4) * 100;
  }

  async updateLocalStorage() {
    let business = await this.auth.getBusiness();
    business.activationSteps = JSON.stringify(this.activationSteps);
    this.auth.setBusiness(business);
    this.calcProgressPeersentage();
  }
}

export interface ActivationStep {
  activationStep: string;
  activationStatus: boolean;
}
