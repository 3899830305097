import { Injectable, EventEmitter } from '@angular/core';
import { SendPhoneVerifiedService } from './send-phone-verified.service';
import { SendMobileVerifyService } from './send-verify-mobile.service';

@Injectable({
  providedIn: "root"
})

export class handleMobileVerificationService {

  constructor(
    private sendMobileVerifyService: SendMobileVerifyService,
    private sendPhoneVerifService: SendPhoneVerifiedService,
  ) { }
  async sendCode(phone=null){
    let object={};
    if(phone) object['phone']=phone;
    this.sendMobileVerifyService.setParams(object);
    await this.sendMobileVerifyService.make()
  }
  async verifyCode(inputCode){

    this.sendPhoneVerifService.setParams({
      verified: inputCode,
    })
    return await this.sendPhoneVerifService.make();

  }
}
