<!-- Navbar STart -->
<header id="topnav" class="sticky shadow-sm px-5">
  <div class="container">
    <div class="row mx-0 justify-content-between align-items-center">
      <div class="col-auto">
        <!-- Logo container-->
        <a class="logo" routerLink="/">
          <img *ngIf="currentLanguages == 'en'" src="../../assets/images/easykash-logo.png" alt="Logo" height="45px">
          <img *ngIf="currentLanguages == 'ar'" src="../../assets/images/easykash-logo-Ar.png" alt="Logo" height="45px">
        </a>
      </div>

      <div id="navigation" class="col-auto hide-on-mobile-view">
        <!-- Navigation Menu-->

        <ul class="navigation-menu">
          <li class="sub-menu-item"><a routerLink="/">{{ 'Home.Home' | translate }}</a></li>
          <div class="lang">
            <!-- <a href="javascript:void(0)"   ></a> -->
            <button class="btn has-submenu parent-parent-menu-item" mat-button [matMenuTriggerFor]="megamenu">{{ 'Footer.Services' | translate }}
              <span class="menu-arrow"></span></button>
          </div>
          <mat-menu #megamenu="matMenu" xPosition="after">
            <ul class="megamenu">

              <li><a routerLink="/services/invoice" class="sub-menu-item">
                  <i class="fas fa-file-invoice-dollar text-primary"></i>{{ 'Home.Services.Invoice' | translate }}</a></li>
              <li><a routerLink="/services/subscription" class="sub-menu-item">
                  <i class="fas fa-comment-dollar text-primary"></i>{{'Home.Services.Subscription' | translate}}</a></li>
              <li><a routerLink="/services/customPayment" class="sub-menu-item">
                  <i class="fas fa-receipt text-primary"></i>{{'Home.Services.CustomPayment' | translate}}</a></li>
              <li><a routerLink="/services/product" class="sub-menu-item">
                  <i class="fas fa-store text-primary"></i>{{'Home.Services.Product' | translate}}</a></li>
              <li><a routerLink="/services/quickCash" class="sub-menu-item">
                  <i class="fas fa-wallet text-primary"></i>{{'Home.Services.QuickCash' | translate}}</a></li>
              <li><a routerLink="/services/quickPayment" class="sub-menu-item">
                  <i class="fa fa-credit-card text-primary"></i>{{'Home.Services.QuickPayment' | translate}}</a></li>
              <li><a routerLink="/services/e-Reservation" class="sub-menu-item">
                  <i class="fa fa-calendar-check text-primary"></i>{{'Home.Services.EReservation' | translate}}</a></li>
              <li><a routerLink="/services/booking&Events" class="sub-menu-item">
                  <i class="fas fa-calendar-plus text-primary"></i>{{'Home.Services.BookingEvents' | translate}}</a></li>
              <li><a routerLink="/services/installmentOptions" class="sub-menu-item">
                  <i class="fa fa-building-columns text-primary"></i>{{'Home.Services.InstallmentOptions' | translate}}</a></li>

            </ul>

          </mat-menu>

          <li class="has-submenu parent-parent-menu-item"><a routerLink="/about-us">{{ 'Footer.AboutUs' | translate }}</a></li>
          <li class="has-submenu parent-parent-menu-item"><a routerLink="/contact">{{ 'Home.Support' | translate }}</a></li>
          <li class="has-submenu parent-parent-menu-item"><a routerLink="/products/browse">{{ 'Home.Browse' | translate }}</a></li>
          <li class="has-submenu parent-parent-menu-item"><a routerLink="/events">{{ 'Home.Events' | translate }}</a></li>

          <div class="lang">
            <button mat-button [matMenuTriggerFor]="menu" class="btn">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" class="global-icon">
                  <path fill="#0f75bc"
                    d="M17.071 2.929C15.182 1.04 12.671 0 10 0 7.329 0 4.818 1.04 2.929 2.929 1.04 4.818 0 7.329 0 10c0 2.671 1.04 5.182 2.929 7.071C4.818 18.96 7.329 20 10 20c2.671 0 5.182-1.04 7.071-2.929C18.96 15.182 20 12.671 20 10c0-2.671-1.04-5.182-2.929-7.071zM8.103 1.378c-.335.326-.697.715-1.06 1.167-.627.783-1.15 1.625-1.558 2.511H2.689c1.249-1.84 3.174-3.186 5.414-3.678zM2.02 6.228H5.02c-.341 1.02-.54 2.087-.592 3.186H1.192c.074-1.133.364-2.208.827-3.186zm-.827 4.358h3.236c.052 1.099.25 2.166.592 3.186h-3c-.464-.978-.754-2.053-.828-3.186zm1.497 4.358h2.796c.408.885.93 1.728 1.558 2.51.362.453.725.842 1.06 1.168-2.24-.492-4.165-1.838-5.414-3.678zm6.725 3.29c-.417-.36-.936-.863-1.457-1.512-.455-.568-.845-1.162-1.17-1.778h2.627v3.29zm0-4.462H6.26c-.381-1.009-.6-2.073-.658-3.186h3.813v3.186zm0-4.358H5.601c.057-1.113.277-2.177.658-3.186h3.155v3.186zm0-4.358H6.787c.318-.604.699-1.185 1.142-1.743.528-.663 1.059-1.178 1.485-1.546v3.29zm9.394 4.358h-3.236c-.052-1.099-.25-2.166-.592-3.186h3c.464.978.754 2.053.828 3.186zm-1.497-4.358h-2.796c-.408-.885-.93-1.728-1.558-2.51-.362-.453-.725-.842-1.06-1.168 2.24.492 4.165 1.838 5.414 3.678zm-6.725-3.29c.417.36.936.863 1.457 1.512.455.568.845 1.162 1.17 1.778h-2.627v-3.29zm0 4.462h3.155c.381 1.009.6 2.073.658 3.186h-3.813V6.228zm0 4.358h3.813c-.057 1.113-.277 2.177-.658 3.186h-3.155v-3.186zm0 7.647v-3.29h2.627c-.318.605-.699 1.186-1.142 1.744-.528.663-1.059 1.178-1.485 1.546zm1.31.39c.336-.327.699-.716 1.061-1.168.628-.783 1.15-1.625 1.558-2.511h2.796c-1.249 1.84-3.174 3.186-5.414 3.678zm6.085-4.851H14.98c.341-1.02.54-2.087.592-3.186h3.236c-.074 1.133-.364 2.208-.827 3.186z">
                  </path>
                </svg>
                <span class="lang-name">{{currentLanguages.toUpperCase()}}</span>
              </span>
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let lang of languages"
              (click)="changeLanguage(lang)">{{lang.toUpperCase()}}</button>
          </mat-menu>

          <div *ngIf="!authenticated" class="loginBtn my-3">
            <a routerLink="/signup" queryParamsHandling="merge" class="btn btn-outline-primary mx-1">{{
              'Home.Signup' |
              translate }}</a>
            <a routerLink="/login" class="btn btn-primary mx-1">{{ 'Home.Login' | translate }}</a>
          </div>
          <div *ngIf="authenticated" class="my-3 loginBtn ">
            <button class="btn btn-outline-primary mx-1" (click)="logout()">{{ 'Home.Logout' | translate }}</button>
            <button (click)="navigateToDashboard()" class="btn btn-primary mx-1">{{ 'Seller.Dashboard.Dashboard' |
              translate }}</button>
          </div>

        </ul>
      </div>

      <div class="col-auto menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
    </div>

  </div>

</header>
