<div class="mt-0">
  <input ngx-google-places-autocomplete #places="ngx-places" placeholder="Enter location"
         (onAddressChange)="onChange($event)" class="form-control mb-2"/>
  <agm-map style="height: 62vh;" [latitude]="coordinates.latitude" [longitude]="coordinates.longitude"
           (mapClick)="placeMarker($event)">
    <agm-marker (dragEnd)="placeMarker($event)" [latitude]="coordinates.latitude" [longitude]="coordinates.longitude" [markerDraggable]="true">
    </agm-marker>
  </agm-map>

  <form #coordinatesForm="ngForm" fxLayout="column" fxLayoutGap="1.2rem">
    <div class="row d-none">
      <div class="column col-6">
        <mat-form-field fxFlex="100" appearance="outline">
          <input matInput placeholder="Latitude" style="width: 100%" [(ngModel)]="coordinates.latitude" type="number"
                 name="number" #number="ngModel" />
        </mat-form-field>
      </div>
      <div class="column col-6">
        <mat-form-field fxFlex="100" appearance="outline" style="width: 100%">
          <input matInput type="number" placeholder="Longitude" [(ngModel)]="coordinates.longitude" name="number"
                 #number="ngModel" />
        </mat-form-field>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary mt-2" type="submit"
              (click)="submit()" [disabled]="coordinatesForm.invalid">
        Add Location
      </button>
    </div>
  </form>
</div>
