<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ DateName }}</mat-label>
  <input  *ngIf="hasMinAsTodayDate; else fieldWithNoMinDate" id="inDate" type="datetime" class="form-control" matInput [min]="minDate" [max]="maxDate"
         [formControl]="formControlRef" [matDatepicker]="picker" placeholder="MM/DD/YYYY"
         (dateChange)="updateDate($event)" (click)="picker.open()" />
  <ng-template #fieldWithNoMinDate>
    <input id="inDate" type="datetime" class="form-control" matInput [max]="maxDate" [formControl]="formControlRef"
            [matDatepicker]="picker" placeholder="MM/DD/YYYY" (dateChange)="updateDate($event)" (click)="picker.open()" />
  </ng-template>
  <mat-datepicker-toggle class="position-absolute" style="right: 0px;top: 0px"
                         [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker startView="month" [startAt]="Datenow" [for]="picker"></mat-datepicker>
  <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
