import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { BaseField } from '../../baseField';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { ImageChangedNotifierService } from 'src/app/shared/product/components/image/image-changed.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent extends BaseField implements OnInit, OnDestroy {

  @ViewChild('fileInput') FileInputRef!: ElementRef;

  selectedFile: any;
  emptyImage = true;
  imageSrc: any;
  hasOldImage = false;
  inDrag = false;
  mandatoryImages = ['Product image', 'Event image','Reservation image'];
  mandatory = false;
  subscriptions: Subscription[] = [];
  @Input() advBannerValidation=false;
  img = new Image();
  imgChanged:Subject<any>=new Subject<any>();
  constructor(
    private ng2ImgMax: Ng2ImgMaxService,
    public sanitizer: DomSanitizer,
    private mandatoryFieldsService: MandatoryFieldsService,
    private imgeChaned:ImageChangedNotifierService
  ) {
    super();
  }

  ngOnInit() {
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryImages, this.fieldObject.fieldName) || false;
    if (this.formControlRef.value && typeof this.formControlRef.value === 'string') {
      this.hasOldImage = true;
      this.imageSrc = this.formControlRef.value;
      this.emptyImage = false;
    }

    this.subscriptions.push(this.formControlRef.valueChanges.subscribe(value => {
      if (value instanceof File) {
        this.hasOldImage = false;
        return;
      } else if (typeof value === 'string') {
        this.hasOldImage = true;
        this.imageSrc = value;
        this.emptyImage = false;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  clearImage() {
    this.selectedFile = null;
    this.imageSrc = null;
    this.emptyImage = true;
    this.FileInputRef.nativeElement.value = '';
    this.formControlRef.setValue('');
    console.log('empty image should be set to true!', this.emptyImage);
  }

  onDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.inDrag = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.inDrag = false;
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.inDrag = false;
    this.selectedFile = event.dataTransfer.files[0];
    this.handleSelectedFile();
  }

  onFileInputChange(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.handleSelectedFile();
  }

  private handleSelectedFile() {
    this.subscriptions.push(this.ng2ImgMax.compressImage(this.selectedFile, 0.200, true).subscribe(
      result => {

        this.emptyImage = false;
        const image = new File([result], result.name);

        this.selectedFile = image;
        this.getImagePreview(image);
        this.formControlRef.setValue(image);
      },
      error => {
        console.log('there is an', error);
      }
    ));
  }

  private getImagePreview(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event:any) => {
      this.imageSrc = reader.result;
      this.img.src = event.target.result;
    };
      this.img.onload = () => {
      this.imgeChaned.pushImageChanged({img:this.img,obj:this});
      }

  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
  onClickChangeImage(event:any,input:HTMLInputElement){
    event.preventDefault();
    input.click()
   }
}
