import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-rounded-container',
  templateUrl: './public-rounded-container.component.html',
  styleUrls: ['./public-rounded-container.component.scss']
})
export class PublicRoundedContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
