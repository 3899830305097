import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ActivationStatusSubmittedNotifier {

  //Subject To Works as Emiter
  activationStatusSubject=new Subject<string>();

  //Function To Emit Value Or Push Value
  pushStatusNotification(){

    this.activationStatusSubject.next();
  }

  //Function To Receive Value When Value is Emited
  getStatusNotification():Observable<string>{
    return this.activationStatusSubject;
  }
}
