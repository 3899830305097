import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})

export class  OpenContractService {
  private statusChanged: EventEmitter<any> = new EventEmitter();
  private status: any;

  constructor() { }

  get contractStatus(): any {
    return this.status;
  }

  set contractStatus(val: any) {
    this.status = val;
    this.statusChanged.emit(val);
  }
}
