<div class="form-group">
  <mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ translateFieldName(fieldObject) }}</mat-label>
  <input [owlDateTime]="dtRange1" [owlDateTimeTrigger]="dtRange1" [selectMode]="'range'"
         [placeholder]="translateFieldName(fieldObject)"
         class="form-control" [formControl]="formControlRef"
         (dateTimeChange)="handleChange($event)" matInput>
  <owl-date-time #dtRange1></owl-date-time>
  <mat-error class="text-danger"
         *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
</div>
