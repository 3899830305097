import { Component, OnInit } from "@angular/core";
import { BaseField } from "../../baseField";
import { GetCurrenciesService } from "./getCurrencies.service";

@Component({
  selector: "app-currency",
  templateUrl: "./currency.component.html",
  styles: []
})
export class CurrencyComponent extends BaseField implements OnInit {
  currenciesNames: any = [];
  egp: any;
  async ngOnInit() {
    const currencies = await this.getCurrencies.make();
    //   this.currenciesNames = currencies.name;
    currencies.forEach((currency: any) => {
      this.currenciesNames.push(currency.name);
    });

    this.egp = this.currenciesNames[0];
  }
  constructor(private getCurrencies: GetCurrenciesService) {
    super();
  }
}
