import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import constantURL from '../constantURL';
import { BaseLoggedInPostService } from './base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalStatusService extends BaseLoggedInPostService {
  _path = constantURL.seller.GET_WITHDRAWAL_ACTIVATION_STATUS;

  refetchBS = new BehaviorSubject<any>(true);
  refetch = this.refetchBS.asObservable();
}
