import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuyerInfoEditService {
  buyerInfos: any;

  setValue(value: any) {
    this.buyerInfos = value;
  }

  getValue() {
    const value = this.buyerInfos
    this.buyerInfos = undefined;
    return value;
  }
}
