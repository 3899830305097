import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { LoginService } from 'src/app/public-website/public/services/login.service';
import { ReCaptchaService } from 'angular-recaptcha3';
import { DeleteUserSessionService } from 'src/app/public-website/public/services/delete-user-session.service';
import { Router } from '@angular/router';
import { FetchBasicUserInfoService } from '../../my-account/services/fetch-basic-user-info.service';
import { Validators } from 'src/app/shared/validators';
import { User } from 'src/app/dashboard/user';

@Component({
  selector: 'app-confarm-password',
  templateUrl: './confarm-password.component.html',
  styleUrls: ['./confarm-password.component.scss'],
})
export class ConfarmPasswordDialogComponent implements OnInit {
  public loginForm!: FormGroup;
  public formValidator: FormValidatorService = new FormValidatorService();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { businessId: number; businessName: string },
    public dialogRef: MatDialogRef<ConfarmPasswordDialogComponent>,
    private login: LoginService,
    private recaptchaService: ReCaptchaService,
    private router: Router,
    private deleteUserSessionService: DeleteUserSessionService,
    private userInfoService: FetchBasicUserInfoService,
  ) {}

  ngOnInit(): void {
    this.initLoginForm();
  }

  async onLogin() {
    const user = await this.getUserData();
    const password = this.loginForm.value.password;
    const recaptchaToken = await this.recaptchaService.execute({
      action: 'login',
    });
    this.login.setParams({
      email: user.email,
      password,
      recaptchaToken,
      businessId: this.data.businessId,
    });
    try {
      await this.deleteUserSessionService.make();
      const result = await this.login.make();
      if (result === true) {
        this.dialogRef.close();
        location.reload();
        this.router.navigate(['/']);
      }
    } catch (errors) {
      if (errors.faultyRequest) {
        const e = errors.faultyRequest.error;
        this.formValidator.addError('password', 'invalid', e.message);
        this.loginForm.controls['password'].setErrors({
          invalid: e.message,
        });
        this.formValidator.ShowErrors();
      }
      console.log({ errors });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private async getUserData(): Promise<User> {
    const user = await this.userInfoService.make();
    return user;
  }

  private initLoginForm() {
    this.loginForm = this.formValidator.createForm([
      {
        fieldName: 'password',
        defaultValue: null,
        validators: [
          {
            validatorFn: Validators.required,
            message: 'Password cannot be empty',
          },
          {
            validatorFn: Validators.minLength(6),
            message: 'Password cannot be less than 6 characters',
          },
        ],
      },
    ]);
  }
}
