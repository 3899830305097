import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalLoaderService } from './global-loader.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseRequestService {
  _path!: string;
  _params!: {};
  _headers!: {};
  _result: any;
  private _isLoading = new Subject<boolean>();
  private hasLoader = true;
  private emiterPaused = false;

  pauseLoadingEventEmitter() {
    this.emiterPaused = true;
  }

  unpauseLoadingEventEmitter() {
    this.emiterPaused = false;
  }

  getResult() {
    return this._result;
  }
  setResult(r: any) {
    this._result = r;
  }

  getPath() {
    return this._path;
  }

  setPath(path: any) {
    this._path = path;
  }

  getParams() {
    return this._params;
  }

  setParams(params: any) {
    this._params = params;
  }

  getHeaders() {
    return this._headers;
  }

  setHeaders(headers: any) {
    this._headers = headers;
  }

  processResult(response: any) {
    return response;
  }

  deactivateLoader() {
    this.hasLoader = false;
  }

  activateLoader() {
    this.hasLoader = true;
  }

  loader() {
    return this._isLoading;
  }

  preProcessResult(response: HttpResponse<any> | HttpErrorResponse) {
    let action: string;
    if (response instanceof HttpErrorResponse) {
      const message: string | undefined = response.error?.message;
      if (message) {
        action = message.substring(
          message.indexOf("(") + 1,
          message.lastIndexOf(")")
        )
      }
      if(response.error?.name == "UserNotFoundException"){
        throw { faultyRequest: response };
      }
    }
    switch (response.status) {
      case 400:
        throw { faultyRequest: response };
      case 401:
        this.authService.notAllowed();
        break;
      case 404:
        // redirect to 404 page
        this.router.navigate(['/404']);
        break;
      case 410:
        this.authService.userSessionExpired();
        break;
      case 403:
        this.authService.notAllowed(action);
        break;
      case 460:
        throw { validationErrors: response };
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
        // redirect to 501 page
        break;
      default:
        break;
    }
    return this.processResult((response as HttpResponse<any>)?.body);
  }

  async make(): Promise<any> {
    // should start loader here
    if (this.hasLoader) {
      this.gls.open();
    }
    if (!this.emiterPaused) {
      this._isLoading.next(true);
    }
    let res, rej;
    try {
      res = await this._make();
    } catch (err) {
      rej = err;
    }
    // should stop loader here
    if (this.hasLoader) {
      this.gls.close();
    }
    if (!this.emiterPaused) {
      this._isLoading.next(false);
    }
    if (res) {
      return Promise.resolve(res);
    }
    if (rej) {
      return Promise.reject(rej);
    }
  }

  abstract _make(): Promise<any>;
  //abstract async _make(): Promise<any>;

  constructor(
    protected gls: GlobalLoaderService,
     protected router: Router,
     protected authService: AuthService
  ) {}
}
