import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-public-website',
  templateUrl: './public-website.component.html',
  styleUrls: ['./public-website.component.scss']
})

export class PublicWebsiteComponent implements OnInit {
  public language: string = '';

  constructor(@Inject(PLATFORM_ID) private platformID,) {
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformID)){
      // back-to-top
      var mybutton = document.getElementById("back-to-top");
      window.onscroll = function () {
        scrollFunction();
      };
  
      function scrollFunction() {
        if (mybutton != null) {
          if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            mybutton.style.display = "block";
          } else {
            mybutton.style.display = "none";
          }
        }
      }
    }
  }

  topFunction(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
