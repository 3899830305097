import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import paymentOptionsConstant from "src/app/shared/paymentOptionsConstant";
import constantURL from "../../../shared/constantURL";
import { CurrencyExchangeService } from "../../../shared/services/currency-exchange.service";

@Injectable({
  providedIn: 'root',
})
export class ViewProductInfoHandlerService {
  productId: any;
  packageId: any;
  viewInfoObject: any;
  paymentOptionObject = { selection: '' };
  paymentOptionObjectEmitter = new EventEmitter<any>();
  repeat = 1;
  merchantFees = 0;
  mFees = 0;
  mAddFees = false;
  quantity = 0;
  Dividing = 1;

  private _subTotal = 0;

  isOfflinePayment() {
    return (
      this.paymentOptionObject.selection ==
      paymentOptionsConstant.constantPaymentOptions.value.OFFLINE_PAYMENT
    );
  }

  public get subTotal() {
    return this.isOfflinePayment() ? 0 : this._subTotal;
  }

  public set subTotal(value) {
    this._subTotal = value;
  }

  private _total = 0;
  public get total() {
    return this.isOfflinePayment() ? this.fees : this._total;
  }

  public set total(value) {
    this._total = value;
  }

  private _taxes = 0;
  public get taxes() {
    return this.isOfflinePayment()? 0 : this._taxes;
  }
  public set taxes(value) {
    this._taxes = value;
    this.calculateTotal();
  }

  private _roundUpAmount= 0;
  public get roundUpAmount() {
    return this._roundUpAmount;
  }
  public set roundUpAmount(value) {
    this._roundUpAmount = value;
  }

  private _fees = 0;
  public get fees() {
    return this._fees;
  }
  public set fees(value) {
    this._fees = value;
  }

  private _vat = 0;
  public get vat() {
    return  this._vat;
  }
  public set vat(value) {
    this._vat = value;
    this.calculateTotal();
  }

  private _donation = 0;
  public get donation() {
    return this._donation;
  }
  public set donation(value) {
    this._donation = value;
  }

  private _feesWithNoPaymentOptionFees = 0;
  public get feesWithNoPaymentOptionFees() {
    return this._feesWithNoPaymentOptionFees;
  }
  public set feesWithNoPaymentOptionFees(value) {
    this._feesWithNoPaymentOptionFees = value;
  }

  private _date = new Date();
  public get date() {
    return this._date;
  }
  public set date(value) {
    this._date = value;
  }

  private _refNum = '';
  public get refNum() {
    return this._refNum;
  }
  public set refNum(value) {
    this._refNum = value;
  }
  private _installmentValues = [];
  public get installmentValues() {
    return this._installmentValues;
  }
  public set installmentValues(value) {
    this._installmentValues = value;
  }

  feesObjectArrayEmitter = new EventEmitter<Array<any>>();
  feesObjectArray: any[] = [];
  currency: string = '';
  promoDiscount = new BehaviorSubject<number>(0);

  private _discount = 0;
  public get discount() {
    return this.isOfflinePayment() ? 0 : this._discount;
  }
  public set discount(value) {
    const absDiscountValue = Math.abs(value);
    this._discount = absDiscountValue > this.subTotal ? -this.subTotal : value;
    this.calculateTotal();
  }

  BusinessId = 0;
  PromocodeId = null;
  exchangeRates: object[] = [];

  constructor(private exchangeService: CurrencyExchangeService) { }

  async getExchangeRates() {
    this.exchangeRates = await this.exchangeService.make();
  }

  public getExchangeRateToEGP(currency: string) {
    if (!this.exchangeRates) {
      return 1;
    }

    if (currency === 'EGP') {
      return 1;
    } else {
      return this.exchangeRates.reduce((prev, current: any) => {
        if ((current.name as string).includes(currency)) {
          return current.rate;
        }
        return prev;
      }, 1);
    }
  }

  public getExchangedValue(egpValue: number, currency: string): number {
    // console.log(this.getExchangeRateToEGP(currency), currency);
    return +(egpValue / this.getExchangeRateToEGP(currency));
  }

  public getExchangedValueToEgp(egpValue: number, currency: string): number {
    return +(egpValue * this.getExchangeRateToEGP(currency));
  }

  setFeesObjectArray(array: any) {
    this.feesObjectArray = array;
    this.feesObjectArrayEmitter.emit(array);
    this.calculateTotal();
  }
  private calculateTotal() {
    this.total =
      this.subTotal + this.fees + this.discount + this.taxes + this.donation + this.roundUpAmount;
  }

  setProductId(id: any) {
    this.productId = id;
  }

  setViewInfoObject(object: any) {
    this.viewInfoObject = object;
  }

  setPaymentOptionObject(object: any) {
    this.paymentOptionObject = object;
    this.paymentOptionObjectEmitter.emit();
  }

  setRepeat(rvalue: any) {
    this.repeat = rvalue;
  }

  applyPromoCode(percentage: number) {
    this.promoDiscount.next(percentage);
    this.calculateDiscount(percentage);
  }
  private calculateDiscount(percentage: any) {
    this.discount = -(this.subTotal * (percentage / 100));
  }

  getSubmitObject() {
    // let total = this.getExchangedValueToEgp(this._total, this.currency);
    // let subTotal = this.getExchangedValueToEgp(this._subTotal, this.currency);
    // let taxes = this.getExchangedValueToEgp(this._taxes, this.currency);
    // let fees = this.getExchangedValueToEgp(this._fees, this.currency);
    // let discount = this.getExchangedValueToEgp(this._discount, this.currency);

    return {
      subTotal: this._subTotal * this.Dividing,
      discount: this._discount * this.Dividing,
      fees: this._fees * this.Dividing,
      taxes: this._taxes * this.Dividing,
      total: this._total * this.Dividing,
      vat: this._vat * this.Dividing,
      roundUpAmount: this._roundUpAmount,
      quantity: this.quantity,
      redirectUrl: constantURL.BASE_GATE_URL + '/gate',
      paymentOptions: this.paymentOptionObject.selection,
      merchantFees: this.merchantFees * this.Dividing,
      mFees: this.mFees,
      mAddFees: this.mAddFees
    };
  }
}
