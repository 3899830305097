import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToggleLayoutService } from './services/toggle-layout.service';
import { AuthService } from './services/auth.service';
import { BasePostService } from './services/basePostService.service';
import { BehaviorSubjectActivationsService } from './services/behavior-subject-activations.service';
import { BehaviorSubjectConfirmationService } from './services/behavior-subject-confirmation.service';
import { FormValidatorService } from './services/form-validator.service';

import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatOptionModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgmCoreModule } from '@agm/core';
import { MatMenuModule } from '@angular/material/menu';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { BaseReportComponent } from './components/base-report/base-report.component';
import { SelectBoxSearchComponent } from './components/select-box-search/select-box-search.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AnnouncementDialogComponent } from './components/announcement-dialog/announcement-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { TitledModalWithContentComponent } from './components/titled-modal-with-content/titled-modal-with-content.component';
import { CongratulationComponent } from './components/congratulation/congratulation.component';
import { EdittedSuccessfullyComponent } from './components/editted-successfully/editted-successfully.component';
import { FormBuilderFielditemComponent } from './components/form-builder-fielditem/form-builder-fielditem.component';
import { ProductNameComponent } from './product/components/product-name/product-name.component';
import { ShareComponent } from './product/components/share/share.component';
import { ToggleComponent } from './product/components/toggle/toggle.component';
import { PaymentOptionsComponent } from './product/components/payment-options/payment-options.component';
import { NumberComponent } from './product/components/number/number.component';
import { TextComponent } from './product/components/text/text.component';
import { NoLabelTextAreaComponent } from './product/components/no-label-text-area/no-label-text-area.component';
import { QuickCashExpirydateComponent } from './product/components/quick-cash-expirydate/quick-cash-expirydate.component';
import { QassatlyComponent } from './product/components/qassatly/qassatly.component';
import { PaymentPlansComponent } from './product/components/payment-plans/payment-plans.component';
import { DateNowComponent } from './product/components/date-now/date-now.component';
import { TextAreaComponent } from './product/components/text-area/text-area.component';
import { NoLabelToggleComponent } from './product/components/no-label-toggle/no-label-toggle.component';
import { NoLabelSelectListComponent } from './product/components/no-label-select-list/no-label-select-list.component';
import { TaxesComponent } from './product/components/taxes/taxes.component';
import { TaxesTypeComponent } from './product/components/taxes-type/taxes-type.component';
import { TicketComponent } from './product/components/ticket/ticket.component';
import { ImageComponent } from './product/components/image/image.component';
import { ProductCategoriesComponent } from './product/components/product-categories/product-categories.component';
import { DatetimeRangeComponent } from './product/components/datetime-range/datetime-range.component';
import { OpenMapComponent } from './product/components/open-map/open-map.component';
import { GoogleMapComponent } from './product/components/google-map/google-map.component';
import { PhoneVerifyComponent } from './components/phone-verify/phone-verify.component';
import { PasswordConfirmComponent } from './components/password-confirm/password-confirm.component';
import { SelectListComponent } from './product/components/select-list/select-list.component';
import { ProductPriceComponent } from './product/components/product-price/product-price.component';
import { QuickCashSuccessComponent } from './components/quick-cash-success/quick-cash-success.component';
import { HiddenComponent } from './product/components/hidden/hidden.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MapDialogComponent } from './components/map-dialog/map-dialog.component';
import { TicketImageComponent } from './product/components/ticket-image/ticket-image.component';
import { VerifyComponent } from './components/verify/verify.component';
import { ShareEmailComponent } from './product/components/share-email/share-email.component';
import { EmailComponent } from './product/components/email/email.component';
import { TelComponent } from './product/components/tel/tel.component';
import { CheckBoxComponent } from './product/components/check-box/check-box.component';
import { RangeComponent } from './product/components/range/range.component';
import { FileComponent } from './product/components/file/file.component';
import { SearchComponent } from './product/components/search/search.component';
import { WeekComponent } from './product/components/week/week.component';
import { TimeComponent } from './product/components/time/time.component';
import { DateTimeComponent } from './product/components/date-time/date-time.component';
import { FieldGroupComponent } from './product/components/field-group/field-group.component';
import { RadioComponent } from './product/components/radio/radio.component';
import { PasswordInputComponent } from './product/components/password/password.component';
import { UrlComponent } from './product/components/url/url.component';
import { DatetimeLocalComponent } from './product/components/datetime-local/datetime-local.component';
import { MonthComponent } from './product/components/month/month.component';
import { CurrencyComponent } from './product/components/currency/currency.component';
import { InvoiceNumberComponent } from './product/components/inovice-number/inovice-number.component';
import { FeesComponent } from './product/components/fees/fees.component';
import { FormBuilderComponent } from './product/components/form-builder/form-builder.component';
import { MultiSelectComponent } from './product/components/multi-select/multi-select.component';
import { FileUploadComponent } from './product/components/file-upload/file-upload-component';
import { WrapperFieldComponent } from './product/wrapper-field/wrapper-field.component';
import { SellerContractConfirmationComponent } from './components/seller-contract-confirmation/seller-contract-confirmation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatSortModule } from '@angular/material/sort';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { ErrorComponent } from './components/error/error.component';
import { RouterModule } from '@angular/router';
import { CreditcardComponent } from './product/components/creditcard/creditcard.component';
import { CreditcardDateComponent } from './product/components/creditcard-date/creditcard-date.component';
import { CvvComponent } from './product/components/cvv/cvv.component';
import { PhoneComponent } from './product/components/phone/phone.component';
import { MultiSelectTriggerComponent } from './product/components/multi-select-trigger/multi-select-trigger.component';
import { PaymentOptionsLogoComponent } from './components/payment-options-logo/payment-options-logo.component';
import { PublicRoundedContainerComponent } from './components/public-rounded-container/public-rounded-container.component';
import { PublicRegisterContainerComponent } from './components/public-register-container/public-register-container.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ReservationTicketComponent } from './product/components/reservation-ticket/reservation-ticket.component';
import { BankFieldsComponent } from '../dashboard/seller/financials/components/bank/bank-fields/bank-fields.component';
import { BuyerPaymentOptionsComponent } from '../public-website/buyer/components/buyer-info/components/buyer-payment-options/buyer-payment-options.component';
import { menuComponent } from './components/menuComponent/menu.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
function getArabicPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = '';

  return paginatorIntl;
}

@NgModule({
  declarations: [
    ReportTableComponent,
    ReportFilterComponent,
    BaseReportComponent,
    SelectBoxSearchComponent,
    ChangePasswordComponent,
    AnnouncementDialogComponent,
    AlertDialogComponent,
    TitledModalWithContentComponent,
    CongratulationComponent,
    EdittedSuccessfullyComponent,
    FormBuilderFielditemComponent,
    ProductNameComponent,
    ShareComponent,
    ToggleComponent,
    PaymentOptionsComponent,
    NumberComponent,
    TextComponent,
    NoLabelTextAreaComponent,
    QuickCashExpirydateComponent,
    QassatlyComponent,
    PaymentPlansComponent,
    DateNowComponent,
    TextAreaComponent,
    NoLabelToggleComponent,
    NoLabelSelectListComponent,
    TaxesComponent,
    TaxesTypeComponent,
    TicketComponent,
    ImageComponent,
    ProductCategoriesComponent,
    DatetimeRangeComponent,
    OpenMapComponent,
    GoogleMapComponent,
    PhoneVerifyComponent,
    PasswordConfirmComponent,
    SelectListComponent,
    ProductPriceComponent,
    QuickCashSuccessComponent,
    HiddenComponent,
    MapDialogComponent,
    TicketImageComponent,
    VerifyComponent,
    ShareEmailComponent,
    EmailComponent,
    TelComponent,
    CheckBoxComponent,
    RangeComponent,
    FileComponent,
    SearchComponent,
    WeekComponent,
    TimeComponent,
    DateTimeComponent,
    FieldGroupComponent,
    RadioComponent,
    PasswordInputComponent,
    UrlComponent,
    DatetimeLocalComponent,
    MonthComponent,
    CurrencyComponent,
    InvoiceNumberComponent,
    FeesComponent,
    FormBuilderComponent,
    MultiSelectComponent,
    FileUploadComponent,
    WrapperFieldComponent,
    SellerContractConfirmationComponent,
    LoaderComponent,
    ErrorComponent,
    CreditcardComponent,
    CvvComponent,
    CreditcardDateComponent,
    PhoneComponent,
    MultiSelectTriggerComponent,
    PaymentOptionsLogoComponent,
    PublicRoundedContainerComponent,
    PublicRegisterContainerComponent,
    ImageSliderComponent,
    SafeHtmlPipe,
    ReservationTicketComponent,
    BankFieldsComponent,
    BuyerPaymentOptionsComponent,
    menuComponent,
    CountdownTimerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatTableModule,
    MatListModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    NgxDaterangepickerMd.forRoot(),
    MatButtonModule,
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatMenuModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    GooglePlaceModule,
    MatSlideToggleModule,
    MatSortModule,
    MatButtonModule,
    HttpClientModule,
    ShareModule,
    ShareButtonsModule,
    ShareIconsModule,
    // google map
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCkh_KNymIU4Br7PwNQXxP6VdRA569_t7k",
      libraries: ["places"]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
      //isolate: true
    }),
    SelectDropDownModule,
    RouterModule,
    CarouselModule
  ],
  exports: [
    HttpClientModule,
    TranslateModule,
    ReportTableComponent,
    ReportFilterComponent,
    BaseReportComponent,
    SelectBoxSearchComponent,
    ChangePasswordComponent,
    AlertDialogComponent,
    TitledModalWithContentComponent,
    CongratulationComponent,
    EdittedSuccessfullyComponent,
    FormBuilderFielditemComponent,
    ProductNameComponent,
    ShareComponent,
    ToggleComponent,
    PaymentOptionsComponent,
    NumberComponent,
    TextComponent,
    NoLabelTextAreaComponent,
    DateNowComponent,
    TextAreaComponent,
    NoLabelToggleComponent,
    QuickCashExpirydateComponent,
    NoLabelSelectListComponent,
    TaxesComponent,
    TaxesTypeComponent,
    TextComponent,
    TicketComponent,
    ImageComponent,
    ProductCategoriesComponent,
    DatetimeRangeComponent,
    OpenMapComponent,
    PhoneVerifyComponent,
    PasswordConfirmComponent,
    SelectListComponent,
    ProductPriceComponent,
    QuickCashSuccessComponent,
    HiddenComponent,
    MapDialogComponent,
    TicketImageComponent,
    VerifyComponent,
    ShareEmailComponent,
    EmailComponent,
    TelComponent,
    CheckBoxComponent,
    RangeComponent,
    FileComponent,
    SearchComponent,
    WeekComponent,
    TimeComponent,
    DateTimeComponent,
    FieldGroupComponent,
    RadioComponent,
    PasswordInputComponent,
    UrlComponent,
    DatetimeLocalComponent,
    MonthComponent,
    CurrencyComponent,
    InvoiceNumberComponent,
    FeesComponent,
    FormBuilderComponent,
    MultiSelectComponent,
    FileUploadComponent,
    WrapperFieldComponent,
    SellerContractConfirmationComponent,
    LoaderComponent,
    ErrorComponent,
    CreditcardComponent,
    CvvComponent,
    CreditcardDateComponent,
    PhoneComponent,
    MultiSelectTriggerComponent,
    PaymentOptionsLogoComponent,
    PublicRoundedContainerComponent,
    PublicRegisterContainerComponent,
    ImageSliderComponent,
    ReservationTicketComponent,
    BankFieldsComponent,
    BuyerPaymentOptionsComponent,
    menuComponent,
    CountdownTimerComponent
  ],
  providers: [
    ToggleLayoutService,
    AuthService,
    BasePostService,
    BehaviorSubjectActivationsService,
    BehaviorSubjectConfirmationService,
    FormValidatorService,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatPaginatorIntl, useValue: getArabicPaginatorIntl() }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
