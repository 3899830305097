import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import actionRoutes from '../actionRoutes';
import { FetchPaymentOptionsForSellerCustomizeService } from './fetch-payment-options-for-seller-customize.service';

@Injectable({
  providedIn: "root"
})
export class ActionsService {
  permissions: any;
  actions: any[] = [];
  documentationURL = 'https://easykash.gitbook.io/easykash-apis-documentation/cash-api';
  hasQuickQassatly = false;
  hasFawry = false;
  hasCashApi = false;
  businessVerified = false;
  hasQassatly=false;
  private hasQassatlyMerchant=false;
  constructor(
    private auth: AuthService,
    private fetchPaymentOptionsForSellerCustomizeService: FetchPaymentOptionsForSellerCustomizeService
  ) {}


  async getActions() {
    this.actions = [];
    this.permissions = await this.auth.getPermissions();
    await this.hasAccessToPaymentOptions();
    this.businessVerified = await this.auth.isBusinessActiveOrVerified()
    this.hasCashApi = await this.auth.hasCashAPIEnabled()
    this.hasQassatlyMerchant=await this.auth.hasQassatlyMerchantId();
    const url = await this.auth.dashboardUrl();
    actionRoutes.commons["Dashboard"].routerLink = url;
    this.actions.push(actionRoutes.commons.Dashboard);
    const allowSmsMarketing = await this.showSmsMarketing();
    if (allowSmsMarketing) this.actions.push(actionRoutes.commons.smsMarketing);
    //const allowBillingSeller = await this.showBillPaymentsSeller(); till fix billing
    //if (allowBillingSeller) this.actions.push(actionRoutes.privates.sellerbillPayments)
    const privatesRoute =
      ['product', 'easyPayments', 'reports', 'sellerAccount', 'bankAccount',
       'adminReports', 'adminRequests', 'adminManagement', 'gateConfigurations','billPayments',
       'adminSettings','billReports','otlobxReports'
      ] as const;
    for (const actionGroupKey of privatesRoute) {
      const actionGroup = actionRoutes.privates[actionGroupKey];
      const filteredActionGroup = await this.getFilteredActionGroupObject(
        actionGroup
      );
      if (filteredActionGroup) {
        this.actions.push(filteredActionGroup);
      }
    }
    return this.actions;
  }

  private async getFilteredActionGroupObject(actionGroup: any) {
    const subActionsAvailable = [];

    for (const subAction of actionGroup.subActions) {
      const routerAllow = await this.hasAccessToRoute(subAction.routerLink);
      const redirectAllow = await this.hasAccessToRoute(subAction.redirectLink);
      const isPublic = await this.isPublicToRole(subAction)
      const isMerchant = await this.isMerchantOnlyRoute(subAction)
      if(isMerchant){
        subActionsAvailable.push(subAction);
      }
      if ( routerAllow || isPublic) {
        subActionsAvailable.push(subAction);
      }

      if(subAction.redirectLink){
        if( redirectAllow || isPublic){
          subActionsAvailable.push(subAction);
        }
      }
    }

    if (subActionsAvailable.length === 0) {
      return null;
    } else {
      actionGroup.subActions = subActionsAvailable;
      return actionGroup;
    }
  }

  private async isPublicToRole(subAction: any) {
    if (subAction.makePublicFor) {
      return await this.auth.isSellerOrisAdmin(subAction.makePublicFor);
    } else {
      return false;
    }
  }

  private async hasAccessToRoute(route: any) {
    const isCashApiEnabled = await this.auth.hasCashAPIEnabled();
    for (const permission of this.permissions) {
      if (route == "/seller/new-products/quick-payment" && this.businessVerified
        && permission.clientURL === route) {
        return true;
      }
      if(route == this.documentationURL && isCashApiEnabled){
        return true
      }
      if (route == "/seller/cash-api") {
        if (isCashApiEnabled && permission.clientURL === route) {
          return true;
        } else continue;
      }
      if (route == '/seller/new-products/quick-qassatly') {
        if (this.hasQuickQassatly && permission.clientURL === route) {
          return true;
        } else continue;
      }
      if (route == '/seller/cash-out'){
        if (this.hasFawry && permission.clientURL === route) return true;
        else continue;
      }
      if(route=='/seller/qassatlyRepaymentsReports' && permission.clientURL==route ){
        if(this.isQasstlyMerchant()) return true;
        else continue;
      }
      if (route == "/seller/billPayments" && permission.clientURL === route) {
        return true;
      }
      if (route === '/seller/organization' && permission.clientURL === route) {
        return this.isOwner();
      }
      if (permission.clientURL === route) {

        return true;
      }

    }
    return false;
  }

  async showSmsMarketing() {
    let hasSmsMarketingPermission = await this.auth.hasAccessToSmsMarketing();
    return hasSmsMarketingPermission;
  }
  async showBillPaymentsSeller (){
    let hasBillingAccess = await this.auth.hasAccesstoBillingSeller();
    return hasBillingAccess;
  }
  isQasstlyMerchant(){
    return this.hasQassatlyMerchant && this.hasQassatly;
  }

  public async hasAccessToPaymentOptions(): Promise<void>{
    const sellerPaymentOptions =
      await this.fetchPaymentOptionsForSellerCustomizeService.make();
    sellerPaymentOptions.forEach((item) => {
      if( item['name'] == 'Quick Qassatly') this.hasQuickQassatly = true;
      if( item['name'] == 'Cash Through Fawry') this.hasFawry = true;
      if( item['name'] == 'Qassatly') this.hasQassatly=true;
    });
  }
  getAdvanceSalesAction(){
    return actionRoutes.commons.AdvanceSales;
  }
  async isMerchantOnlyRoute(subAction){
    let merchantId = await this.auth.getMerchantId();
    if(merchantId&& subAction.makePublicFor=="Merchant"){
      return true;
    }
    return false;
  }

  private async isOwner(): Promise<boolean> {
    return await this.auth.isOwner();
  }
}
