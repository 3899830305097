import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseRequestService } from './base-request.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalLoaderService } from './global-loader.service';

@Injectable()
export class BasePostService extends BaseRequestService {

  constructor(public httpClient: HttpClient, gls: GlobalLoaderService, r: Router,
    auth: AuthService) {
    super(gls, r, auth);
  }

  async _make() {
    const params = this.getParams();
    const path = this.getPath();
    const headers = this.getHeaders();
    try {
      const result = await this.httpClient
        .post<any>(path, params, { headers, withCredentials: true, observe: 'response',  })
        .toPromise();

      this.setResult(result.body);
      return this.preProcessResult(result);
    } catch (error: HttpErrorResponse | any) {
      return this.preProcessResult(error);
    }
  }
}
