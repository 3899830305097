<div [ngClass]="isArabicLang? 'd-flex flex-row-reverse':'d-flex'" >
  <span class="d-inline-flex col-3" *ngIf="isQassatlyOrAmanForm">
    <ng-container *ngIf="isArabicLang">
      <span class="col-5  px-2  h4 my-auto"> 2+</span>
      <img src="assets/images/egFlag.svg" class="col-7 my-auto mx-auto" height="70%"  />
    </ng-container>
    <ng-container *ngIf="!isArabicLang">
      <img src="assets/images/egFlag.svg" class="col-7 my-auto mx-auto" height="70%"  />
      <span class="col-5 px-2 h4  my-auto">+2 </span>
    </ng-container>
  </span>
<mat-form-field [ngClass]="isQassatlyOrAmanForm?'col-9':'col-12'"  appearance="outline">
  <mat-label>{{ translateFieldName(fieldObject) }}</mat-label>
  <input type="tel" matInput class="form-control"
          [attr.disabled]="(productType === 5  && edit) ? true:null"
          [formControl]="formControlRef"
          [placeholder]="translateFieldName(fieldObject)" (keyup)="OutPutFormControl($event)"/>
  <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
</div>
