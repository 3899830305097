<div class="cb-wrapper" [formGroup]="paymentOptionRef">
  <div class="d-flex justify-content-around">
    <div class="columcard w-100">
      <div *ngFor="let control of payment_option.controls; let i = index;">
        <mat-checkbox
        [checked]="integrationPage ? options[i].enabledDirectPay : options[i].isSelected"
        [formControl]="convertControl(control)"
        (change)="changePaymentOptionSelectionValue($event.checked, i)"
        >
        {{ translateOption(options[i]) }}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <small class="form-error form-error-hidden"
         [ngClass]="{
        'form-error-hidden':
          !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible':
          fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
