import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { VerifyFacebook } from '../../services/verify-facebook.service';
import { BehaviorSubjectActivationsService } from '../../services/behavior-subject-activations.service';
import { WithdrawalStatusService } from '../../services/withdrawal-status.service';
import { UpdateActivationStatusService } from '../../services/update-activation-status.service';
import { GetBusinessProfileService } from '../../services/get-business-profile.service';
import { GetActivationStepService } from '../../services/get-activation-step.service';
import { ActivationStepsService } from '../../services/activation-steps.service';
import { ACTIVATION_STEPS } from '../../activationSteps';
import { TranslateService } from '@ngx-translate/core';
import { ActivationStatusSubmittedNotifier } from 'src/app/dashboard/seller/my-account/components/new-activation-page/activationsSubmittedNotifier.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  haveActivations: boolean = false;
  isListOpened: boolean = true;
  percentage: number = 25;
  withdrawalStatus: string = '';
  isFacebookVerified: boolean = false
  canOpenList: boolean = true;
  bussinessStatus: string = '';
  constructor(
    private getActivationStepService: GetActivationStepService,
    public ActivationSteps: ActivationStepsService,
    private route: Router,
    private BehaviorSubjectActivations: BehaviorSubjectActivationsService,
    private withdrawalStatusService: WithdrawalStatusService,
    public UpdateActivationStatus: UpdateActivationStatusService,
    private businessProfile: GetBusinessProfileService,
    private verifyFb: VerifyFacebook,
    private translate: TranslateService,
    private activationSumbittedNotifier:ActivationStatusSubmittedNotifier,
    private auth: AuthService,
  ) {
    route.events.subscribe((value) => {
      this.isListOpened = true
    })
    this.BehaviorSubjectActivations.renderData.subscribe(async (ele) => {
      await this.ngOnInit();
    })
  }

  async ngOnInit() {
    this.checkStatus();
    this.activationSumbittedNotifier.getStatusNotification().subscribe(async(res)=>{
      const { status } = await this.withdrawalStatusService.make();
        this.withdrawalStatus = status;
    });
    setTimeout(async () => {
      let isOwner: boolean = await this.auth.isOwner()
      let business: any = await this.auth.getBusiness();
      if (business) {
        this.getActivationStepService.setParams({});
        let activationSteps = await this.getActivationStepService.make();
        this.ActivationSteps.setActivationSteps(activationSteps.data);
      }
      if (!!isOwner && !!business) {
        this.haveActivations = isOwner == true;
        const { status } = await this.withdrawalStatusService.make();
        this.withdrawalStatus = status;
        if (this.withdrawalStatus == "done" && !this.ActivationSteps.getWithdrawalActivationStep) {
          let params = {
            activationStep: ACTIVATION_STEPS.WITHDRAWAL_ACTIVATION,
            activationStatus: true
          };

          this.UpdateActivationStatus.setParams(params);
          let result = await this.UpdateActivationStatus.make();
          if (result.success) {
            this.ActivationSteps.setWithdrawalActivationStep = params;
          }
        } else if (this.withdrawalStatus == "pending") {
          this.isListOpened = true;
          this.canOpenList = true;
        }
      }
    }, 1000);
  }

  async checkStatus() {
    let bussiness_status: any = await this.businessProfile.make();
    this.bussinessStatus = bussiness_status?.Business?.activeStatus;
    //console.log('bussinessStatus', this.bussinessStatus);
  }

  openBusinessPage() {
    if (!this.ActivationSteps.getBusinessProfileStep) {
      // this.isListOpened = false;
      this.route.navigate(['/seller/settings/business']);
    }
  }

  toggelList() {
    if (this.canOpenList) {
      this.isListOpened = !this.isListOpened;
    }
  }

  get haveActivationRemmining() {
    return !(this.ActivationSteps.getBusinessProfileStep /*&& this.ActivationSteps.getVerifyFacebookStep */ && this.ActivationSteps.getPhoneAndEmailVerifyStep && this.ActivationSteps.getWithdrawalActivationStep);
  }

  get allowWithdrowal() {
    return !(this.ActivationSteps.getBusinessProfileStep /*&& this.ActivationSteps.getVerifyFacebookStep */ && this.ActivationSteps.getPhoneAndEmailVerifyStep);
  }




  facebookVerificationPopup() {
    if (!this.ActivationSteps.getVerifyFacebookStep) {
      Swal.fire({
        title: this.translate.instant('Seller.Products.EnterVerifyFacebookCode'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        cancelButtonText: this.translate.instant('Seller.Products.Cancel'),
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Seller.Products.Verify'),
        showLoaderOnConfirm: true,
        preConfirm: async (code) => {
          const result: any = await this.verifyCode(code)
          if (result != 'Verified') {
            Swal.showValidationMessage(
              `${result}`
            )
          } else {
            this.isFacebookVerified = result
            return
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result: SweetAlertResult) => {
        if (result.value) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.translate.instant('Seller.Products.Verify'),
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    }
  }

  async verifyCode(code: any) {
    this.verifyFb.setParams({ code });
    const res = await this.verifyFb.make();
    if (res.msg == 'Verified') {
      let params = {
        activationStep: ACTIVATION_STEPS.FACEBOOK_ACTIVATION,
        activationStatus: true
      };
      this.UpdateActivationStatus.setParams(params);
      let result = await this.UpdateActivationStatus.make();
      if (result.success) {
        this.ActivationSteps.setVerifyFacebookStep = params;
      }
    }
    return res.msg
  }


}
