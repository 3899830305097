import { Injectable } from "@angular/core";
import constantURL from "../constantURL";
import { BaseLoggedInPostService } from "./base-logged-in-post.service";


@Injectable({
  providedIn: "root"
})
export class RemoveLoginSessionService extends BaseLoggedInPostService {
  _path = constantURL.auth.login.removeSession;
}
