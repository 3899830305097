import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      //console.log('user is authenticated');
      return true;
    } else {
      //console.log('user is not authenticated');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
