import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '../../dashboard/admin/action/action';
import { TitledModalWithContentComponent } from '../components/titled-modal-with-content/titled-modal-with-content.component';

@Injectable({
  providedIn: 'root'
})
export class TitledModalWithContentService {
  dialogRef!: MatDialogRef<TitledModalWithContentComponent>;
  constructor(
    private dialog: MatDialog
  ) { }

  open(data: ModalData): MatDialogRef<TitledModalWithContentComponent> {
    this.dialogRef = this.dialog.open(TitledModalWithContentComponent, {
      width: '50%',
      data: data
    });
    return this.dialogRef;
  }
}
