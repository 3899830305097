import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { GetBusinessProfileService } from '../../../shared/services/get-business-profile.service';
import { LanguageService } from '../../../shared/services/language.service';
import { GetAuthBusinessService } from 'src/app/shared/services/get-auth-business.service';
import { DeleteUserSessionService } from '../../public/services/delete-user-session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public languages = ['ar', 'en'];
  public currentLanguages: string = '';
  public authenticated: boolean = false;
  public mode: string = '';
  public name: string = '';
  public image: string = '';
  public subscription!: Subscription;
  public queryParamsHandling: string = '';
  public searchProduct: string = '';

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private getBusinessProfileService: GetBusinessProfileService,
    private router: Router,
    private getAuthBusinessService: GetAuthBusinessService,
    private deleteUserSessionService: DeleteUserSessionService,
    @Inject(PLATFORM_ID) private platformID: any
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformID)) {
      if (localStorage.getItem('language')) {
        this.changeLanguage(localStorage.getItem('language'));
      } else {
        this.changeLanguage('en');
      }
      this.authenticated =this.authService.getToken()

      if (this.authenticated) {
        if (await this.authService.isSellerOrisAdmin('Seller')) {
          this.mode = 'seller';
        } else if (await this.authService.isSellerOrisAdmin('Admin')) {
          this.mode = 'admin';
        }
      }
    }

    const pathname = window.location.pathname;
    if (!this.authenticated && (pathname == '/' || pathname == '/signup')) {
      this.queryParamsHandling = 'merge';
    }
  }

  async logout() {
    this.authService.logout();
    this.getAuthBusinessService.clear();
    try {
      await this.deleteUserSessionService.make();
    } catch (e) {
      console.log(e);
    }
  }

  search(searchProduct: string) {
    this.router.navigate(['/search/' + searchProduct]);
  }

  async getUserData(mode: string) {
    try {
      if (mode === 'Seller') {
        const sellerResponse = await this.getBusinessProfileService.make();
        this.name = sellerResponse.Seller?.Business.name;
        this.image = sellerResponse.Seller?.Business.logo;
      }
    } catch (error) {
      console.log(error);
      // this.authService.logout();
    }
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.currentLanguages = language;

    if (language === 'ar') {
      document.body.classList.add('dir-rtl');
      return;
    }
    document.body.classList.remove('dir-rtl');
  }
 async navigateToDashboard() {
      const url = await this.authService.dashboardUrl()
      this.router.navigate([url])
    }
}
