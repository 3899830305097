import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BaseLoggedInPostService } from './base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class GetBusinessProfileService extends BaseLoggedInPostService {
  _path = constantURL.settings.profile.businessInfo.getBusinessProfile;
}
