import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pay-mob-lightbox',
  templateUrl: './pay-mob-lightbox.component.html',
  styleUrls: ['./pay-mob-lightbox.component.scss'],
})
export class PayMobLightboxComponent implements OnInit {
  url:string = '';
  constructor() {}

  ngOnInit(): void {}
  async initiatLightBox(url:string) {
    this.url = url;
    this.openIFrame()
  }
  async openIFrame(){
    Swal.fire({
      html: `
        <iframe src="${this.url}" width="100%" id="otpFrame" height="560px" frameborder="0"></iframe>
        `,
      showCloseButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((inputValue: any) => {
    });
  }
}
