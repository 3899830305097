import { Injectable } from '@angular/core';
import { CalculateService } from './calculate.service';
import { ViewProductInfoHandlerService } from './view-product-info-handler.service';
import { ProductTypes } from 'src/app/shared/product/productTypes';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class GetCalculatesParamsService {
  public limit!: number;
  params: {
    productId: number,
    quantity: number,
    paymentOptions?: string,
    tickets?: object[],
    codeName?: string,
    productType: string,
    package: object
  } = {
      productId: 0,
      quantity: 0,
      paymentOptions: '',
      tickets: [],
      codeName: '',
      productType: '',
      package: {}
    };
  Dividing = 1;

  private calculateResponse: CalaculateResponse;
  private once = false;
  private paymentGroup: PaymentGroup;
  private promocode: Promocode;

  constructor(
    private vpihs: ViewProductInfoHandlerService,
    public calculate: CalculateService
  ) { }

  getParams() {
    return this.params;
  }
  getDividing() {
    return this.Dividing;
  }
  setParams(params: any) {
    this.params = params;
  }
  setCodeName(val: string) {
    this.params['codeName'] = val;
  }

  setPromocode(val: Promocode) {
    this.promocode = val;
  }

  setTickets(val: object[]) {
    this.params['tickets'] = val;
  }

  setQuantity(val: number) {
    this.params['quantity'] = val;
  }
  setPackage(val: object) {
    this.params['package'] = val
  }
  setProductId(val: number) {
    this.params['productId'] = val;
  }

  setPaymentOptions(val: string) {
    this.params['paymentOptions'] = val;
  }

  setPaymentGroup(val: PaymentGroup) {
    this.paymentGroup = val;
  }

  setProductType(type: string) {
    this.params['productType'] = type;
  }

  async calculateInfos() {
    const outputTickets = [];
    if (this.vpihs.viewInfoObject && this.vpihs.viewInfoObject?.extras && !this.vpihs.viewInfoObject?.extras?.isCustomPayment) {
      if (this.vpihs.viewInfoObject.extras.isBooking) {
        for (const slot of this.vpihs.viewInfoObject.extras.selectedSlots) {
          const newSlot = {
            itemId: slot.slotId,
            description: slot.slotName,
            price: Number(slot.slotPrice),
            quantity: Number(slot.slotQuantity)
          };
          outputTickets.push(newSlot);
        }
      } else {
        for (const ticket of this.vpihs.viewInfoObject.extras.selectedTickets) {
          const newTicket = {
            itemId: ticket.ticketId,
            description: ticket.ticketName,
            price: Number(ticket.ticketPrice),
            quantity: Number(ticket.ticketQuantity)
          };
          outputTickets.push(newTicket);
        }
      }
    }
    if (this.vpihs.viewInfoObject?.extras?.isCustomPayment) {
      if (this.vpihs.productId) {
        this.setProductId(this.vpihs.productId)
        this.setQuantity(1)
        this.setProductType(ProductTypes.customPayment)
      } else {
        return;
      }
    }
    const source = this.trackData(this.vpihs.productId);
    if (!this.once) {
      this.once = true;
      this.calculate.setParams({
        ...this.params,
        tickets: outputTickets,
        source,
      });
      this.calculateResponse = await this.calculate.make();
    }
    let result = this.calculateResponse;
    result.discountValue = this.promocode?.precentage || 0;
    if (this.promocode?.type === 'percentage') {
      result.discountValue = this.promoCodeCap(
        this.promocode,
        result.originalAmount,
      );
    }
    let amountAfterDiscount = Math.max(result.originalAmount - result.discountValue, 0);
    result.Fees = this.calcFees(amountAfterDiscount, result.Taxes);
    result.paymentOptionFees = this.calcPaymentOptionFees(result.originalAmount);
    result.VAT = this.calcVAT(result.Fees, result.paymentOptionFees);
    if (result.splitFees) {
      result.Fees = +(result.Fees / 2).toFixed(2)
    }
    result.roundUpAmount = this.calcRoundUpAmount(result);

    let Dividing = 1;
    if (this.vpihs.currency && this.vpihs.currency != 'EGP') {
      Dividing = this.getDividingRate(this.vpihs.currency, result.rate);
      this.vpihs.exchangeRates = result.rate;
    } else {
      //Currently there is a case that produce vpihs.currency == null
      //to avoid this we set the vpihs.currency = 'EGP' this is the default case
      this.vpihs.currency = 'EGP';
    }
    this.vpihs.Dividing = Dividing;
    this.Dividing = Dividing;
    this.vpihs.fees = result.paymentOptionFees;
    if (result.splitFees || result.addFees) {
      this.vpihs.fees += result.Fees;
    }
    if (result.mAddFees && source){
      this.vpihs.fees += result.mFees
    }
    this.vpihs.fees += result.VATOnSellerFlag ? 0 : result.VAT;
    this.vpihs.fees += result.productFees;
    this.vpihs.fees += result.roundUpAmount;
    this.vpihs.fees = Number((this.vpihs.fees / Dividing).toFixed(2));
    // this.vpihs.roundUpAmount = result.roundUpAmount / Dividing;
    this.vpihs.discount = -result.discountValue / Dividing;
    this.vpihs.taxes = result.Taxes / Dividing;
    this.vpihs.subTotal = result.originalAmount / Dividing;
    this.vpihs.total = result.amountPaidByBuyer / Dividing;
    this.vpihs.merchantFees = result.Fees / Dividing;
    this.vpihs.vat = result.VAT / Dividing;
    this.vpihs.feesWithNoPaymentOptionFees = this.vpihs.fees - result.paymentOptionFees;
    if(source){
      this.vpihs.mFees = result.mFees / Dividing;
      this.vpihs.mAddFees = result.mAddFees;
    }
  }

  trackData(prodId){
    let existingData = [];
  let source = "";
  const localStorageData = localStorage.getItem('trackingData') ? JSON.parse(localStorage.getItem('trackingData')) : [];
  const cookieData = Cookies.get('trackingData') ? JSON.parse(Cookies.get('trackingData')) : [];

  if (localStorageData.length >= cookieData.length) {
    existingData = localStorageData;
  } else {
    existingData = cookieData;
  }

  existingData.forEach((item) => {
    if (item.prodId == prodId) {
      source = item.source;
      return source;
    }
    return source;
  });
  return source;
}

  getDividingRate(currency: string, rates: rate[]){
    for (let rateInfo of rates) {
      if (rateInfo['name'] == `${currency}EGP`) {
        return rateInfo['rate'];
      }
    }
    return 1
  }

  private roundToTwoDecimalPlaces(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  private calcFees(amount: number, taxes: number) {
    const paymentOptionFees = this.paymentGroup?.sellerPaymentOptionFees || 0;
    return this.roundToTwoDecimalPlaces(
      ((amount + taxes) * paymentOptionFees) / 100,
    );
  }

  private calcPaymentOptionFees(amount: number) {
    const percentageFees = (this.paymentGroup?.percentageFees || 0) * 0.01;
    const flat = this.paymentGroup?.flatFees || 0;
    const paymentOptionFees = +amount * +percentageFees + flat;
    return Math.floor(paymentOptionFees * 100) / 100;
  }

  private calcVAT(fees: number, paymentOptionFees: number) {
    if (this.params.paymentOptions === 'Offline Payments') {
      return 0;
    }
    return +Number((fees + paymentOptionFees) * 0.14).toFixed(2);
  }

  private calcRoundUpAmount(r: CalaculateResponse) {
    let total = r.originalAmount;
    if (r.addFees || r.splitFees) {
      total += r.Fees;
    }
    if (!r.VATOnSellerFlag) {
      total += r.VAT;
    }
    if (r.mAddFees && r.source){
      total += r.mFees;
    }
    const dotAmount = total % 1;
    return dotAmount ? +(1 - dotAmount).toFixed(2) : 0;
  }

  promoCodeCap(promoCode: Promocode, amount: number): number {
	  const value = amount * (promoCode.precentage / 100);
	  const cap = promoCode.maxPrecentageValue;
	  if (cap > 0 && value > cap) {
		  return cap;
	  }
	  return value;
  }
}

interface rate {
  name: string
  rate: number
  [key: string]: any
}

type CalaculateResponse = {
  amountPaidByBuyer: number;
  paymentOptionFees: number;
  rate: Array<Rate>;
  Fees: number;
  Taxes: number;
  originalAmount: number;
  discountValue: number;
  addFees: boolean;
  VAT: number;
  splitFees: boolean;
  productFees: number;
  VATOnSellerFlag: boolean;
  roundUpAmount: number;
  mFees: number;
  mAddFees: boolean;
  source: string;
};

type Rate = {
  id: 1;
  name: 'USDEGP' | 'EUREGP' | 'SAREGP' | 'GBPEGP' | string;
  rate: number;
};

export type PaymentGroup = {
  name: string;
  nameAr: string;
  flatFees: number;
  percentageFees: number;
  sellerPaymentOptionFees: number;
};

type Promocode = {
  availableQuantity: number;
  redeemedQuantity: number;
  precentage: number;
  ticketQuantity: number;
  redeemedTicketQuantity: number;
  type: 'percentage' | 'flat';
  maxPrecentageValue: number;
};
