<div *ngIf="bussinessStatus=='UnVerified'">
  <div class="verig-pjk" *ngIf="isListOpened">
    <span>
      {{ 'Seller.Dashboard.CompleteVerifySteps' | translate }}
    </span>
    <div class="progress-bar mt-3">
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="ActivationSteps.persentage+'%'"
             [attr.aria-valuenow]="ActivationSteps.persentage" aria-valuemin="0" aria-valuemax="100">
          {{ActivationSteps.persentage.toFixed(1)}}%
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="checkbox-bc mb-2 d-flex">
        <input type="checkbox" [checked]="!!ActivationSteps.getWithdrawalActivationStep" [disabled]="true"/>
        <a class="ms-2" routerLink="/seller/settings/business"   [ngClass]="{'disabled-link': (ActivationSteps.getWithdrawalActivationStep)}">
          {{ 'Seller.Dashboard.AccountActivationRequest' | translate }}
        </a>
        <i *ngIf="withdrawalStatus == 'pending'" class="fa fa-spinner mx-2" ></i>
      </div>
      <div class="checkbox-bc">
        <input type="checkbox" [checked]="ActivationSteps.getVerifyFacebookStep"  [disabled]="true" />
        <span class="facebook-btn ms-2" (click)="facebookVerificationPopup()"
              [ngClass]="{'disabled-link':(ActivationSteps.getVerifyFacebookStep ||  withdrawalStatus != 'pending') }">
          {{ 'Seller.Dashboard.VerifyFacebook' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="bussinessStatus=='Active'">
  <div class="verig-pjk">
    <span>
      {{ 'Seller.Dashboard.VerifiedBusiness' | translate }}
    </span>
  </div>
</div>
