<div class="form-group mb-0">
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>{{ fieldObject.fieldName }}</mat-label>
    <input
    matInput
    [formControl]="formControlRef"
      type="time"
      class="form-control"
      [formControl]="formControlRef"
    />
    </mat-form-field>
    <small
      class="form-error form-error-hidden"
      [ngClass]="{
        'form-error-hidden':
          !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible':
          fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
      }"
    >
      {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
    </small>
  </div>
