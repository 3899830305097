import { Injectable } from '@angular/core';
import constantURL from 'src/app/shared/constantURL';
import { BasePostService } from 'src/app/shared/services/basePostService.service';
import { GateLightBoxData } from '../buyer';
import { AES } from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CreditCardPaymentService extends BasePostService {
  _path = constantURL.CreditCardPayment.authenticatePay
  private _providerObject: GateLightBoxData;
  private token: string;
  private creditBody:ICreditPayReq;
  private otpPageHtml:string;

  public get providerObject() {
    return this._providerObject;
  }
  public set providerObject(value: GateLightBoxData) {
    this._providerObject = value;
  }
  public set otpPage(value:string){
    this.otpPageHtml = value;
  }
  public get otpPage(){
    return this.otpPageHtml;
  }
  public get payToken() {
    return this.creditBody;
  }
  public get encToken(){
    return this.token;
  }
  public setPayToken(payObject: ICreditPayReq) {
    this.token = AES.encrypt(
      JSON.stringify(payObject),
      constantURL.SECURE_KEY,
    ).toString();
    this.setParams({ encryptedData: this.token, trxRef: this.trxRefNum });
  }
  public get amount () {
    return this._providerObject.amount;
  }
  public get trxRefNum () {
    return this._providerObject.trxRefNum;
  }
  public get merchant_ref_num () {
    return this._providerObject.merchant_ref_num;
  }
}
interface IProviderObject {
  amount: number;
  clientid: string;
  gateLightBox: boolean;
  merchant_ref_num: string; //number in string format
  payment_options: Array<number>; //payment_option ID
  process_payment_api: string;
  redirect_url: string; //usless
  trxRefNum: string; //number in string format
  url: string; //lightbox redirect, not going to use
}
export interface ICreditPayReq {
  'expiration-month-and-year': string;
  number: string; //credit card number in string format,
  providerType: string; //master card or visa or etc
  'security-code': string; //cvv
  trxRef: string; //number in string formate,
  operationType:string,
  publicToken:string,
}
