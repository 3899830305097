import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/container/dashboard.component';
import { VerificationComponent } from './dashboard/verification/verification.component';
import { DirectPayComponent } from './public-website/buyer/components/direct-pay/direct-pay.component';
import { PublicWebsiteComponent } from './public-website/public-website.component';
import { AuthGuardService } from './shared/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PublicWebsiteComponent,
    loadChildren: () => import('./public-website/public-website.module').then(m => m.PublicWebsiteModule),
    pathMatch: "prefix"
  },
  {
    path: '',
    component: DashboardComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    pathMatch: "prefix"
  },
  {
    path: "DirectPayV1/:pattern",
    component: DirectPayComponent
  },
  {
    path: 'user/mobile-verification',
    component: VerificationComponent,
    canActivate:[AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: "enabled"
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
