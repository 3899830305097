import {Component} from '@angular/core';
import { BaseField } from "../../baseField";
import {HttpClient} from '@angular/common/http';
import { Ng2ImgMaxService } from 'ng2-img-max';
import constantURL from 'src/app/shared/constantURL';

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload-component.html",
  styles: []
})
export class FileUploadComponent extends BaseField {
  fileData!: File ;
  previewUrl:any = null;
  fileUploadProgress!: string;
  uploadedFilePath: any;
  MAX_IMAGE_SIZE = .500;
  constructor(
    private http: HttpClient,
    private ng2ImgMax: Ng2ImgMaxService
  ) {
    super();
  }

  async fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
     this.preview();
  }

   preview() {
    // Show preview
    this.previewUrl = null;
    // const mimeType = this.fileData.type;
    // if (mimeType.match(/image\/*/) == null) {
    //   return;
    // }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = async (_event) => {
      if(reader.result!.toString().includes('image')){
        this.previewUrl = reader.result;
      }
      await this.handleSelectedFile();
    };
  }

  private async handleSelectedFile() {
        if(this.validateFileSize(this.fileData.size)){
          // if type is image , compress it's size
          if(this.fileData.type.includes('image')){
            this.ng2ImgMax.compressImage(this.fileData, this.MAX_IMAGE_SIZE).subscribe(res => {
              const image = new File([res], res.name);
              this.sendRequest(image);
            });
          } else {
            // without compress for other types
            await this.sendRequest(this.fileData);
          }
        }

      }


   validateFileSize(size: number){
    const maxSizeAllowed = 5000000; // 5MGB
      if(size <= maxSizeAllowed) return true;
      return false;
  }


  async sendRequest(compressedImage: any){
    const data = compressedImage ? compressedImage : this.fileData;
    const formData = new FormData();
    const fileName = this.fileData.name;
    formData.append('file', data, fileName);
    return this.http
      .post(constantURL.product.extraInfo.submitBuyerInfoFile, formData, {
        withCredentials: true,
      })
      .subscribe((res) => {
        // @ts-ignore
        this.uploadedFilePath = res.location;
        this.formControlRef.setValue(this.uploadedFilePath);
      });
  }

}
