<div class="row">
  <div class="col-12">
    <label style="font-size :1.2rem"> {{ fieldObject.fieldName }}</label>
    <div class="form-group">
      <input type="file" name="image" accept="image/*"
             (change)="fileProgress($event)"/>
    </div>
    <div *ngIf="fileUploadProgress">
      Upload progress: {{ fileUploadProgress }}
    </div>
    <div class="image-preview mb-3" *ngIf="previewUrl">
      <img [src]="previewUrl" height="300" width="600"/>
    </div>

    <input type="hidden" [formControl]="formControlRef">
  </div>
</div>
