import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadHandlerService {
  uploaderServiceRef!: BaseRequestService;

  public setUploaderService(serviceExtendsBaseRequest: any) {
    this.uploaderServiceRef = serviceExtendsBaseRequest;
  }

  public setImageFile(imagefile: any) {
    const fd = new FormData();
    fd.append('image', imagefile);
    this.uploaderServiceRef.setParams(fd);
  }

  public async upload() {
    try {
      const result = await this.uploaderServiceRef.make();
      return result;
    } catch (err) {
      return false;
    }
  }
}
