import { Component } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-field-group',
  templateUrl: './field-group.component.html',
  styles: []
})
export class FieldGroupComponent extends BaseField {
  fieldArrayModel = null;
  fieldArray  : any;

  addRow() {
    this.fieldArray.push(this.fieldArrayModel);
  }

  deleteRow() {
    this.fieldArray.pop();
  }

}
