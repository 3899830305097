import { Injectable } from '@angular/core';
import constantURL from 'src/app/shared/constantURL';
import { BaseLoggedInGetService } from 'src/app/shared/services/base-logged-in-get.service';

@Injectable({
  providedIn: 'root'
})
export class GetBasicStepOneTemplateService extends BaseLoggedInGetService {
  _path = constantURL.product.get.stepOneTemplate;
}
