import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import constantURL from '../constantURL';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GlobalLoaderService } from './global-loader.service';
import { AuthService } from './auth.service';
import { BaseLoggedInGetService } from './base-logged-in-get.service';
@Injectable({
  providedIn: 'root',
})
export class RolePermissions extends BaseLoggedInGetService {
  _path = constantURL.permissions;
  private rolepermissions: rolePermissions;
  constructor(
    public httpClient: HttpClient,
    protected router: Router,
    public gls: GlobalLoaderService,
    public auth: AuthService,
    @Inject(PLATFORM_ID) private platformID
  ) {
    super(httpClient, gls, router, auth);
  }
  async getCopyRole() {
    let isAuthenticated: boolean;

    if (isPlatformBrowser(this.platformID))
    isAuthenticated = this.auth.isAuthenticated();
    else return undefined;

    if (!isAuthenticated) return null;
    if (this.rolepermissions) {
      return { ...this.rolepermissions };
    } else {
      let result = await this.make();
      this.rolepermissions = result;
      return { ...this.rolepermissions };
    }
  }
  clear() {
    this.setResult(undefined);
    this.rolepermissions = undefined;
  }
}
interface rolePermissions {
  id: number;
  name: string;
  type: 'Admin' | 'Seller';
  temp: boolean;
  createdAt: Date;
  updatedAt: Date;
  Permissions: Array<{
    id: number;
    name: string;
    action: string | null;
    type: 'Admin' | 'Seller';
    assignable: boolean;
    clientURL: string;
    createdAt: Date;
    updatedAt: Date;
    Role_Permission: {
      createdAt: Date;
      updatedAt: Date;
      PermissionId: number;
      RoleId: number;
    };
  }>;
}
