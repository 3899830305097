import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BaseLoggedInGetService } from './base-logged-in-get.service';

@Injectable({
  providedIn: 'root'
})
export class FetchFormfieldstypesService extends BaseLoggedInGetService {
  _path = constantURL.product.fields.formBuilder.fetchFieldTypes;
}
