import { Injectable } from '@angular/core';
import constantURL from 'src/app/shared/constantURL';
import { BasePostService } from 'src/app/shared/services/basePostService.service';

@Injectable({
  providedIn: 'root'
})
export class LimitService extends BasePostService {
  _path = constantURL.product.fields.paymentOptions.limit
}
