export const CONSTANT_PRODUCT_TYPE_INFOS = {
  PRODUCT_TYPES: {
    PHYSICAL: "Physical Product",
    INVOICE: "Invoice",
    Event: "Event",
    Calendar: "Calendar",
    QUICK_PAYMENT: "Quick Payment",
    QUICK_CASH: "Quick Cash",
    Reservation:"Reservation",
  },
  EXTRA_INFO: {
    PHYSICAL: "PhysicalProductExtraInfo",
    INVOICE: "InvoiceProductExtraInfos",
    Event: "EventProductExtraInfos",
    QUICK_PAYMENT: "QuickPaymentExtraInfos",
    QUICK_CASH: "QuickCashExtraInfos",
    Calendar: "CalendarExtraInfo",
    Reservation:"ReservationExtraInfos",

  }
};

export const BASIC_FORM_TEMPLATE = {
  PRODUCT_NAME: "product template"
}
