<!-- Buyer Header Start -->
<section class="d-table w-100 my-4">
  <div class="container" style="margin: 50px auto;">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">{{ 'Buyer.Checkouts' | translate }}</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Payment Section -->
<div class="col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0 m-auto">
  <div class="rounded shadow-lg p-4 sticky-bar" id="paymentOptions">
    <!-- Payment Options -->
    <div class="invoice-middle pb-4 border-bottom">
      <div [ngClass]="{'border border-2 border-danger rounded p-2': paymentValidation}">
        <app-buyer-payment-options [expired]="productObject?.expired || false"
          [productId]="productObject.id" [totalAmount]="vpihs.total">
        </app-buyer-payment-options>
      </div>
      <div class="text-danger" *ngIf="paymentValidation">
        {{ 'Buyer.ChoosePaymentMethod' | translate }}
      </div>
    </div>
    <div class="table-responsive overflow-x-hidden">
      <!-- Invoice Info -->
      <table class="table table-center table-padding mb-0">
        <tbody>
          <tr>
            <td class="fs-6 border-0">{{ 'Buyer.Subtotal' | translate }}</td>
            <td class="text-end fw-bold border-0 pt-4">
              {{ vpihs.subTotal.toFixed(2) }} {{ vpihs.currency }}
            </td>
          </tr>
          <tr>
            <td class="fs-6 border-0">{{ 'Buyer.Taxes' | translate }}</td>
            <td class="text-end fw-bold border-0">
              {{ vpihs.taxes.toFixed(2) }} {{ vpihs.currency }}
            </td>
          </tr>
          <tr>
            <td class="fs-6 border-0">{{ 'Buyer.Discount' | translate }}</td>
            <td class="text-end fw-bold border-0">
              {{ vpihs.discount.toFixed(2) }} {{ vpihs.currency }}
            </td>
          </tr>
          <tr>
            <td class="fs-6">{{ 'Buyer.Fees' | translate }}</td>
            <td class="text-end fw-bold">
              {{ vpihs.fees.toFixed(2) }} {{ vpihs.currency }}
            </td>
          </tr>
          <tr class="bg-light">
            <td class="fs-5 fw-bold">{{ 'Buyer.Total' | translate }}</td>
            <td class="text-end text-primary fs-4 fw-bold">
              {{ vpihs.total.toFixed(2) }} {{ vpihs.currency }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4 pt-2">
        <div class="d-grid">
          <button class="btn btn-primary" (click)="submit()">
            {{ 'Buyer.PlaceOrder' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-light-box-upg></app-light-box-upg>
<app-card-payments></app-card-payments>
<app-pay-mob-lightbox></app-pay-mob-lightbox>
<app-valu-lightbox></app-valu-lightbox>
