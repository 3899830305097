import { Component, Output, EventEmitter, AfterViewInit, OnInit, Input } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent extends BaseField implements OnInit {
  ngOnInit(): void {
    this.selectedInput=this.fieldObject.defaultValue || this.fieldObject.fieldValues[0].name;
    if(this.formDefaultValue){
      this.selectedInput=this.formControlRef.value;
    }
  }
  @Output() selectedTypeEvent = new EventEmitter<string>();
  @Input() formDefaultValue=false;
  selectedInput;
  sendSelectedType(value: any) {
    this.selectedTypeEvent.emit(value);
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
