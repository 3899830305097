import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ViewProductInfoHandlerService } from '../../../../public-website/buyer/services/view-product-info-handler.service';
import { ShareProductService } from '../../../services/share-product.service';

@Component({
  selector: 'app-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss']
})
export class ShareEmailComponent implements OnInit {
  form = new FormControl("", Validators.email);
  emailInput: boolean = false;
  isSent: boolean = false;
  @Input() url: string = '';
  constructor(private shareEmail: ShareProductService, private vpihs: ViewProductInfoHandlerService) {
  }

  ngOnInit() {
  }

  click() {
    this.emailInput = true;
  }

  get email() {
    return this.form.value;
  }

  async sendEmail() {
    this.shareEmail.setParams({ email: this.email, productId: this.vpihs.productId, url: this.url });
    await this.shareEmail.make();
    this.isSent = true;
  }
}
