import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class TicketsService {

  Tickets = new Subject<any>();
  addTickets = new BehaviorSubject<boolean>(false);

  addTicket(ticket: boolean) {
    return this.addTickets.next(ticket);
  }

  getTickets(tickets: any) {
    return this.Tickets.next(tickets);
  }
}
