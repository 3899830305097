<div class="form-group" *ngIf="!fieldObject.isStaticUI">
  <label>Cash Expiry Duration</label>
  <select type="text" class="form-control form-select" [attr.disabled]="(productType === 6 && edit) ? true:null"
          [formControl]="formControlRef">
    <option *ngFor="let fDate of expiryDates" [value]="fDate.value">
      {{ translateExpiryDate(fDate) }}
    </option>
  </select>
</div>

<select *ngIf="fieldObject.isStaticUI" type="text" class="form-control form-select" [attr.disabled]="(productType === 6 && edit) ? true:null"
        [formControl]="formControlRef">
  <option *ngFor="let fDate of expiryDates" [value]="fDate.value">
    {{ translateExpiryDate(fDate) }}
  </option>
</select>
