import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BaseGetService } from './baseGetService.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeService extends BaseGetService {
  _path = constantURL.exchange.getRate;
}
