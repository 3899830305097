<div class="row">
  <div class="col-lg-6 col-sm-6 col-sm-12">
    <app-payment-plans [formControlRef]="paymentPlansFormRef"></app-payment-plans>
  </div>
  <div class="col-lg-6 col-sm-6 col-sm-12 form-group">
    <label style="font-size :1.2rem ; height: 40px;">Do you want Qassatly's fees to be on the you or the buyer?</label>
    <select type="text" class="form-control" [disabled]="(productType === 6 ) ? true: false"
            [formControl]="paymentTypeRef" style="height: 50px;" (change)="handleOutput()">
      <option value="free">Free</option>
      <option value="insured">Insured</option>
    </select>
  </div>
  <div class="col-12 form-group" *ngIf="hasFeesPayer">
    <label style="font-size :1.2rem">Do you want Qassatly's fees to be on the you or the buyer?</label>
    <select type="text" class="form-control" [disabled]="(productType === 6 && edit) ? true: false"
            [formControl]="feesPayerRef">
      <option value="seller">Seller</option>
      <option value="buyer">Buyer</option>
    </select>
  </div>
</div>
