import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ContractStatusNotifierService {
  //Service Created To Let The Contract Verification Step Recieve The Changes When The Contract Form is Submitted

  //Subject To Works as Emiter
  contractStatusSubject=new Subject<string>();

  //Function To Emit Value Or Push Value
  pushStatusNotification(contractStatus:string){

    this.contractStatusSubject.next(contractStatus);
  }

  //Function To Receive Value When Value is Emited
  getStatusNotification():Observable<string>{
    return this.contractStatusSubject;
  }
}
