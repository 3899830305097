import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { LogoHeaderComponent } from './logo-header/logo-header.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    HeaderComponent,
    LogoHeaderComponent,
    FooterComponent,
    ReceiptComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    MatMenuModule 
  ],
  exports: [
    HeaderComponent,
    LogoHeaderComponent,
    FooterComponent,
    ReceiptComponent,
    MatMenuModule 
  ],
})
export class PublicSharedModule { }
