import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import constantURL from '../constantURL';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BaseLoggedInGetService } from './base-logged-in-get.service';
import { GlobalLoaderService } from './global-loader.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class GetAuthBusinessService extends BaseLoggedInGetService {
  _path = constantURL.getBussiness;
  private business: business;
  private _isOwner: boolean = false;
  private _hasCashApi: boolean = false;
  private _status: string;
  private _merchant: number;
  private _phoneVerified: boolean;
  private type: string;
  constructor(
    public httpClient: HttpClient,
    protected router: Router,
    public gls: GlobalLoaderService,
    public auth: AuthService,
    @Inject(PLATFORM_ID) private platformID
  ) {
    super(httpClient,gls,router,auth)
  }
  async getBussinessCopy() {
    let isAuthenticated: boolean;

    if (isPlatformBrowser(this.platformID))
    isAuthenticated = this.auth.isAuthenticated();
    else return undefined;

    if (!isAuthenticated) return null;
    if (this.type && this.type == 'Admin') return undefined

    if (this.business) {
      return { ...this.business };
    } else {
      let result: result;
      try {
        result = await this.make();
        if (!result) return undefined;
      } catch (error) {
        return undefined;
      }

      if(result.merchant) this._merchant = result.merchant;
      if(result.type == 'Admin') {
        this.type = 'Admin'
        this._phoneVerified = result.phoneVerified
        return undefined;
      }
      this.business = result.business;
      this._isOwner = result.isOwner;
      this._hasCashApi = result.hasCashApiEnabled;
      this._status = result.status;
      this._phoneVerified = result.phoneVerified
      return { ...this.business };
    }
  }
  get isOwner() {
    return Boolean(this._isOwner);
  }
  get hasCashApi() {
    return Boolean(this._hasCashApi);
  }
  get status() {
    return this._status;
  }
  get merchant(){
    return this._merchant
  }
  get phoneVerified(){
    return this._phoneVerified;
  }
  setBusiness(business: business, isOwner?: boolean, hasCashApi?: boolean,merchant?:number,phoneVerified?:boolean) {
    this.business = { ...business };
    if (isOwner) this._isOwner = isOwner;
    if (hasCashApi) this._hasCashApi = hasCashApi;
    if (merchant) this._merchant =  merchant;
    if (phoneVerified) this._phoneVerified = phoneVerified;
  }
  setPhoneVerified(status:boolean){
    this._phoneVerified = status;
  }
  setStatus(status: string) {
    this._status = status;
  }
  clear() {
    this.setResult(undefined)
    this.business = undefined;
    this._isOwner = false;
    this._hasCashApi = false;
    this._status = undefined;
    this._merchant = undefined;
    this._phoneVerified = false;
    this.type = undefined;
  }
}
interface result {
  business: business;
  isOwner: boolean;
  hasCashApiEnabled: boolean;
  status: string;
  merchant:number;
  phoneVerified:boolean;
  type:'Admin' | 'Seller'
}
export interface business {
  id: number;
  name: string;
  description: string;
  code: string;
  logo: string;
  fees: string;
  fees_type: string;
  can_withdraw: boolean;
  productBrowseFlag: boolean;
  cs_email: string;
  cs_phone: string;
  link_fb: string | null;
  link_tw: string | null;
  link_li: string | null;
  activationSteps: string;
  availableFeesPercentage: number;
  contractSubmittedAt: Date;
  advancedSalesLimit: number | null;
  enabel_advancedSales: boolean;
  activeStatus: string;
  facebook_likes: string;
  contractStatus: string;
  pendingToAvailable: number;
  last_signin: Date;
  creditCardLimit: number;
  customPaymentFeesOnBuyer: boolean;
  businessType: string;
  cashWalletFees: number;
  AdvanceRepaymentFees: number;
  RecurringWithdrawal: string;
  AdvancedFees: number;
  adminId: number;
  createdAt: Date;
  updatedAt: Date;
  CategoryId: number;
  QassatlyMerchant: {
    id: number;
    merchant_id: number;
    isEnabled: boolean;
    business_id: number;
    QasstlyLimit: string;
    qassatlyMonthlyLimit: number;
    logo: string;
  };
  branch: boolean;
}
