import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BaseLoggedInGetService } from 'src/app/shared/services/base-logged-in-get.service';

@Injectable({
  providedIn: 'root',
})
export class GetOrganizationService extends BaseLoggedInGetService {
  _path = constantURL.Organization.get;
}
