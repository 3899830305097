<div class="d-flex align-items-center justify-content-center pt-2" style="flex-direction: column;">
  <div (click)="click()" *ngIf="!emailInput">{{ 'Buyer.ShareEmail' | translate }}</div>
  <div *ngIf="emailInput">
    <input [formControl]="form" class="form-control d-inline" style="width: auto;" type="email">
    <button (click)="sendEmail()" class="btn btn-primary kt-font-bold kt-font-transform-u mx-1 pull-right"
            [disabled]="form.invalid">
      send
    </button>
  </div>
  <p *ngIf="form.invalid" class="form-error form-error-visible" style="font-size: 12px; margin: 8px auto;">
    Email is required
  </p>
  <div *ngIf="isSent">
    We have emailed your product to your buyer
  </div>
</div>
