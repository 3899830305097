import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-qassatly',
  templateUrl: './qassatly.component.html',
  styleUrls: ['./qassatly.component.scss']
})
export class QassatlyComponent extends BaseField implements OnInit {
  options: Array<any> = [];
  paymentPlans: string = "";
  paymentType: string = "";
  feesPayer: string = "";
  paymentPlansFormRef: FormControl = new FormControl();
  paymentTypeRef: FormControl = new FormControl();
  feesPayerRef: FormControl = new FormControl();
  subscription!: Subscription;
  hasFeesPayer: boolean = true;
  edit!: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }
  async ngOnInit() {

    let value = JSON.parse(this.formControlRef.value);
    // set inputes values on login 
    if (this.formControlRef.value) {
      this.paymentPlansFormRef.patchValue(value.paymentPlans)
      this.paymentTypeRef.setValue(value.paymentType)
      this.feesPayerRef.setValue(value.payerFees)
      this.hasFeesPayer = value.paymentType === "insured";
      this.paymentType = value.paymentType;
      this.feesPayer = value.payerFees;
      this.cdr.detectChanges()


    }
    this.subscription = this.paymentPlansFormRef.valueChanges.subscribe(value => {
      this.paymentPlans = value;
      this.handleOutput();
    });
    this.subscription = this.paymentTypeRef.valueChanges.subscribe(value => {
      this.paymentType = value;
      this.handleOutput();
    });
    this.subscription = this.feesPayerRef.valueChanges.subscribe(value => {
      this.feesPayer = value;
      this.handleOutput();
    });


    this.cdr.detectChanges();
  }

  handleOutput() {
    let outputobject = {
      paymentPlans: this.paymentPlans,
      // paymentType: this.paymentType,
      paymentType: 'insured',
      payerFees: 'seller'
      // payerFees: this.hasFeesPayer ? this.feesPayer : ""
    };

    this.hasFeesPayer = this.paymentType === "insured";
    this.formControlRef.setValue(JSON.stringify(outputobject));
  }


}
