<mat-form-field appearance="outline" class="col-12">
  <mat-label>{{ translateFieldName(fieldObject) }}</mat-label>
  <input [type]="fieldObject.fieldType"
         class="form-control"
         [formControl]="formControlRef"
         [attr.disabled]="(productType === 6 && edit ) ? true: false"
         [readonly]="readonly"
         (input)="onValueChange($event.target.value)"
         matInput>
  <mat-icon matSuffix *ngIf="showIcon" matTooltip="{{toolTipMsg}}">info</mat-icon>
  <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ getErrorMsg1(fieldObject.fieldName) || fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
