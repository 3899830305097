import { Injectable } from '@angular/core';
import constantURL from '../../../constantURL';
import { BaseLoggedInPostService } from '../../../services/base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class FetchPaymentOptionsForSellerService extends BaseLoggedInPostService {
  _path = constantURL.product.fields.paymentOptions.fetchForSeller;
}
