import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormValidatorService } from '../../services/form-validator.service';
import { Validators } from '../../validators';
import { handleMobileVerificationService } from '../../services/handleMobileVerification.service';

@Component({
  selector: 'app-phone-verify',
  templateUrl: './phone-verify.component.html',
  styleUrls: ['./phone-verify.component.scss']
})
export class PhoneVerifyComponent implements OnInit {
  codeForm!: FormGroup;
  formValidator: FormValidatorService = new FormValidatorService();
  onAdd = new EventEmitter();
  invaild: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PhoneVerifyComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { confResult: any },
    private handleMobileVerification:handleMobileVerificationService,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
    if (localStorage.getItem('language')) {
      this.translate.use(localStorage.getItem('language'));
    }
   this.codeForm = this.formValidator.createForm([
      {
        fieldName: 'code',
        defaultValue: null,
        validators: [
          {
            validatorFn: Validators.required,
            message: ''
          },
          {
            validatorFn: Validators.pattern('^[0-9]+$'),
            message: ''
          }
        ]
      }
    ]);
    await this.handleMobileVerification.sendCode(this.data.confResult);
   
    this.translateValidationMessages();
    this.translate.onLangChange.subscribe(() => {
      this.translateValidationMessages();
    })
  }
  translateValidationMessages() {
    this.translate.get('FormValidation').subscribe(() => {
      this.formValidator.validationMessages = {
        code: {
          required: this.translate.instant('FormValidation.Empty', {
            field: 'Pin code',
            fieldAr: 'الرقم السري'
          }),
          pattern: this.translate.instant('FormValidation.RequiredNumber', {
            field: 'Pin code',
            fieldAr: 'الرقم السري'
          }),
        },
      }
    });
  }

  async submit() {
    try {
      let res =await this.handleMobileVerification.verifyCode(this.codeForm.value.code);
      if (res.status) {
        this.dialogRef.close({data:res.status});
      } else {
        this.invaild = true;
      }
    }
    catch (err) {
      this.invaild = true;
    }
  }

}
