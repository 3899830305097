import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})

export class NumberComponent extends BaseField implements OnInit {
  edit: boolean = false;
  @Output() valueChange = new EventEmitter<string>()

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  async ngOnInit() {
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }

  onValueChange(value){
    this.valueChange.emit(value)
  }

  getErrorMsg1(error) {
  }
}
