import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PublicSharedModule } from '../public-website/public-shared/public-shared.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './container/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { VerificationComponent } from './verification/verification.component';
import { AdvertisementDialogComponent } from './container/advertisement-dialog/advertisement-dialog.component';
import { OrganizationPop } from './container/organization-pop/organization-pop.component';
import { ConfarmPasswordDialogComponent } from './seller/organization/components/confarm-password.component';
import { ReCaptchaModule } from 'angular-recaptcha3';
import constantURL from 'src/app/shared/constantURL';

@NgModule({
  declarations: [
    DashboardComponent,
    VerificationComponent,
    AdvertisementDialogComponent,
    OrganizationPop,
    ConfarmPasswordDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    PublicSharedModule,
    FormsModule,
    MatIconModule,
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: constantURL.RECAPTCHA_KEY,
      },
      normal: {
        sitekey: constantURL.RECAPTCHA_KEY,
      },
      language: 'en',
    }),
  ],
})
export class DashboardModule {}
