import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
declare var Checkout: any;

@Component({
  selector: 'app-card-payments',
  templateUrl: './card-payments.component.html',
  styleUrls: ['./card-payments.component.scss'],
})
export class CardPaymentsComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2
  ) {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(
      script,
      'src',
      'https://banquemisr.gateway.mastercard.com/checkout/version/61/checkout.js'
    );

    this.renderer.setAttribute(script, 'data-error', 'errorCallback');
    this.renderer.setAttribute(
      script,
      'data-complete',
      'completeCallback'
    );
    this.renderer.setAttribute(
      script,
      'data-cancel',
      'https://cancelcallback.requestcatcher.com/'
    );
    this.renderer.appendChild(this.document.head, script);
  }

  ngOnInit() {}

  config(sessionId: string) {
    Checkout.configure({
      session: {
        id: sessionId,
      },
      merchant: 'EASYKASH',
      order: {
        amount: '10.05',
        currency: 'EGP',
        description: 'Ordered goods',
        id: '1234',
      },
      interaction: {
        operation: 'PURCHASE',
        merchant: {
          name: 'EasyKash',
          address: {
            line1: '200 Sample St',
            line2: '1234 Example Town',
          },
        },
      },
    });
  }

  show() {
    Checkout.showLightbox();
    // Checkout.showPaymentPage();
  }
}
