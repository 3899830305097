<div *ngIf="!expired">
  <h5 class="text-primary">{{ 'Buyer.SelectPaymentOptions' | translate }} :</h5>
  <div class="row">
    <div class="widget">
      <button
        (click)="resetInstallments()"
        *ngIf="selectedInstallment !== ''"
        type="reset"
        class="btn btn-success">
        Back
      </button>
      <ul class="list-unstyled mt-2 mb-0">
        <div *ngIf="selectedInstallment === ''">
          <li *ngFor="let option of options; let i = index"
            [attr.data-index]="i">
            <div
              class="custom-control custom-radio custom-control-inline form-check mb-2">
              <input class="form-check-input" type="radio" name="paymentOption"
                [checked]="checked" id="{{option}}"
                (click)="radioChange(option)">
              <label class="d-block" for="{{option}}">
                <label class="form-check-label" for="{{option}}">{{translateOption(i)}}</label>
                <div class="d-flex justify-content-end align-items-end">
                  <app-payment-options-logo [option]="option"></app-payment-options-logo>
                </div>
              </label>
            </div>
          </li>
        </div>

        <div *ngIf="selectedInstallment === 'NBE Installments'">
          <li *ngFor="let option of nbeInstallments; let i = index"
            [attr.data-index]="i">
            <div
              class="custom-control custom-radio custom-control-inline form-check mb-2">
              <input class="form-check-input" type="radio" name="paymentOption"
                [checked]="checked" id="{{option}}"
                (click)="radioChange(option)">
              <label class="d-block" for="{{option}}">
                <label class="form-check-label" for="{{option}}">{{translateOption(i,
                  nbeInstallments)}}</label>
                <app-payment-options-logo [option]="option"></app-payment-options-logo>
              </label>
            </div>
          </li>
        </div>

        <div *ngIf="selectedInstallment === 'BM Installments'">
          <li *ngFor="let option of bmInstallments; let i = index"
            [attr.data-index]="i">
            <div
              class="custom-control custom-radio custom-control-inline form-check mb-2">
              <input class="form-check-input" type="radio" name="paymentOption"
                [checked]="checked" id="{{option}}"
                (click)="radioChange(option)">
              <label class="d-block" for="{{option}}">
                <label class="form-check-label" for="{{option}}">{{translateOption(i,
                  bmInstallments)}}</label>
                <app-payment-options-logo [option]="option"></app-payment-options-logo>
              </label>
            </div>
          </li>
        </div>

        <div *ngIf="selectedInstallment === 'Installments'">
          <li *ngFor="let option of installments; let i = index"
            [attr.data-index]="i">
            <div
              class="custom-control custom-radio custom-control-inline form-check mb-2">
              <input class="form-check-input" type="radio" name="paymentOption"
                [checked]="checked" id="{{option}}"
                (click)="radioChange(option)">
              <label class="d-block" for="{{option}}">
                <label class="form-check-label" for="{{option}}">{{translateOption(i, installments)}}</label>
                <app-payment-options-logo [option]="option"></app-payment-options-logo>
              </label>
            </div>
          </li>
        </div>


      </ul>
    </div>
  </div>
</div>
