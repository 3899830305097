import { Injectable } from '@angular/core';
import { BaseLoggedInGetService } from 'src/app/shared/services/base-logged-in-get.service';
import constantURL from '../../../../shared/constantURL';

@Injectable({
  providedIn: 'root'
})
export class GetUpgCallbackStatus extends BaseLoggedInGetService {
  _path = constantURL.UPG.getUpgCallbackStatus;
}
