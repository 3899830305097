import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-product-name',
  templateUrl: './product-name.component.html',
  styleUrls: ['./product-name.component.scss']
})
export class ProductNameComponent extends BaseField implements OnInit {
  edit: boolean = false;
  productName: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private languageService: LanguageService
  ) {
    super();
  }

  async ngOnInit() {
    if (localStorage.getItem("language") === "ar") {
      this.productName = this.fieldObject.fieldNameAr;
    } else {
      this.productName = this.fieldObject.fieldName;
    }
    this.languageService.languageUpdated.subscribe((lang: string) => {
      if (lang === "ar") {
        this.productName = this.fieldObject.fieldNameAr;
      } else if (lang === "en") {
        this.productName = this.fieldObject.fieldName;
      }
    });
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
  }
}
