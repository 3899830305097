import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from '../../../dashboard/admin/action/action';

@Component({
  selector: 'app-titled-modal-with-content',
  templateUrl: './titled-modal-with-content.component.html',
  styleUrls: ['./titled-modal-with-content.component.scss']
})

export class TitledModalWithContentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TitledModalWithContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit() {
    document.getElementById('div-content').innerHTML = this.data.content;
  }

  close() {
    this.dialogRef.close();
  }

}
