import { Component } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styles: []
})
export class MultiSelectComponent extends BaseField {
  selectedItems: any = [];
  constructor(){
    super();
  }
  translateMutliSelectFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar" && fieldObject.fieldNameAr) {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}

