<div class="ek-color">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content id="div-content">
    <!--<p>{{ data.content }}</p>-->
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="close()" cdkFocusInitial
            class="mb-3">{{ data.closeButtonName || 'ok!' }}</button>
  </div>
</div>
