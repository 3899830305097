import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const publicWebsiteRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  { path: '', loadChildren: () => import('./buyer/buyer.module').then(m => m.BuyerModule), },
];

@NgModule({
  imports: [RouterModule.forChild(publicWebsiteRoutes)],
  exports: [RouterModule]
})
export class PublicWebsiteRoutingModule { }
