import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'; 
import { Router } from '@angular/router';
@Component({
  selector: 'app-valu-lightbox',
  templateUrl: './valu-lightbox.component.html',
  styleUrls: ['./valu-lightbox.component.scss']
})
export class ValuLightboxComponent implements OnInit {
  url:string = '';
  refNum:string='';
  constructor(
    private router :Router
  ) { }

  ngOnInit(): void {
  }
  async initiatLightBox(url:string,refNum:string) {
    this.url = url;
    this.refNum = refNum;
    this.openIFrame()
  }
  async openIFrame(){
    Swal.fire({
      html: `
        <iframe src="${this.url}" width="100%" id="otpFrame" height="520px" frameborder="0"></iframe>
        `,
      showCloseButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      position:'top-end',
      showConfirmButton : false,
      onClose:()=>{
        this.handleClose()
      },
    }).then((result: any) => {
      
    });
  }
  async handleClose (){
    this.router.navigate([`valu/${this.refNum}`])
  }
}
