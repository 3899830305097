<ng-container #content></ng-container>
<ng-container *ngIf="fieldObject.fieldName=='Phone'">
<ng-container *ngIf="isQassatlyPaymentPicked==true">
  <div class="my-4">
  {{ 'BUYER_INFO.QASSATLY.QASSATLY_PHONE_EXTRA_MESSAGE' | translate }}
</div>
</ng-container>
<ng-container *ngIf="isAmanPaymentPicked==true">
  <div class="my-4">
    {{ 'BUYER_INFO.Aman_Instalation.AMAN_PHONE_EXTRA_MESSAGE' | translate }}
  </div>
</ng-container>
</ng-container>
