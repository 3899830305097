import {  Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorModel } from 'src/app/dashboard/admin/report/report';
import { PaymentNotifierService } from 'src/app/public-website/buyer/components/buyer-info/container/buyer-info/PaymentNotifier.service';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styles: []
})
export class PhoneComponent extends BaseField implements OnInit {
  edit = false;
  isArabicLang = false;
  isQassatlyOrAmanForm=true;


  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private paymentNotifier:PaymentNotifierService
  ) {
    super();
  }
  async ngOnInit() {
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
    this.isArabicLang=localStorage.getItem("language") === "ar";
    this.paymentNotifier.getQassatlyPaymentNotification().subscribe(res=>{
      if(this.isQassatlyOrAmanForm!=res){
        this.isQassatlyOrAmanForm=res;

        this.handleFormChange(this.isQassatlyOrAmanForm);
      }
    });
    this.paymentNotifier.getAmanPaymentNotification().subscribe(res=>{
      if(this.isQassatlyOrAmanForm!=res){
        this.isQassatlyOrAmanForm=res;

        this.handleFormChange(this.isQassatlyOrAmanForm);
      }
    });

  }
  OutPutFormControl(event: KeyboardEvent) {
    const inputTxt=(event.target as HTMLInputElement).value;


    this.fieldErrors[this.fieldObject.fieldName] = '';

      if(inputTxt.length>=11 ){

        this.handleFormChange(this.isQassatlyOrAmanForm,inputTxt);
      }


    if (this.formControlRef.errors?.maxlength) {
      this.fieldErrors[this.fieldObject.fieldName] = this.fieldObject.validations[2].message;
    }else if (this.formControlRef.errors?.minlength) {
      this.fieldErrors[this.fieldObject.fieldName] = this.fieldObject.validations[1].message;
    } else if (this.formControlRef.errors?.pattern) {
      this.fieldErrors[this.fieldObject.fieldName] = this.fieldObject.validations[3].message;
    } else if (this.formControlRef.errors?.required) {
      this.fieldErrors[this.fieldObject.fieldName] = this.fieldObject.validations[0].message;
    };


  }
  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      if (fieldObject.fieldNameAr) {
        return fieldObject.fieldNameAr;
      } else {
        return fieldObject.fieldName;
      }
    } else {
      return fieldObject.fieldName;
    }
  }
  private getValidatorObjectFromFVObject(object: any): ValidatorModel | null {
    switch (object.name) {
      case "required":
        return {
          validatorFn: Validators.required,
          message: object.message
        };
      case "pattern":
        return {
          validatorFn: CustomValidators.customPattern(new RegExp(object.value)),
          message: object.message
        };
      case "minLength":
        return {
          validatorFn: Validators.minLength(object.value),
          message: object.message
        };
        case "maxLength":
          return {
            validatorFn: Validators.minLength(object.value),
            message: object.message
          };
      default:
        return null;
    }
  }
  handleFormChange(isQassatlyForm,txt=''){

    this.formControlRef.clearValidators();
    let maxlength=13;
    let minlength=11;
    let validators:ValidatorFn[]=[ Validators.required];
    let pattern='^([+]|[0-9])[0-9]+$';
    if( txt.charAt(0)=='+'){
      maxlength=14;
    }
    if(isQassatlyForm){
      maxlength=11;
      pattern='^01[0125][0-9]{8}$';
    }
    validators.push(Validators.pattern(new RegExp(pattern)));

    validators.push( Validators.minLength(minlength));
    validators.push(Validators.maxLength(maxlength))


    this.formControlRef.setValidators(validators);
    this.formControlRef.updateValueAndValidity();
    let fieldValidations = [];

    this.translate.get('FormValidation').subscribe(() => {
      this.fieldObject.validations = [];
      fieldValidations.push({
        name: 'required', value: null,
        message: this.translate.instant('FormValidation.Required')
      });
      fieldValidations.push({
        name: 'minLength', value: minlength,
        message: this.translate.instant('FormValidation.MinLength', {
          field: 'Phone',
          fieldAr: 'رقم الهاتف',
          value: minlength
        })
      });
      fieldValidations.push({
        name: 'maxLength', value: maxlength,
        message: this.translate.instant('FormValidation.MaxLength', {
          field: 'Phone',
          fieldAr: 'رقم الهاتف',
          value: maxlength
        })
      });

        fieldValidations.push({
          name: 'pattern', value: pattern,
          message: this.translate.instant('FormValidation.Pattern', {
            field: 'Phone',
            fieldAr: 'رقم الهاتف'
          })
        });

    });
    for (const validation of fieldValidations) {
      if (this.getValidatorObjectFromFVObject(validation)) {
        this.fieldObject.validations.push(this.getValidatorObjectFromFVObject(validation));
      }
    }

  }
}
