import { Component, OnInit } from '@angular/core';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent extends BaseField implements OnInit {
  mandatoryPrices: string[] = ['Product price', 'Price'];
  mandatory: boolean = false;

  constructor(private mandatoryFieldsService: MandatoryFieldsService) {
    super();
  }

  ngOnInit() {
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryPrices, this.fieldObject.fieldName) || false;
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
