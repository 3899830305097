import { Injectable, EventEmitter } from '@angular/core';
//import { StepComponent } from '../step/step.component';

@Injectable({
  providedIn: 'root'
})
export class ProductStepHandlingService {
  currentOrderEvent = new EventEmitter<number>();
  isBuyerInfoFormValid = new EventEmitter<boolean>();
  isNotifyBuyersValid=new EventEmitter<boolean>();
  currentOrder = 1;
  stepsRefs: Array<StepReferenceObject> = [];

  async isCurrentStepValid() {

    if (this.stepsRefs[this.currentOrder - 1]) {
      const res = await this.stepsRefs[this.currentOrder - 1].ref.formSubmit();
      if (res) {
        return res;
      } else {
        this.stepsRefs[this.currentOrder - 1].ref.formValidator.forceShowErrors();
        return res;
      }
    } else {
      return false;
    }
  }
  registerStep(stepRef: any) {
    this.stepsRefs.push(stepRef);
  }
  flushStep() {
    this.stepsRefs = [];
    this.currentOrder = 1;
  }
  nextOrPrevStep(move: string) {
    if (move === 'next') {
      // this.currentOrder = this.currentOrder <= 2 ? this.currentOrder++ : this.currentOrder;
      ++this.currentOrder;
      this.currentOrderEvent.emit(this.currentOrder);
    } else if (move === 'prev') {
      // this.currentOrder = this.currentOrder <= 2 ? this.currentOrder++ : this.currentOrder;
      --this.currentOrder;
      this.currentOrderEvent.emit(this.currentOrder);
    }
  }
  async applyCopyProduct(productObject: any) {
    for (const step of this.stepsRefs) {
      step.ref.applyValuesToFields(productObject);
      try {
        const res = await step.ref.formSubmit();
        if (!res) {
          step.ref.formValidator.forceShowErrors();
        }
      } catch (e) {
        step.ref.formValidator.forceShowErrors();
      }
    }
  }
}

interface StepReferenceObject {
  ref: any;
  order: number;
}
