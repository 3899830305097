import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-quick-cash-expirydate',
  templateUrl: './quick-cash-expirydate.component.html',
  styleUrls: ['./quick-cash-expirydate.component.scss']
})
export class QuickCashExpirydateComponent extends BaseField implements OnInit {
  expiryDates = [
    { name: '3 Hours', value: '3', nameAr: '3 ساعات' },
    { name: '6 Hours', value: '6', nameAr: '6 ساعات' },
    { name: '12 Hours', value: '12', nameAr: '12 ساعات' },
    { name: '24 Hours', value: '24', nameAr: '24 ساعات' },
    { name: '48 Hours', value: '48', nameAr: '48 ساعات' }
  ];
  edit = false;

  constructor( private activatedRoute: ActivatedRoute ) {
    super();
  }

  async ngOnInit() {
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
    if (!this.formControlRef.value) this.formControlRef.setValue('3');
  }

  translateExpiryDate(expiryDate: any) {
    if (localStorage.getItem("language") === "ar") {
      return expiryDate.nameAr;
    } else {
      return expiryDate.name;
    }
  }
}
