import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BaseLoggedInPostService } from './base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class GetPendingRequestsService extends BaseLoggedInPostService {
  _path = constantURL.admin.withdrawalRequests.getPendingRequests;
}
