import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BasePostService } from './basePostService.service';

@Injectable({
  providedIn: 'root'
})
export class FetchGroupPaymentOptionService extends BasePostService {
  _path = constantURL.product.fields.paymentOptions.getSelectedGroupOption;
}
