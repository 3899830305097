<div fxLayoutGap="2rem">
  <mat-form-field fxFlex="50" class="col-12" appearance="outline">
    <mat-label>{{ description }}</mat-label>
    <textarea matInput type="text" class="form-control" [attr.disabled]="(productType === 6 && edit )? true: false"
              [formControl]="formControlRef" cols="30" rows="{{ fieldObject.rows || 1 }}" maxlength ="250"></textarea>
    <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
      {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
    </mat-error>
  </mat-form-field>
</div>
