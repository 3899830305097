import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import paymentOptionsConstant from 'src/app/shared/paymentOptionsConstant';
import { GetBusinessProfileService } from 'src/app/shared/services/get-business-profile.service';
import { TitledModalWithContentService } from 'src/app/shared/services/titled-modal-with-content.service';
import Swal from 'sweetalert2';
import { ProviderObjectModel } from '../../buyer';
import { CreditCardPaymentService } from '../../services/creditCardPayment.service';
import { FetchPageService } from '../../services/fetch-page.service';
import { GetCalculatesParamsService } from '../../services/get-calculates-params.service';
import { QuickCashData } from '../../services/quick-cash-data.service';
import { SubmitBuyerInfoService } from '../../services/submit-buyer-info.service';
import { ViewProductInfoHandlerService } from '../../services/view-product-info-handler.service';
import { LightBoxUpgComponent } from '../buyer-info/components/light-box-upg/light-box-upg.component';
import { LimitService } from '../buyer-info/container/buyer-info/limit.service';
import { paymentOptionsMapping } from '../../services/submitBuyerHelper.service';
import { CardPaymentsComponent } from '../buyer-info/components/card-payments/card-payments.component';
import { TranslateService } from '@ngx-translate/core';
import { PayMobLightboxComponent } from '../buyer-info/components/pay-mob-lightbox/pay-mob-lightbox.component';
import { ValuLightboxComponent } from '../buyer-info/components/valu-lightbox/valu-lightbox.component';
@Component({
  selector: 'app-direct-pay',
  templateUrl: './direct-pay.component.html',
  styleUrls: ['./direct-pay.component.scss'],
})
export class DirectPayComponent implements OnInit {
  @ViewChild(LightBoxUpgComponent) lightBoxUPGComponent!: LightBoxUpgComponent;
  @ViewChild(CardPaymentsComponent) cardPaymentsComponent!: CardPaymentsComponent;
  @ViewChild(PayMobLightboxComponent) payMobLightBoxComponent!: PayMobLightboxComponent;
  @ViewChild(ValuLightboxComponent) valuLightboxComponent!: ValuLightboxComponent;
  pattern: string;
  productObject: IProductObject;
  paymentValidation: boolean = false;
  nbeInstallmentsOptions: string[];
  businessObject: IBusinessObject;
  buyerInfo: IBuyerInfo;
  redirectUrl: URL;
  providerObject: ProviderObjectModel;
  customerRef:string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private fetchPageService: FetchPageService,
    public vpihs: ViewProductInfoHandlerService,
    public getBusinessProfileService: GetBusinessProfileService,
    private getCalculatesParamsService: GetCalculatesParamsService,
    private limitService: LimitService,
    public submitInfo: SubmitBuyerInfoService,
    public router: Router,
    public modal: TitledModalWithContentService,
    public creditCardService: CreditCardPaymentService,
    private quickCashData: QuickCashData,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformID
  ) {}

  async ngOnInit(): Promise<void> {
    document.getElementById('whatsapp-chat-widget').style.display = 'none';
    if (isPlatformBrowser(this.platformID)) {
      this.pattern = this.activatedRoute.snapshot.params.pattern;
      await this.fetchPage();
      await this.setVariablesValues();
    }
  }

  async fetchPage() {
    try {
      this.fetchPageService.setParams({
        code: this.pattern,
      });
      const result = await this.fetchPageService.make();
      if (!result.available) {
        Swal.fire({
          icon: 'error',
          text: 'Product not available. Please try again'
        })
      }
      if (result) {
        this.productObject = result.product;
        this.businessObject = this.productObject?.Business;
        if (result.product && result.product.DirectPayExtraInfos[0]) {
          this.customerRef =
            result.product.DirectPayExtraInfos[0].customerReference;
        }
        console.log(this.vpihs.total,"derpay")
      }
    } catch (error) {
      console.log(error);
    }
  }

  async submit() {
    let donationAmount = 0;
    if (this.vpihs.paymentOptionObject.selection != '') {
      const submitObject: any = {
        ProductId: this.vpihs.productId,
        Buyers: [this.buyerInfo],
        PackageId: this.vpihs.packageId,
        vpihs: this.vpihs.getSubmitObject(),
        promoCode: { id: null },
        donationAmount,
      };
      const paymentOption =
        paymentOptionsMapping[this.vpihs.paymentOptionObject.selection];
      if (paymentOption == 'creditCard') {
        this.limitService.setParams({
          businessId: this.businessObject.id,
          limitType: 'creditCard',
          trxAmount: this.vpihs.getSubmitObject().total,
        });
        let result: { limitExceeded: boolean; singleLimitExceeded: boolean } =
          await this.limitService.make();
        if (result.singleLimitExceeded) {
          return this.showErrorMessage('Credit Card');
        }
      } else if (paymentOption == 'Qassatly') {
        this.limitService.setParams({
          businessId: this.businessObject.id,
          limitType: 'qassatly',
          trxAmount: this.vpihs.getSubmitObject().total,
        });
        let result: { limitExceeded: boolean; singleLimitExceeded: boolean } =
          await this.limitService.make();
        if (result.limitExceeded) {
          return this.showErrorMessage('Qassatly monthly');
        }
        if (result.singleLimitExceeded) {
          return this.showErrorMessage('single');
        }
      }

      this.submitInfo.setParams(submitObject);
      try {
        const submitBuyerInfo = await this.submitInfo.make();
        this.vpihs.date = new Date();
        this.vpihs.refNum = submitBuyerInfo.refNum;

        if (submitBuyerInfo.error) {
          return Swal.fire({
            icon: 'error',
            text: 'Something went wrong. Please try again'
          })
        } else {
          if (this.vpihs.total == 0 && this.vpihs.PromocodeId) {
            if (submitBuyerInfo.PaymentAttempt.status == 'PAID') {
              this.router.navigate(['gate'], {
                queryParams: {
                  refNum: submitBuyerInfo.PaymentAttempt.providerRefNum,
                },
              });
            }
          }

          if (submitBuyerInfo.isQuantityInvalid) {
            this.modal.open({
              title:
                "Sorry, the quantity you've chosen has just run out. Please return to the product page to see the remaining quantity.",
              content: 'Please try again',
              closeButtonName: 'exit',
            });
          }

          this.providerObject = submitBuyerInfo['Provider'];
          const paymentOption =
            paymentOptionsMapping[this.vpihs.paymentOptionObject.selection];
          const isCreditCard =
            paymentOption == 'creditCard' ||
            paymentOption.includes('NBE') ||
            paymentOption.includes('BM') ||
            paymentOption ===
              paymentOptionsConstant.constantPaymentOptions.name.MEEZA ||
            paymentOption === 'Card_Payments';
          if (isCreditCard && submitBuyerInfo) {
              this.creditCardService.providerObject = submitBuyerInfo["Provider"];
              let prodCode:string
              this.activatedRoute.params.subscribe(params=>{
                prodCode = params.pattern
              })
              this.router.navigate([`${prodCode}/buyerinfo/credit-card`]);
            }

          if (paymentOption === 'Mobile_Wallet') {
            this.lightBoxUPGComponent.tID = this.providerObject['terminalID'];
            this.lightBoxUPGComponent.secureHash =
              this.providerObject['secureHash'];
            this.lightBoxUPGComponent.paymentMethodFromLightBox =
              this.providerObject['paymentMethodFromLightBox'];
            this.lightBoxUPGComponent.orderId = this.providerObject['voucher'];
            this.lightBoxUPGComponent.mID = this.providerObject['merchantID'];
            this.lightBoxUPGComponent.amount =
              this.providerObject['amount'] * 100;
            this.lightBoxUPGComponent.trxDateTime = this.providerObject['date'];
            this.lightBoxUPGComponent.qrcode = this.providerObject['qrcode'];
            this.lightBoxUPGComponent.MerchantReference =
              submitBuyerInfo['PaymentAttempt']['providerRefNum'];

            if (this.lightBoxUPGComponent.validateUPGRequiredData()) {
              this.lightBoxUPGComponent.callLightBox();
            } else {
              Swal.fire({
                icon: 'error',
                title: this.translate.instant('BUYER_INFO.UPG.ERROR_TITLE'),
                text: this.translate.instant('BUYER_INFO.UPG.TRY_AGAIN_TEXT'),
              });
            }
          }
          if (paymentOption == 'Qassatly') {
            window.location.href =
              submitBuyerInfo.Provider.redirectUrl + `?redirectUrl=${this.redirectUrl.toString()}`;
          }
          if (paymentOption === 'Cash' || paymentOption === 'Fawry') {
            const provider = paymentOption == 'Cash' ? 'Aman' : 'Fawry';
            this.openDialog({
              ...submitBuyerInfo['done'],
              sellerName: this.businessObject.name,
              provider,
              providerResponse: submitBuyerInfo.Provider
            });
          }
          if (paymentOption.includes('Installments') || paymentOption === 'Contact') {
            this.setLocalStorageItems()
            this.payMobLightBoxComponent.initiatLightBox(this.providerObject['lightboxURL'])
          }

          if (paymentOption === 'Aman Installments') {
            const path = window.location.pathname.split('/')[2] + '/buyerinfo/aman-options';
            this.router.navigate([path]);
            localStorage.setItem('amanData',JSON.stringify(submitBuyerInfo.Provider));
          }

          if (paymentOption === 'ValU' || paymentOption === 'Souhoola' || paymentOption === 'Halan') {
            this.valuLightboxComponent.initiatLightBox(this.providerObject.redirectUrl,submitBuyerInfo.PaymentAttempt.providerRefNum)
          }
          if (paymentOption == null || paymentOption == undefined) {
            return Swal.fire({
              icon: 'error',
              text: 'Something went wrong. Please try again'
            })
          }
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Ooops!',
          text: err.faultyRequest.error.message,
        });
      }
    } else {
      if (this.vpihs.paymentOptionObject.selection == '') {
        this.paymentValidation = true;
        const paymentOptionsRadio = document.getElementById('paymentOptions');
        paymentOptionsRadio?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  async setVariablesValues() {
    this.vpihs.BusinessId = this.productObject.BusinessId;
    this.vpihs.productId = this.productObject.id;
    this.vpihs.currency = this.productObject.DirectPayExtraInfos[0].currency
    this.buyerInfo = {
      Name: this.productObject.DirectPayExtraInfos[0]['payerName'],
      Email: this.productObject.DirectPayExtraInfos[0]['payerEmail'],
      Phone: this.productObject.DirectPayExtraInfos[0]['payerMobile'],
    };
    this.redirectUrl = new URL(
      this.productObject.DirectPayExtraInfos[0]['redirectUrl']
    );
    localStorage.setItem('customerRedirectUrl', this.redirectUrl.toString());
    this.vpihs.setViewInfoObject({
      product: this.productObject,
      extras: null,
    });
    this.getCalculatesParamsService.setProductId(this.productObject.id);
    this.getCalculatesParamsService.setProductType(
      this.productObject.ProductType.name
    );
    this.getCalculatesParamsService.setQuantity(1);
    await this.getCalculatesParamsService.calculateInfos();
  }

  openDialog(done: any): void {
    if (!done) {
      this.modal.open({
        title: 'Quick Cash Error',
        content: 'Something Went Wrong, please try again later',
        closeButtonName: 'exit',
      });
    }

    if (done) {
      localStorage.removeItem('customerRedirectUrl');
      this.redirectUrl.searchParams.set('status', 'PENDING');
      this.redirectUrl.searchParams.set(
        'providerRefNum',
        done.providerResponse.tempTrx.transaction_ref_num
      );
      this.redirectUrl.searchParams.set('voucher', this.providerObject.voucher);
      this.redirectUrl.searchParams.set('customerReference', this.customerRef);
      window.location.href = this.redirectUrl.toString();
    }
  }

  updateNbeInstallmentsOptions(value: string[]): void {
    this.vpihs.installmentValues = value;
  }

  showErrorMessage(limitType: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Ooops!',
      text: `Seller ${limitType} limit has been reached, please
            contact seller or use another payment option`,
    });
  }

  setLocalStorageItems(): void {
    // for receipt component.
    const receiptData = {
      submittedObject: JSON.stringify(this.vpihs.getSubmitObject()),
      productCode: JSON.stringify(this.vpihs.viewInfoObject.product.code),
      viewInfoObject: JSON.stringify(this.vpihs.viewInfoObject)
    }
    localStorage.setItem('vpihs', receiptData.submittedObject);
    localStorage.setItem('code', receiptData.productCode);
    localStorage.setItem('viewInfoObject', receiptData.viewInfoObject);
  }

}

interface IProductObject {
  Business: IBusinessObject;
  BusinessId: number;
  id: number;
  DirectPayExtraInfos: {
    payerName: string;
    payerMobile: string;
    payerEmail: string;
    currency: string;
    [key: string]: any
  }[];
  ProductType: { name: string };
  paymentOptions: string;
  expired: boolean
}

interface IBuyerInfo {
  Name: string;
  Email: string;
  Phone: string;
}

interface IBusinessObject {
  id: number;
  name: string;
  [key: string]: any;
}
