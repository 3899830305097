import { Injectable } from '@angular/core';
import { BasePostService } from './basePostService.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalLoaderService } from './global-loader.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BaseLoggedInPostService extends BasePostService {
  constructor(
    public httpClient: HttpClient,
    gls: GlobalLoaderService,
    router: Router,
    auth: AuthService
  ) {
    super(httpClient, gls, router, auth);
  }
  _make(): Promise<any> {
    if (this.authService.isAuthenticated()) {
      this._headers = new HttpHeaders({
        'x-url': this.router.url.split('?')[0],
      });
    }

    return super._make();
  }
}
