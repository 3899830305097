import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-hidden',
  templateUrl: './hidden.component.html',
  styleUrls: ['./hidden.component.scss']
})
export class HiddenComponent extends BaseField implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
