import { Injectable } from '@angular/core';
import { BaseLoggedInGetService } from 'src/app/shared/services/base-logged-in-get.service';
import constantURL from 'src/app/shared/constantURL';

@Injectable({
    providedIn: 'root'
})
export class GetCurrenciesService extends BaseLoggedInGetService {
    _path = constantURL.product.commons.fetchCurrencies;
}
