import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import constantURL from '../../../shared/constantURL';
import { BasePostService } from '../../../shared/services/basePostService.service';
import { GlobalLoaderService } from '../../../shared/services/global-loader.service';
import { DeleteUserSessionService } from './delete-user-session.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { RemoveLoginSessionService } from 'src/app/shared/services/remove-login-session.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BasePostService {
  constructor(
    http: HttpClient,
    gls: GlobalLoaderService,
    private auth: AuthService,
    r: Router,
    private deleteUserSessionService: DeleteUserSessionService,
    private removeLoginSession: RemoveLoginSessionService,
    private translate: TranslateService,
  ) {
    super(http, gls, r, auth);
  }
  _path = constantURL.auth.login.login;
  async processResult(response: any) {
    if (response?.error) return false;
    else {
      let url = await this.login(response);
      if (response.hasOtherSessions) {
        this.showPopSession(url);
      } else {
        this.router.navigate([url]);
      }
      this.deleteUserSessionService.deleteSessionOnLogoutSubscription();
      return true;
    }
  }

  async login(response: any): Promise<any> {
    let url = await this.auth.login(
      response.status,
      response.business,
      response.needsCCAlert,
      response.hasCashApiEnabled,
      response.isOwner,
      response.merchant,
      response.phoneVerified,
      response.lastPasswordChangeAt,
      response.role.type,
      response.role.Permissions,
    );
    return url;
  }

  showPopSession(url: string) {
    localStorage.setItem('hasOtherSessions', '1');
    localStorage.setItem('redirctUrl', url);

    Swal.fire({
      title: this.translate.instant('Login.alertSessionTitle'),
      text: this.translate.instant('Login.alertSessionText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('Login.alertSessionConfirmBtn'),
      cancelButtonText: this.translate.instant('Login.alertSessionCancelBtn'),
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('hasOtherSessions');
        localStorage.removeItem('redirctUrl');
        await this.removeLoginSession.make();
        this.router.navigate([url]);

        Swal.fire({
          icon: 'success',
          title: this.translate.instant('Login.RemoveDone'),
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        localStorage.removeItem('hasOtherSessions');
        localStorage.removeItem('redirctUrl');
        this.auth.logout();
      }
    });
  }
}
