import { Injectable } from '@angular/core';
import {  Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ImageChangedNotifierService {

  //Subject To Works as Emiter
  imageChanged=new Subject<any>();
  //Function To Emit Value Or Push Value

  pushImageChanged(imgData:any){
    this.imageChanged.next(imgData);
  }
  //Function To Receive Value When Value is Emited
  getImageChanged():Observable<any>{
    return this.imageChanged;
  }

}
