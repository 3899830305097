import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent extends BaseField implements OnInit {
  edit = false;
  mandatoryTextAreas = ['Event description', 'Invoice description', 'Product description','Reservation description'];
  mandatory = false;
  description: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private mandatoryFieldsService: MandatoryFieldsService,
    public translate: TranslateService,
    private languageService: LanguageService
  ) {
    super();
  }

  async ngOnInit() {
    if (localStorage.getItem("language") === "ar") {
      this.description = this.fieldObject.fieldNameAr;
    } else {
      this.description = this.fieldObject.fieldName;
    }
    this.languageService.languageUpdated.subscribe((lang: string) => {
      if (lang === "ar") {
        this.description = this.fieldObject.fieldNameAr;
      } else if (lang === "en") {
        this.description = this.fieldObject.fieldName;
      }
    });
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryTextAreas, this.fieldObject.fieldName) || false;
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        this.edit = true;
      }
    });
  }
}
