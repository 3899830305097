import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalLoaderService } from './global-loader.service';
import constantURL from '../constantURL';

@Injectable()
export abstract class BaseGetService extends BaseRequestService {
  constructor(
    protected httpClient: HttpClient,
    gls: GlobalLoaderService,
    r: Router,
    auth: AuthService
  ) {
    super(gls, r, auth);
  }

  async _make() {
    const params = this.getParams();
    const headers = this.getHeaders();
    const path = this.getPath();
    try {
      let options = { headers, params, withCredentials: true, observe: 'response'}

      if (this._path == constantURL.getIpAddress){
        delete options["withCredentials"]
      };
      const result = await this.httpClient
      // @ts-ignore
        .get(path, options)
        .toPromise();
        // @ts-ignore
      this.setResult(result.body);
      // @ts-ignore
      return this.preProcessResult(result);
    } catch (error) {
      return this.preProcessResult(error);
    }
  }
}


