import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { Subscription } from 'rxjs';
import { ImageService } from '../../../../dashboard/seller/payment-pages/services/image.service';
import { ImageUploadHandlerService } from '../../../services/image-upload-handler.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-ticket-image',
  templateUrl: './ticket-image.component.html',
  styleUrls: ['./ticket-image.component.scss']
})
export class TicketImageComponent extends BaseField implements OnInit, OnDestroy {
  @ViewChild('fileInput') FileInputRef!: ElementRef;
  selectedFile: any;
  imageSrc: any;
  inDrag: boolean = false;
  mandatory: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private ng2ImgMax: Ng2ImgMaxService,
    private imageUploadRqst: ImageService,
    private imageUploadHandler: ImageUploadHandlerService,
  ) { super(); }

  ngOnInit() {
    if (this.formControlRef.value && typeof this.formControlRef.value === 'string') {
      this.setImageSource();
    }
    this.subscriptions.push(this.formControlRef.valueChanges.subscribe(value => {
      this.onImageSourceChange(value);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  onDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.inDrag = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.inDrag = false;
  }

  onDrop(e: any) {
    event?.preventDefault();
    event?.stopPropagation();
    this.inDrag = false;
    this.selectedFile = e.dataTransfer.files[0];
    this.handleSelectedFile();
  }

  onFileInputChange(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.handleSelectedFile();
  }

  private setImageSource() {
    this.imageSrc = this.formControlRef.value;
    if (this.imageSrc) {
      let location = JSON.parse(this.imageSrc).location;
      this.selectedFile = {
        name: location
      }
    }
  }

  private onImageSourceChange(value: any) {
    if (value instanceof File) {
      return;
    } else if (typeof value === 'string') {
      this.imageSrc = value;
    }
  }

  private handleSelectedFile() {
    this.subscriptions.push(this.ng2ImgMax.compressImage(this.selectedFile, 0.200).subscribe(
      async result => {
        const image = new File([result], result.name);
        this.selectedFile = image;
        this.imageUploadHandler.setUploaderService(this.imageUploadRqst);
        this.imageUploadHandler.setImageFile(image);
        const uploadResult = await this.imageUploadHandler.upload();
        let value = null;
        if (uploadResult && uploadResult.location) {
          value = JSON.stringify(uploadResult);
        }
        this.formControlRef.setValue(value);
      },
      error => {
        alert('Error : ' + error);
      }
    ));
  }
}
