import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
   Toast=Swal.mixin({
    toast: true,showConfirmButton: false,
    timer: 1500,
    position:toastPositonType.TOP_END,

  });

  addSucessToast(title:string){
    this.Toast.fire({title:title,icon:'success'});
  }
  addFailedToast(title:string){
    this.Toast.fire({title:title,icon:'error'});
  }
}
export enum toastPositonType {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  CENTER = 'center',
  CENTER_START = 'center-start',
  CENTER_END = 'center-end',
  BOTTOM_CENTER='bottom',
  BOTTOM_START='bottom-start',
  BOTTOM_END='bottom-end',
}
