import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-payment-plans',
  templateUrl: './payment-plans.component.html',
  styleUrls: ['./payment-plans.component.scss']
})
export class PaymentPlansComponent extends BaseField implements OnInit {
  paymentPlans = [
    { name: '1 Month', value: '1' },
    { name: '3 Months', value: '3' },
    { name: '6 Months', value: '6' }
  ];
  constructor() {
    super();
  }

  async ngOnInit() {
    this.formControlRef.setValue(this.formControlRef.value || this.paymentPlans);
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name property - and not by reference.
    return o1 && o2 ? o1.value === o2.value : o2 === o2;
  }
}
