import { Injectable } from "@angular/core";
import constantURL from '../../constantURL';
import { BaseLoggedInPostService } from '../../services/base-logged-in-post.service';

@Injectable({
  providedIn: "root"
})
export class UpdateUserInfoService extends BaseLoggedInPostService {
  _path = constantURL.settings.profile.basicInfo.updateUserInfo;

  processResult() {
    if (this._result.token) {
      localStorage.setItem("token", this._result.token);
    }
  }
}
