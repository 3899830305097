<form [formGroup]="codeForm">
  <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="">
    <h2 mat-dialog-title>Enter Phone verification code</h2>
    <mat-dialog-content style="font-size: 13px; width: 80%;">
      <mat-form-field fxFlexFill appearance="outline">
        <mat-label style="font-size: 13px">Enter code</mat-label>
        <input matInput type="text" formControlName="code"
               placeholder="Enter pin code" style="font-size: 15px;">
      </mat-form-field>
    </mat-dialog-content>
    <span style="color:red" *ngIf="formValidator.formErrors.code || invaild==true">
      invalid code number
    </span>
    <mat-dialog-actions fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem">
      <button mat-flat-button style="color: white;background-color: rgba(15, 117, 189, 1)" (click)="submit()" color="primary">Submit</button>
    </mat-dialog-actions>
  </section>
</form>
