import { Component } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styles: []
})
export class EmailComponent extends BaseField {
  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      if (fieldObject.fieldNameAr) {
        return fieldObject.fieldNameAr;
      } else {
        return fieldObject.fieldName;
      }
    } else {
      return fieldObject.fieldName;
    }
  }
}
