<div class="form-group mb-0">
  <select class="form-control form-select" type="text" [formControl]="formControlRef">
    <option *ngFor="let fieldValue of fieldObject.fieldValues"
            [value]="fieldValue">
      {{ fieldValue }}
    </option>
  </select>
  <small class="form-error form-error-hidden"
         [ngClass]="{'form-error-hidden': !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
         'form-error-visible': fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
