import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  saveRoute(url: string) {
    //save route in local storage
    if(this.validateRoute(url)) localStorage.setItem('last-route-url', url);
  }
  validateRoute(url: string): boolean {
    //prevent saving invalid routes
    const role = this.roleType()?.toLowerCase(); // seller or admin 
    return role && url?.startsWith(`/${role}`)
  }
   roleType(){
    return localStorage.getItem('roleType') ?? ""
  }
}
