<div class="form-check mb-0" style="top: 50%">
  <input
    type="checkbox"
    [formControl]="formControlRef"
    class="form-check-input"
    id="checkbox"
  />
  <label for="checkbox" style="font-size :1.2rem">
    {{ fieldObject.fieldName }}
  </label>
  <p
    class="form-error form-error-hidden"
    [ngClass]="{
      'form-error-hidden':
        !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
      'form-error-visible':
        fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
    }"
  >
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </p>
</div>

<!-- <div class="mat-form-field-wrapper" style="width: 100%;">
  <mat-checkbox [formControl]="formControlRef" color="primary">{{
    fieldObject.fieldName
  }}</mat-checkbox>
  <mat-error
    *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]"
  >
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</div> -->
