import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PaymentNotifierService } from 'src/app/public-website/buyer/components/buyer-info/container/buyer-info/PaymentNotifier.service';
import { FieldsRegistery } from './fieldsRegistery';

@Component({
  selector: 'app-wrapper-field',
  templateUrl: './wrapper-field.component.html',
  styleUrls: ['./wrapper-field.component.css']
})
export class WrapperFieldComponent implements OnInit, AfterViewInit {
  @ViewChild('content', { read: ViewContainerRef }) content: ViewContainerRef;

  fieldRegistery = new FieldsRegistery();
  isQassatlyPaymentPicked=false;
  isAmanPaymentPicked=false;

  constructor(
    private componentFactory: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef,
    private paymentNotifier:PaymentNotifierService
    ) { }

  @Input() formControlRef;
  @Input() fieldObject;
  @Input() fieldErrors;
  @Input() fieldErrorName?;
  @Input() productType;
  @Input() isUpdate?;

  ngOnInit() {
    this.paymentNotifier.getQassatlyPaymentNotification().subscribe((isQassatlyPicked)=>{
        this.isQassatlyPaymentPicked=isQassatlyPicked;
    });
    this.paymentNotifier.getAmanPaymentNotification().subscribe((isAmanPicked)=>{
      this.isAmanPaymentPicked=isAmanPicked;
  });
  }

  ngAfterViewInit() {
    this.loadComponent();
  }

  loadComponent() {
    let componentClass, componentFactory, componentRef;
    componentClass = this.fieldRegistery[this.fieldObject.fieldType];
    componentFactory = this.componentFactory.resolveComponentFactory(componentClass);
    componentRef = this.content.createComponent(componentFactory);
    componentRef.instance.formControlRef = this.formControlRef;
    componentRef.instance.fieldObject = this.fieldObject;
    componentRef.instance.productType = this.productType;
    componentRef.instance.fieldErrors = this.fieldErrors;
    componentRef.instance.isUpdate = this.isUpdate;

    if (!this.fieldErrorName) {
      this.fieldErrorName = null;
    }
    componentRef.instance.fieldErrorName = this.fieldErrorName;
    this.cdRef.detectChanges();

  }

}
