import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as _moment from 'moment';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeRangeComponent extends BaseField implements OnInit {
  public dateTimeRange: Date[] = [];
  mandatoryDateRanges: string[] = ['Event Duration'];
  @Input() mandatory: boolean = false;

  constructor(private mandatoryFieldsService: MandatoryFieldsService) {
    super();
  }

  ngOnInit() {
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryDateRanges, this.fieldObject.fieldName) || false;
  }

  handleChange(event: any) {
    this.formControlRef.setValue(event.value)
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
