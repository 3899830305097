<label class="mat-select-list">{{translateFieldName(fieldObject)}}</label>
<select class="form-control form-select" [formControl]="formControlRef"
        (change)="sendSelectedType($event)" [(ngModel)]="selectedInput">
  <option *ngFor="let fieldValue of fieldObject.fieldValues" [value]="fieldValue.name">
    {{fieldValue.name}}
  </option>
</select>
<div *ngIf="fieldErrors">
  <small class="text-danger" *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
