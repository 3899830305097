<div mat-dialog-title>
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onNoClick()"
    ></button>
  </div>
</div>

<div mat-dialog-content>
  <p class="text-wrap fs-lg fw-bold">
    Scan the QR code below to complete <br />
    the transaction using your mobile <br />
    wallet application. After the <br />
    transaction is complete, you will <br />
    receive an email confirmation from <br />
    Easykash for your purchase.
  </p>
  <img class="img-fluid rounded" [src]="imgPath" alt="" />
</div>

<div mat-dialog-actions>
  <div class="pt-2">
    <button type="button" class="btn btn-primary" (click)="onNoClick()">
      Close
    </button>
  </div>
</div>
