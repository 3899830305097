import { Component, EventEmitter, OnInit } from '@angular/core';
import moment from 'moment';
import { BaseField } from '../../baseField';
declare let Modernizr: any;
declare let $: any;

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styles: []
})
export class DateTimeComponent extends BaseField implements OnInit {
  ngOnInit() {
    if(!this.formControlRef.value){
    this.formControlRef.setValue(moment().format('YYYY-MM-DDThh:mm'));
    }
    if (!Modernizr.inputtypes['datetime-local']) {
      $('input[type=datetime-local]').datetimepicker({
        format: 'm/d/Y h:i A',
        onChangeDateTime: function () {
          if ($('input[type=datetime-local]').val()) {
            this.formControlRef.setValue($('input[type=datetime-local]').val());
          }
        }.bind(this)
      });
    }
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }

}
