import { Injectable } from '@angular/core';
import constantURL from '../../../../shared/constantURL';
import { BaseLoggedInGetService } from '../../../../shared/services/base-logged-in-get.service';

@Injectable({
  providedIn: 'root'
})
export class FetchBasicUserInfoService extends BaseLoggedInGetService {
  _path = constantURL.settings.profile.basicInfo.fetchBasicUserInfo;
}
