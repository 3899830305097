export default {
  commons: {
    Dashboard: {
      action: "Dashboard",
      actionAr: "لوحة التحكم",
      routerLink: "/",
      icon: "dashboard.svg"
    },
    smsMarketing: {
      action: 'SMS Marketing',
      actionAr: 'تسويق بالرسائل القصيرة',
      routerLink: '/seller/sms-marketing',
      icon: 'new.svg',
    },
    AdvanceSales: {
      action: "Advance Sales",
      actionAr: "المبيعات المُسبقة",
      icon: "advanced-sales.svg",
      routerLink: "/seller/advanceSales"
    }
  },

  privates: {
    product: {
      action: "Products",
      actionAr: "المنتجات",
      icon: "product.svg",
      subActions: [
        {
          name: "Manage all products",
          nameAr: "إدارة جميع المنتجات",
          routerLink: "/seller/products/management"
        },
        {
          name: "Invoice",
          nameAr: "الفاتورة",
          routerLink: "/seller/new-products/invoice"
        },
        {
          name: "E-Tickets & Events",
          nameAr: " التذاكر الالكترونية والفعاليات",
          routerLink: "/seller/new-products/event"
        },
        {
          name: "Reservation",
          nameAr: "الحجوزات",
          routerLink: "/seller/new-products/reservation",
        },
        {
          name: "Products",
          nameAr: "المنتجات",
          routerLink: "/seller/new-products/physical"
        },
        {
          name: 'Subscription',
          nameAr: "الاشتراك",
          routerLink: '/seller/new-products/subscription'
        },
        {
          name: "Promocodes",
          nameAr: "البروموكود",
          routerLink: "/seller/promo-code/manage"
        },
        {
          name: 'Calendar',
          nameAr: "النتيجة",
          icon: "new.svg",
          routerLink: '/seller/new-products/calendar'
        }
      ]
    },
    easyPayments:{
      action: "Easy Payments",
      actionAr: "طرق دفع مريحة",
      icon:"payment-pages.svg",
      subActions: [
        {
          name: "Quick Payment",
          nameAr: "الدفع السريع",
          routerLink: "/seller/new-products/quick-payment"
        },
        {
          name: 'Quick Cash',
          nameAr: "النقد السريع",
          routerLink: '/seller/new-products/quick-cash'
        },
        {
          name: 'Quick Qassatly',
          nameAr: "خدمة قسّطلي السريعة",
          icon: "new.svg",
          routerLink: '/seller/new-products/quick-qassatly'
        },
        {
          name: 'Cash Out',
          nameAr: "المصروفات",
          icon: "new.svg",
          routerLink: '/seller/cash-out'
        }
      ]
    },
    reports: {
      action: "Reports",
      actionAr: "التقارير",
      icon: "reports.svg",
      subActions: [
        {
          name: "Sales Report",
          nameAr: "تقرير المبيعات",
          routerLink: "/seller/sales"
        },
        {
          name: "E-Tickets & Events Sales",
          nameAr: "مبيعات التذاكر الالكترونية والفعاليات",
          routerLink: "/seller/eventSales"
        },
        {
          name: "Reservation Sales",
          nameAr: "مبيعات الحجوزات",
          routerLink: "/seller/reservationSales"
        },
        {
          name: "Calender Sales",
          nameAr: "مبيعات النتيجة",
          routerLink: "/seller/calendarSales"
        },
        {
          name: "Subscriptions",
          nameAr: "الاشتراكات",
          routerLink: "/seller/subscription"
        },
        {
          name: "Buyer Info",
          nameAr: "معلومات المشتري",
          routerLink: "/seller/buyerInfoReport"
        },
        {
          name: "Invoice Monthly sales",
          nameAr: "تقرير المبيعات الشهرية",
          routerLink: "/seller/monthly-invoice",
        },
        {
          name: "Qassatly Repayment",
          nameAr: "سداد قسّطلي",
          routerLink: "/seller/qassatlyRepaymentsReports"
        }
      ]
    },
    sellerbillPayments:{
      action: "Bill Payments",
      actionAr : "دفع الفواتير",
      routerLink : "/seller/billPayments",
      icon:'bill.svg',
    },
    sellerAccount: {
      action: "Settings",
      actionAr: "الإعدادات",
      icon: "settings.svg",
      subActions: [
        {
          name: "Profile & Account",
          nameAr: "الملف الشخصي والحساب",
          routerLink: "/seller/settings/personal",
          makePublicFor: "Seller"
        },
        {
          name: "Roles",
          nameAr: "الأدوار",
          routerLink: "/seller/team/roles"
        },
        {
          name: "Custom Payment",
          nameAr: "الدفع المخصص",
          routerLink: "/seller/settings/customPayment",
        },
        {
          name: "Users",
          nameAr: "المستخدمون",
          routerLink: "/seller/team/users"
        },
        {
          name: "Team Mangement",
          nameAr: "إدارة الفريق",
          routerLink: "/seller/team/mangement"
        },
        {
          name: "Integration Settings",
          nameAr: "واجهة برمجة التطبيقات النقدية",
          routerLink: "/seller/cash-api"
        },
        {
          name: "Documentation",
          nameAr: "تعليمات تشغيل الواجهة",
          redirectLink: "https://easykash.gitbook.io/easykash-apis-documentation/cash-api"
        },
        {
          name: "Branch Management",
          nameAr: "إدارة الفروع",
          routerLink: "/seller/organization"
        },
        {
          name: 'Basic Info',
          nameAr: "المعلومات الأساسية",
          icon: "reports.svg",
          routerLink: '/seller/new-products/basic-info',
          makePublicFor: "Seller"
        }
      ]
    },
    bankAccount: {
      action: "Financials",
      actionAr: "الإحصائيات المالية",
      icon: "financials.svg",
      subActions: [
        {
          name: "Statement",
          nameAr: "كشف الحساب",
          routerLink: "/seller/statement"
        },
        {
          name: "Bank Accounts",
          nameAr: "الحسابات المصرفية",
          routerLink: "/seller/bank"
        },
        {
          name: "Withdrawals",
          nameAr: "عمليات السحب",
          routerLink: "/seller/balance"
        },
      ]
    },
    adminReports: {
      action: "Admin Reports",
      actionAr: "تقارير المسؤول",
      icon: "reports.svg",
      subActions: [
        {
          name: "Sales Report",
          routerLink: "/admin/reports/sales"
        },
        {
          name: "Sales Follow Up Report",
          routerLink: "/admin/reports/salesFollowUp"
        },
        {
          name: "Bank Account Report",
          routerLink: "/admin/reports/sellerBankAccounts"
        },
        {
          name: "Settlements Report",
          routerLink: "/admin/reports/settlements"
        },
        {
          name: "Products Report",
          routerLink: "/admin/reports/products"
        },
        {
          name: "Transactions Report",
          routerLink: "/admin/reports/transactions"
        },
        {
          name: "Sellers Report",
          routerLink: "/admin/reports/sellers"
        },
        {
          name: "Invoice Accounting Report",
          routerLink: "/admin/reports/invoice-accounting-report"
        },
        {
          name: "Referral Report",
          routerLink: "/admin/reports/referrals"
        },
        {
          name: "Merchant Report",
          routerLink: "/admin/reports/merchant"
        },
        {
          name: "Seller Statement  Report",
          routerLink: "/admin/reports/sellerStatement",

        },
        {
          name: "Income Report",
          routerLink: "/admin/reports/income",

        },
        {
          name: "Errors Logger",
          routerLink: "/admin/reports/errorslogger"
        },
        {
          name: "Donation Report",
          routerLink: "/admin/reports/donation",
        },
        {
          name: "Seller Income Report",
          routerLink: "/admin/reports/sellerIncome",
        },
        {
          name: "KYC Report",
          routerLink: "/admin/reports/kyc-report"
        },
      ]
    },
    otlobxReports: {
      action: "Otlobx Reports",
      actionAr: "تقارير otlobx",
      icon: "reports.svg",
      subActions: [
        {
          name: "Post Requests",
          routerLink: "/admin/requests/postRequest"
        },
        {
          name: "Posts Report",
          routerLink: "/admin/reports/otlobx/post-report"
        },
      ]
    },
    adminRequests: {
      action: "Admin Requests",
      actionAr: "طلبات المسؤول",
      icon: "requests.svg",
      subActions: [
        {
          name: "Withdrawals Requests",
          routerLink: "/admin/reports/withdrawals"
        },
        {
          name: "Activation Requests",
          routerLink: "/admin/requests/ActivationRequest"
        },
        {
          name: "Contracts",
          routerLink: "/admin/requests/contracts"
        },
        {
          name: "Bank Accounts Requests",
          routerLink: "/admin/requests/bankAccounts"
        },
        {
          name: "Sms Marketing Requests",
          routerLink: "/admin/requests/sms-marketing"
        },
        {
          name: "Advanced Sales Requests",
          routerLink: "/admin/reports/advanced-sales-report"
        },
      ]
    },
    adminManagement: {
      action: "Admin Actions",
      actionAr: "إجراءات المسؤول",
      icon: "actions.svg",
      subActions: [
        {
          name: "Announcements",
          routerLink: "/admin/announcements"
        },
        {
          name: "Advetrtisement Banner Management",
          routerLink: "/admin/advertisement-banner"
        },
        {
          name: "Adjustments",
          routerLink: "/admin/make-adjustment"
        },
        {
          name: "Roles Management",
          routerLink: "/admin/manage-roles",
          makePublicFor: "Admin"  //remove comment if wants to show it again regardless of permission
        },
        {
          name: "Admin Management",
          routerLink: "/admin/mangement"
        },
        {
          name: "CRM",
          routerLink: "/admin/reports/crm"
        },
        {
          name: "Promo Codes (System)",
          routerLink: "/admin/systemPromocode/manage",
          //makePublicFor: "Admin"  //remove comment if wants to show it again regardless of permission
        },
        {
          name: "Manual Payment",
          routerLink: "/admin/manualPayment",
           makePublicFor: "Admin"
        },
      ]
    },
    gateConfigurations: {
      action: "Gate Pages",
      actionAr: "صفحات الدفع",
      icon: "actions.svg",
      subActions: [
        {
          name: "Gate Sales Report",
          routerLink: "/admin/reports/gate-sales",
        },
        {
          name: "Gate Credit Sales Report",
          routerLink: "/admin/reports/credit-gate-sales",
        },
        {
          name: "Gate Cash Sales Report",
          routerLink: "/admin/reports/cash-gate-sales",
        },
        {
          name: "Providers Reconciliation Report",
          routerLink: "/admin/reports/providers-reconciliations",
        },
        {
          name: "Providers Management",
          routerLink: "/admin/providers",
        },
        {
          name: "Merchant Management",
          routerLink: "/admin/merchants",
        },
        {
          name: "Fraud Detection",
          routerLink: "/admin/fraud/module",
        },
        {
          name: "bin providers",
          routerLink: "/admin/bin-providers",
        },
      ]
    },
    billPayments:{
      action:'Bill Payments',
      actionAr:'فواتير المدفوعات',
      icon:'actions.svg',
      subActions:[
        {
          name: "Category Managment ",
          routerLink: "/admin/bill-categories",
        },
        {
          name: "Provider Managment ",
          routerLink: "/admin/bill-providers",
        },
        {
          name: "Service Managment ",
          routerLink: "/admin/bill-services",
        },
        {
          name: "Limits Managment ",
          routerLink: "/admin/bill-limits",
        },
      ]
    },
    adminSettings: {
      action: "Admin Settings",
      actionAr: "إعدادات المسؤول",
      icon: "settings.svg",
      subActions: [
        {
          name: "Update Password",
          routerLink: "/admin/update-password",
          makePublicFor: "Admin"
        },
        {
          name: "Merchant Profile",
          routerLink: "/admin/merchants/profile",
          makePublicFor: "Merchant"
        }
      ]
    },
    billReports: {
      action: "Bill Reports",
      actionAr: "تقارير المدفوعات",
      icon: "financials.svg",
      subActions: [
        {
          name: "Transactions",
          routerLink: "/admin/reports/biller/temp-transactions",
        },
        {
          name : "Buyer Users" ,
          routerLink : "/admin/reports/biller/billingUsersReport"
        }
      ]
    }
  }
};
