<mat-form-field class="col-12" appearance="outline">
  <mat-label>{{ translateFieldName(fieldObject) }}</mat-label>
  <input type="tel" matInput class="form-control"
          [attr.disabled]="(productType === 5  && edit) ? true:null"
          [formControl]="formControlRef"
          [placeholder]="translateFieldName(fieldObject)"/>
  <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </mat-error>
</mat-form-field>
