import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-advertisement-dialog',
  templateUrl: './advertisement-dialog.component.html',
  styleUrls: ['./advertisement-dialog.component.scss']
})
export class AdvertisementDialogComponent implements OnInit {
  imgPath: string = "../../../../assets/images";
  check: boolean = false;
  lang

  constructor(private dialogRef: MatDialogRef<AdvertisementDialogComponent>,) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('language')
    this.setPath();
  }

  private setPath() {
     // true for mobile device
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      if(this.lang == 'en'){  this.imgPath += "/Website_banner_tall.png"}
      else this.imgPath += "/Website_Banner_tall_ARABIC.png"
      return;
    }
    if(this.lang == 'en') {this.imgPath += "/Website_banner.png"}
    else {this.imgPath += "/Website_Banner_arabic.png"}
  }

  checkCheckBoxvalue(_event: any){
      this.check = !this.check;
  }

  onNoClick(): void {
    localStorage.setItem("dont-show-advertisement", String(this.check))
    this.dialogRef.close();
  }
}
