import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root"
})

export class LanguageService {

  public languageUpdated = new EventEmitter<string>();

  constructor(private translate: TranslateService) {
   }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
    this.languageUpdated.emit(lang);
    localStorage.setItem('language', lang);
  }

}
