<div class="form-group mb-0">
    <label style="font-size :1.2rem"> {{ fieldObject.fieldName }}</label>
    <input
      type="search"
      class="form-control"
      [formControl]="formControlRef"
    />
    <p
      class="form-error form-error-hidden"
      [ngClass]="{
        'form-error-hidden':
          !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible':
          fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
      }"
    >
      {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
    </p>
  </div>
