<ng-container *ngFor="let group of fieldArray; let i = index">
  <fieldset *ngIf="fieldObject.fields" class="p-2">
    <legend>{{ fieldObject.fieldName }}</legend>
    <ng-container *ngFor="let field of fieldObject.fields; let j = index">
      <app-wrapper-field
        [fieldObject]="field"
        [fieldErrors]="fieldErrors"
        [formControlRef]="fieldArray[i].controls[j]"
      ></app-wrapper-field>
    </ng-container>
    <button mat-flat-button color="primary" (click)="addRow()">Add</button>
    <button mat-flat-button color="primary">Delete</button>
  </fieldset>
</ng-container>
