<div class="form-group mb-0" *ngIf="currenciesNames.length > 1">
  <label style="font-size :1.2rem">Viewing Currency</label>
  <span class="mandatory"></span>
  <select type="text" class="form-control" [formControl]="formControlRef">
    <option
      *ngFor="let currency of currenciesNames; let i = index"
      [value]="currency"
    >
      {{ currency }}</option
    >
  </select>
  <small
    class="form-error form-error-hidden"
    [ngClass]="{
      'form-error-hidden':
        !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
      'form-error-visible':
        fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
    }"
  >
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
