import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-no-label-select-list',
  templateUrl: './no-label-select-list.component.html',
  styleUrls: ['./no-label-select-list.component.scss']
})
export class NoLabelSelectListComponent extends BaseField implements OnInit {

  ngOnInit() {
    if (this.fieldObject.defaultValue) {
      this.formControlRef.setValue(this.fieldObject.defaultValue);
    }
  }

}

