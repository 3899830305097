import { Injectable } from '@angular/core';
import { BaseGetService } from './baseGetService.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalLoaderService } from './global-loader.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BaseLoggedInGetService extends BaseGetService {
  constructor(
    httpClient: HttpClient,
    gls: GlobalLoaderService,
    r: Router,
    auth: AuthService
  ) {
    super(httpClient, gls, r, auth);
  }
  _make(): Promise<any> {
    this._headers = new HttpHeaders({

      'x-url': this.router.url.split('?')[0],
    });
    return super._make();
  }
}
