import { Injectable } from '@angular/core';
import constantURL from '../../../shared/constantURL';
import { BasePostService } from '../../../shared/services/basePostService.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitBuyerInfoService extends BasePostService {
  _path = constantURL.product.extraInfo.submitBuyerInfo;

}
