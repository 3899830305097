import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { get } from 'scriptjs';
import { GetUpgCallbackStatus } from 'src/app/dashboard/seller/financials/services/get-upg-callback-status.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var Lightbox: any;

@Component({
  selector: 'app-light-box-upg',
  templateUrl: './light-box-upg.component.html',
  styleUrls: ['./light-box-upg.component.scss'],
})
export class LightBoxUpgComponent implements OnInit {
  orderId: string = '';
  paymentMethodFromLightBox: number = 2; // default payment method will be all
  amount: number = 0;
  mID: number = 10940538636;
  tID: number = 47778387;
  secureHash: string = '';
  trxDateTime: string = '';
  returnUrl: string = '/';
  MerchantReference: any;
  processOneTime: boolean = false;
  SystemReference: string | null;
  qrcode: string | null;

  constructor(
    private router: Router,
    private getUpgCallbackStatus: GetUpgCallbackStatus,
    private dialog: MatDialog,
  ) {
    this.SystemReference = null;
  }

  ngOnInit() {}
  /**
   * include upg script on component init
   * @param url
   */

  callLightBox() {
    this.dialog.open(QRCodePopup, { data: this.qrcode });
    // get(environment.UPG_LIGHTBOX, () => {
    //   Lightbox.Checkout.configure = {
    //     OrderId: '',
    //     paymentMethodFromLightBox: this.paymentMethodFromLightBox,
    //     MID: this.mID,
    //     TID: this.tID,
    //     SecureHash: this.secureHash,
    //     TrxDateTime: this.trxDateTime,
    //     AmountTrxn: this.amount,
    //     MerchantReference: this.MerchantReference,
    //     ReturnUrl: this.returnUrl,
    //     completeCallback: async (data: any) => {
    //       console.log("callLightBox data: ", data);
    //       this.SystemReference = data?.SystemReference;
    //       await this.redirectToGatePage(data);
    //     },
    //     cancelCallback: (_data: any) => {
    //       this.router.navigate(['/gate'], {
    //         queryParams: {
    //           refNum: this.SystemReference ? this.SystemReference : this.orderId,
    //         },
    //       });
    //     },
    //   };
    //   Lightbox.Checkout.showLightbox();
    // });
  }

  openPaySky() {
    get(environment.PaySky_LIGHTBOX, () => {
      Lightbox.Checkout.configure = {
        OrderId: '',
        MID: this.mID,
        TID: this.tID,
        SecureHash: this.secureHash,
        TrxDateTime: this.trxDateTime,
        AmountTrxn: this.amount,
        MerchantReference: this.MerchantReference,
        ReturnUrl: this.returnUrl,
        completeCallback: async (data: any) => {
          console.log("openPaySky data: ", data);
          this.SystemReference = data.SystemReference;
          await this.redirectToGatePage(data);
        },
        cancelCallback: () => {
          this.router.navigate(['/gate'], {
            queryParams: {
              refNum: this.SystemReference ? this.SystemReference : this.orderId,
            },
          });
        },
      };
      Lightbox.Checkout.showLightbox();
    });
  }

  validateUPGRequiredData(): boolean {
    const requiredProperties = [
      'tID',
      'paymentMethodFromLightBox',
      'mID',
      'amount',
      'trxDateTime',
      'MerchantReference',
    ];
    for (const requiredProperty of requiredProperties) {
      if (this[requiredProperty] == null) return false;
    }
    return true;
  }

  async redirectToGatePage(data: {
    MerchantReference: string | number;
    SystemReference: string | number;
    status: string;
  }) {
    const dataToSend = {
      merchantReference: data.MerchantReference,
      systemReference: data.SystemReference,
      status: data.status,
    };

    try {
      if (!data) throw new Error('completeCallback: returning data in empty');

      this.getUpgCallbackStatus.setParams(dataToSend);
      const pullUpgCallbackStatus = await this.getUpgCallbackStatus.make();
      if (!pullUpgCallbackStatus.success) throw new Error('Payment Failed');
    } catch (e) {
      console.log('completeCallback: ', e);
    } finally {
      this.router.navigate(['/gate'], {
        queryParams: {
          refNum: data.SystemReference ? data.SystemReference : this.orderId,
        },
      });
    }
  }
}

@Component({
  selector: 'app-qrcodepopup',
  templateUrl: 'qrcodepopup.component.html',
})
export class QRCodePopup {
  constructor(
    public dialogRef: MatDialogRef<QRCodePopup>,
    @Inject(MAT_DIALOG_DATA) public imgPath: string,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
