<!-- <div class="col-12"> -->
    <mat-form-field class="col-12">
        <mat-label style="padding: 2px 7px;
        margin-top: -13px;
        background-color: #fff;
        margin-left: 7px;">{{translateMutliSelectFieldName(fieldObject)}}</mat-label>
        <mat-select class="form-control" style="height: 46px;margin-top: -20px;" [formControl]="formControlRef" multiple>
            <mat-option *ngFor="let fieldValue of fieldObject.fieldValues" [value]="fieldValue.name">{{ fieldValue.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]">
            {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
        </mat-error>
    </mat-form-field>
    <!-- <small
        class="form-error form-error-hidden"
        [ngClass]="{
        'form-error-hidden':
            !fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
        'form-error-visible':
            fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]
        }"
    >
        {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
    </small> -->
<!-- </div> -->
