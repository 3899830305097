import { Injectable } from '@angular/core';
import constantURL from '../../../shared/constantURL';
import { BaseLoggedInPostService } from '../../../shared/services/base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentOptionsService extends BaseLoggedInPostService {
  _path = constantURL.PaymentOptions.getAll;
}
