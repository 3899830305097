<div class="form-group" *ngIf="!fieldObject.isStaticUI">
  <label>{{ fieldObject.fieldName }}</label>
  <select [formControl]="formControlRef" class="form-control">
    <option *ngFor="let option of arrayOfOptions" [value]="option">
      {{ option }}
    </option>
  </select>
  <small class="form-error form-error-hidden"
         [ngClass]="{'form-error-hidden':!fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
                   'form-error-visible':fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>

<div class="form-group" *ngIf="fieldObject.isStaticUI">
  <select [formControl]="formControlRef" class="form-control">
    <option *ngFor="let option of arrayOfOptions" [value]="option">
      {{ option }}
    </option>
  </select>
  <small class="form-error form-error-hidden"
         [ngClass]="{'form-error-hidden':!fieldErrors[fieldObject.fieldName] && !fieldErrors[fieldErrorName],
                   'form-error-visible':fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName]}">
    {{ fieldErrors[fieldObject.fieldName] || fieldErrors[fieldErrorName] }}
  </small>
</div>
