import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GetOrganizationService } from 'src/app/shared/services/get-organization';
import { Organization } from '../../Organization';
import { ConfarmPasswordDialogComponent } from '../../seller/organization/components/confarm-password.component';

@Component({
  selector: 'app-organization-pop',
  templateUrl: './organization-pop.component.html',
  styleUrls: ['./organization-pop.component.scss'],
})
export class OrganizationPop implements OnInit {
  @Input() currentBusinessName: string;
  public isOwner = false;
  public data: DisplayedData[] = [];

  constructor(
    public getOrganizationService: GetOrganizationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    const is_seller = await this.authService.isSellerOrisAdmin('Seller');
    if(is_seller){
      this.isOwner = await this.authService.isOwner();
      try {
        await this.initData();
      } catch (err) {
        console.log({ err });
      }
    }

  }

  public closePopup() {
    document.getElementById('org-popup')!.classList.remove('d-block');
  }

  public switchBassiness(event: { businessName: string; id: number }) {
    this.dialog.open(ConfarmPasswordDialogComponent, {
      data: { businessName: event.businessName, businessId: event.id },
    });
  }

  private async initData(): Promise<void> {
    const res = await this.getOrganizationService.make();
    if (res.success) {
      (res.data as Organization[]).map((e) => {
        if (!e.Business) {
          this.data.unshift({ businessName: 'Main Business', id: e.BusinessId });
          return;
        };
        this.data.push({ businessName: e.Business.name, id: e.BusinessId });
      });
    }
  }
}

type DisplayedData = { businessName: string; id: number };
