<section class="section bg-home bg-circle-gradiant d-flex align-items-center">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card login-page bg-white shadow rounded border-0">
          <div class="card-body">
        <ng-content></ng-content>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
