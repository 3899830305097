import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  @Input() url: string = '';
  @Input() btns: string[] = [];
  @Input() size: number = 0;
  @Input() showEmail: boolean = false;
  @Input() product: any;

  constructor() { }

  ngOnInit() {
    // tslint:disable
    if (!this.btns) {
      this.btns = ['facebook', 'twitter', 'linkedin', 'google', 'pinterest', 'reddit', 'tumblr', 'mix', 'vk', 'telegram', 'messenger', 'whatsapp', 'xing', 'line', 'sms', 'email', 'print', 'copy'];
    }
    // tslint:enable
    if (!this.size) {
      this.size = 4;
    }
  }

}
