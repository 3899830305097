import {  Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BaseField } from '../../baseField';
import { Validators } from '../../../validators';
import { CustomValidators } from '../../../custom-validators';
import { TicketsService } from './ticket.service';
import { LanguageService } from '../../../services/language.service';
import { FormValidatorService } from '../../../services/form-validator.service';
import { ActivatedRoute } from '@angular/router';
declare let Object: any;

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ["./ticket.component.scss"],
  styles: []
})
export class TicketComponent extends BaseField implements OnInit {
  public validationMsg = {
    required: "This field is required field",
    invalidEmail: "Enter valid email",
    empty: "This field cannot be empty",
    minLength: "This field cannot be less than ",
    maxLength: "This field cannot be more than ",
    pattern: "This field format is not correct",
    min: "This field cannot be less than ",
    max: "This field cannot be more than ",
    invalidDate: "Invalid Expiration Date",
    dateInTheFuture: "Invalid Date (Must be in the future)",
    notInPattern: "This field cannot be numbers only",
    requiredNumber: "This field must be number",
    textOnly: "This field must contain letters only",
    lessThanOtherField: "This field must be a number less than or equal ",
    moreThanOtherField: "This field must be a number more than ",
  }
  public validationMsgAr = {
    required: "مطلوب",
    invalidEmail: "أدخل بريدًا إلكترونيًا صالحًا",
    empty: "لا يمكن ان يكون فارغا",
    minLength: "لا يمكن أن يكون أقل من ",
    maxLength: "لا يمكن أن يكون أكثر من ",
    pattern: "تنسيق الـغير صحيح",
    min: "لا يمكن أن يكون أقل من ",
    max: "لا يمكن أن يكون أكثر من ",
    invalidDate: "تاريخ انتهاء صلاحية غير صالح",
    dateInTheFuture: "التاريخ غير صالح (يجب أن يكون في المستقبل)",
    notInPattern: "لا يمكن أن تكون أرقامًا فقط",
    requiredNumber: "يجب أن يكون رقمًا",
    textOnly: "يجب أن يحتوي على أحرف فقط",
    lessThanOtherField: "يجب أن يكون رقمًا أصغر من أو يساوي ",
    moreThanOtherField: "يجب أن يكون رقمًا أكبر من ",
  }
  @ViewChild(MatTable) table!: MatTable<any>;
  pendigQuantityDescription = 'Quantity booked but not yet paid. Returned if booking expires.'
  paidQuantityDescription = 'Quantity paid and confirmed.'
  QuantityDescription: any = [];
  formValidator!: FormValidatorService;
  formRef!: FormGroup;
  ticketsArray = [];
  noTicket!: boolean;
  ticketNameIsUnique = true;
  subscription!: Subscription;
  ticketsForm: any;
  ticketFormIdsToBeAdded = [];
  formValidatorArray: FormValidatorService[] = [];
  ticketFields = {
    id: {
      'fieldName': 'id',
      'fieldType': 'id'
    },
    name: {
      'fieldName': 'Ticket name',
      'fieldNameAr': 'إسم التذكرة',
      'fieldType': 'TicketName',
      validators: [
        {
          validatorFn: Validators.required,
          message: `${this.validationMsg.required}`,
          messageAr: `${this.validationMsgAr.required}`
        },
        {
          validatorFn: CustomValidators.notInPattern(/^[0-9]*$/),
          message: `${this.validationMsg.notInPattern}`,
          messageAr: `${this.validationMsgAr.notInPattern}`
        },
        {
          validatorFn: Validators.minLength(2),
          message: `${this.validationMsg.minLength}2 characters`,
          messageAr: `${this.validationMsgAr.minLength}حرفان`
        }
      ]
    },
    image: {
      fieldName: "Ticket Image",
      fieldNameAr: 'صورة التذكرة',
      fieldType: "ticket_image",
      isStaticUI: true,
      defaultValue: "",
      validators: [],
    },
    price: {
      'fieldName': 'Price',
      'fieldNameAr': 'السعر',
      'fieldType': 'Price',
      validators: [
        {
          validatorFn: Validators.required,
          message: `${this.validationMsg.required}`,
          messageAr: `${this.validationMsgAr.required}`
        },
        {
          validatorFn: CustomValidators.customPattern(/^(([0-9]{1,6})+([.][0-9]*)?|[.][0-9]+)$/),
          message: `${this.validationMsg.requiredNumber} and ${this.validationMsg.maxLength}6 characters`,
          messageAr: `${this.validationMsgAr.requiredNumber} و ${this.validationMsgAr.maxLength}6 أحرف`
        },
        {
          validatorFn: Validators.min(1),
          message: `${this.validationMsg.min}1`,
          messageAr: `${this.validationMsgAr.min}الـ1`
        }
      ]
    },
    available_quantity: {
      'fieldName': 'original Quantity',
      'fieldNameAr': 'العدد الأصلى',
      'fieldType': 'original Quantity',
      'isStaticUI': true,
      defaultValue: '',
      validators: []
    },
    available_Dbquantity: {
      'fieldName': 'Available Db Quantity',
      'fieldNameAr': 'العدد المتاح',
      'fieldType': 'original Quantity',
      'isStaticUI': true,
      defaultValue: '',
      validators: []
    },
    quantity: {
      'fieldName': 'Total Quantity',
      'fieldNameAr': 'العدد الكلي',
      'fieldType': 'Available Quantity',
      validators: [
        {
          validatorFn: Validators.required,
          message: `${this.validationMsg.required}`,
          messageAr: `${this.validationMsgAr.required}`
        },
        {
          validatorFn: CustomValidators.customPattern(/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/),
          message: `${this.validationMsg.requiredNumber}`,
          messageAr: `${this.validationMsgAr.requiredNumber}`
        },
        {
          validatorFn: CustomValidators.MoreThanOtherField('original Quantity'),
          message: `${this.validationMsg.moreThanOtherField}pending quantity.`,
          messageAr: `${this.validationMsgAr.moreThanOtherField}الكمية المعلقة.`
        },
        {
          validatorFn: Validators.min(1),
          message: `${this.validationMsg.min}1`,
          messageAr: `${this.validationMsgAr.min}الـ1`
        }
      ]
    },
    pending_quantity: {
      'fieldName': 'Pending Quantity',
      'fieldNameAr': 'الكمية المعلقة',
      'fieldType': 'Pending Quantity',
      'isStaticUI': true,
      defaultValue: '',
      validators: []

    },
    paid_quantity: {
      'fieldName': 'Paid Quantity',
      'fieldNameAr': 'الكمية المدفوعة',
      'fieldType': 'Paid Quantity',
      'isStaticUI': true,
      defaultValue: '',
      validators: []

    },
    maxQuantity: {
      'fieldName': 'Max Quantity Per Sale',
      'fieldNameAr': 'أقصى كمية لكل عملية بيع',
      'fieldType': 'Max Quantity Per Sale',
      'isStaticUI': true,
      defaultValue: null,
      validators: [
        {
          validatorFn: CustomValidators.lessThanOtherField('Total Quantity'),
          message: `${this.validationMsg.lessThanOtherField}available quantity.`,
          messageAr: `${this.validationMsgAr.lessThanOtherField}الكمية المتوفرة.`
        }
      ]
    },
    description: {
      'fieldName': 'Ticket Description',
      'fieldNameAr': 'وصف التذكرة',
      'fieldType': 'Ticket Description',
      'isStaticUI': true,
      rows: 1,
      defaultValue: '',
      validators: []
    },
    expiry: {
      'fieldName': 'Ticket expiry',
      'fieldNameAr': 'إنتهاء صلاحية التذكرة',
      'fieldType': 'Ticket expiry',
      'isStaticUI': true,
      defaultValue: '',
      validators: []
    }
  };

  constructor(
    private tickets: TicketsService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private activatedRoute:ActivatedRoute
  ) {
    super();
  }
  async ngOnInit() {
    this.ticketsForm = this.formBuilder.group({
      tickets: this.formBuilder.array([])
    });
    this.activatedRoute.params.subscribe(async (params) => {
      if(params.id){
        this.isUpdate=true;
      }
    });
    if(this.formTicketControls.length ==0 && !this.isUpdate){
      this.addTicketFormGroup();
    }
    this.ticketsForm.get('tickets').valueChanges.subscribe((tickets: any) => {
      this.ticketNameIsUnique = true;
      let counter = 0;
      let invalidFormArray = false;
      for (const ticket of tickets) {
        if (ticket['id'] && ticket['id'] !== null) {
          const quantityIndex = this.QuantityDescription.findIndex((desc: any) => desc.id === ticket['id']);
          const quantityMessage = 'Available quantity = ';
          if (quantityIndex === -1) {
            this.QuantityDescription.push({ id: ticket['id'], quantity: quantityMessage + ticket['Available Db Quantity'] });
          } else {
            this.QuantityDescription[quantityIndex] = { id: ticket['id'], quantity: quantityMessage + ticket['Available Db Quantity'] };
          }
        }
        let ticketNames = tickets.map(function (ticket: any) { return ticket['Ticket name'] });
        let index = 0;
        var isDuplicate = ticketNames.some(function (item: any, idx: any) {
          let check = ticketNames.indexOf(item) != idx;
          index++;
          return check;
        });
        if (isDuplicate) {
          this.ticketNameIsUnique = false;
          this.formValidatorArray[counter].addError('Ticket name', 'invalid', 'Ticket name already exists');
          let formRefControls = this.ticketsForm.get('tickets').controls[index - 1].controls;
          formRefControls['Ticket name'].setErrors({ 'invalid': 'Ticket name already exists' });
          this.formValidatorArray[counter].forceShowErrors();
        }
        let formGroup = this.ticketsForm.get('tickets').controls[counter];
        if (formGroup.errors || formGroup.invalid) {
          invalidFormArray = true;
          this.formValidatorArray[counter].forceShowErrors();
        }
        else {
          if (this.ticketNameIsUnique) {


            this.ticketsArray = (JSON.parse(JSON.stringify(tickets)));
            if (this.ticketsArray[0]) {

              this.noTicket = false;
              this.formControlRef.setValue(this.ticketsArray);
            } else {

              this.noTicket = true;
              this.formControlRef.setValue(null);
            }
          }
        }

        this.formValidatorArray[counter].markFormGroupTouched(this.ticketsForm.get('tickets').controls[counter]);
        counter++;

        formGroup.controls['Total Quantity'].valueChanges.subscribe((value: any) => {
          let maxQuantity = formGroup.controls['Max Quantity Per Sale'].value;

          if (+maxQuantity && +maxQuantity > +value) {
            formGroup.controls['Max Quantity Per Sale'].setValue(value);
          }
        });
        formGroup.controls['Max Quantity Per Sale'].valueChanges.subscribe((value: any) => {
          if (value === '') {
            formGroup.controls['Max Quantity Per Sale'].setValue(null);
          }
        });
      }
      if (invalidFormArray) {
        this.formControlRef.setValue(null);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async populateTicket(object?: any) {
    let hasNoTickets = this.ticketsArray.length < 1 ? true : false;
    this.tickets.addTicket(hasNoTickets);
    if (object) {
      let formGroup = this.addTicketFormGroup();
      formGroup.setValue(object);
    }
  }

  async populateAllTickets(arrayOfTickets: any) {
    if (Array.isArray(arrayOfTickets) && arrayOfTickets.length > 0) {
      this.formControlRef.setValue(arrayOfTickets);
    }
  }


  deleteTicket(index: number) {
    let formGroups = this.ticketsForm.get('tickets') as FormArray;
    if (formGroups.length > 1) {
      formGroups.removeAt(index);
      this.formValidatorArray.splice(index, 1);
    }
  }
  get formTicketControls(){
    return this.ticketsForm.get('tickets').controls as FormArray;
  }
  private createTicketFormGroup(): FormGroup {
    this.formValidatorArray.push(
      new FormValidatorService()
    )
    return this.formValidatorArray[this.formValidatorArray.length - 1].createForm(
      Object.values(this.ticketFields)
    );
  }

  public addTicketFormGroup(formGroup?: any): FormGroup {
    const tickets = this.ticketsForm.get('tickets') as FormArray
    let formGroupObject = this.createTicketFormGroup();
    tickets.push(formGroupObject);
    return formGroupObject;
  }

  get ticketsItem() {
    return this.ticketsForm.get('tickets') as FormArray;
  }

  convertTicketControls(index: number, name: string) {
    return this.ticketsItem.controls[index].get(name) as FormControl;
  }
}
