import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styles: []
})
export class FeesComponent extends BaseField implements OnInit {

  ngOnInit() {
    this.formControlRef.setValue(0);
  }

}
