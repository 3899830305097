import { Component, OnInit } from '@angular/core';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-no-label-toggle',
  templateUrl: './no-label-toggle.component.html',
  styleUrls: ['./no-label-toggle.component.scss']
})
export class NoLabelToggleComponent extends BaseField implements OnInit {
  ngOnInit() {
    if (!this.formControlRef.value) {
      this.formControlRef.setValue(false);
    }

    if (this.formControlRef.value === '1')
      this.formControlRef.setValue(true);

    if (this.formControlRef.value === '0')
      this.formControlRef.setValue(false);

    this.formControlRef.valueChanges.subscribe(v => {
      if (v === '1')
        this.formControlRef.setValue(true);

      if (v === '0')
        this.formControlRef.setValue(false);
    })
  }
}
