import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { LanguageService } from '../../../services/language.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-date-now',
  templateUrl: './date-now.component.html',
  styleUrls: ['./date-now.component.scss']
})
export class DateNowComponent extends BaseField implements OnInit {
  @Input() maxDate=null;
  @Input() hasMinAsTodayDate: boolean = true;

  Datenow = moment.utc();
  minDate= this.Datenow.format('YYYY-MM-DD');
  DateName: string = '';


  constructor(
    public translate: TranslateService,
    private languageService: LanguageService
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem("language") === "ar") {
      this.DateName = this.fieldObject.fieldNameAr;
    } else {
      this.DateName = this.fieldObject.displayName || this.fieldObject.fieldName;
    }
    this.languageService.languageUpdated.subscribe((lang: string) => {
      if (lang === "ar") {
        this.DateName = this.fieldObject.fieldNameAr;
      } else if (lang === "en") {
        this.DateName =this.fieldObject.displayName || this.fieldObject.fieldName;
      }
    });
  }

  updateDate(event: any) {
    if(!event.value) return;
    const d = new Date(event.value);
    const m = moment(d).add(-d.getTimezoneOffset(), 'm').toISOString();
    this.formControlRef.setValue(m);
  }
}
