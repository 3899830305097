import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ToggleLayoutService {
  toggleHeaderListener = new Subject<boolean>();
  toggleSidenavListener = new Subject<boolean>();
  toggleFooterListener = new Subject<boolean>();

  toggleHeader(showHeader: boolean) {
    this.toggleHeaderListener.next(showHeader);
  }

  getToggleHeaderListener() {
    return this.toggleHeaderListener.asObservable();
  }

  toggleSideNav(showSidenav: any) {
    this.toggleSidenavListener.next(showSidenav);
  }

  getToggleSidenavListener() {
    return this.toggleSidenavListener.asObservable();
  }

  toggleFooter(showFooter: boolean) {
    this.toggleFooterListener.next(showFooter);
  }
  getToggleFooterListener() {
    return this.toggleFooterListener.asObservable();
  }
}
