import { ProductNameComponent } from '../components/product-name/product-name.component';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { PaymentOptionsComponent } from '../components/payment-options/payment-options.component';
import { NumberComponent } from '../components/number/number.component';
import { TextComponent } from '../components/text/text.component';
import { NoLabelTextAreaComponent } from '../components/no-label-text-area/no-label-text-area.component';
import { QuickCashExpirydateComponent } from '../components/quick-cash-expirydate/quick-cash-expirydate.component';
import { QassatlyComponent } from '../components/qassatly/qassatly.component';
import { PaymentPlansComponent } from '../components/payment-plans/payment-plans.component';
import { DateNowComponent } from '../components/date-now/date-now.component';
import { TextAreaComponent } from '../components/text-area/text-area.component';
import { NoLabelToggleComponent } from '../components/no-label-toggle/no-label-toggle.component';
import { NoLabelSelectListComponent } from '../components/no-label-select-list/no-label-select-list.component';
import { TaxesComponent } from '../components/taxes/taxes.component';
import { TaxesTypeComponent } from '../components/taxes-type/taxes-type.component';
import { TicketComponent } from '../components/ticket/ticket.component';
import { ImageComponent } from '../components/image/image.component';
import { ProductCategoriesComponent } from '../components/product-categories/product-categories.component';
import { DatetimeRangeComponent } from '../components/datetime-range/datetime-range.component';
import { OpenMapComponent } from '../components/open-map/open-map.component';
import { SelectListComponent } from '../components/select-list/select-list.component';
import { ProductPriceComponent } from '../components/product-price/product-price.component';
import { HiddenComponent } from '../components/hidden/hidden.component';
import { TicketImageComponent } from '../components/ticket-image/ticket-image.component';
import { EmailComponent } from '../components/email/email.component';
import { TelComponent } from '../components/tel/tel.component';
import { CheckBoxComponent } from '../components/check-box/check-box.component';
import { RangeComponent } from '../components/range/range.component';
import { FileComponent } from '../components/file/file.component';
import { SearchComponent } from '../components/search/search.component';
import { WeekComponent } from '../components/week/week.component';
import { TimeComponent } from '../components/time/time.component';
import { DateTimeComponent } from '../components/date-time/date-time.component';
import { FieldGroupComponent } from '../components/field-group/field-group.component';
import { RadioComponent } from '../components/radio/radio.component';
import { PasswordInputComponent } from '../components/password/password.component';
import { UrlComponent } from '../components/url/url.component';
import { DatetimeLocalComponent } from '../components/datetime-local/datetime-local.component';
import { MonthComponent } from '../components/month/month.component';
import { CurrencyComponent } from '../components/currency/currency.component';
import { InvoiceNumberComponent } from '../components/inovice-number/inovice-number.component';
import { FeesComponent } from '../components/fees/fees.component';
import { FormBuilderComponent } from '../components/form-builder/form-builder.component';
import { MultiSelectComponent } from '../components/multi-select/multi-select.component';
import { FileUploadComponent } from '../components/file-upload/file-upload-component';
import { PhoneComponent } from '../components/phone/phone.component';

export class FieldsRegistery {
  'textarea' = TextAreaComponent;
  'text' = TextComponent;
  'email' = EmailComponent;
  'tel' = TelComponent;
  'toggle' = ToggleComponent;
  'checkbox' = CheckBoxComponent;
  'range' = RangeComponent;
  'file' = FileComponent;
  'hidden' = HiddenComponent;
  'number' = NumberComponent;
  'productQuantity' = NumberComponent;
  'search' = SearchComponent;
  'week' = WeekComponent;
  'time' = TimeComponent;
  'datetime' = DateTimeComponent;
  'fieldGroup' = FieldGroupComponent;
  'radio' = RadioComponent;
  'password' = PasswordInputComponent;
  'url' = UrlComponent;
  'selectList' = SelectListComponent;
  'dropDown' = SelectListComponent;
  'dateTimeLocal' = DatetimeLocalComponent;
  'image' = ImageComponent;
  'month' = MonthComponent;
  'payment_option' = PaymentOptionsComponent;
  'mapInput' = OpenMapComponent;
  'physical category' = ProductCategoriesComponent;
  'ticket' = TicketComponent;
  'dateNow' = DateNowComponent;
  'currency' = CurrencyComponent;
  'cashExpiryDate' = QuickCashExpirydateComponent;
  'Taxes' = TaxesComponent;
  'type' = TaxesTypeComponent;
  'invoiceNumber' = InvoiceNumberComponent;
  'productName' = ProductNameComponent;
  'productPrice' = ProductPriceComponent;
  'fees' = FeesComponent;
  'formBuilder' = FormBuilderComponent;
  'datetimeRange' = DatetimeRangeComponent;
  'published' = ToggleComponent;
  'expiryDate' = DateNowComponent;
  'multiSelect' = MultiSelectComponent;
  'checkBoxes' = MultiSelectComponent;
  'qassatly' = QassatlyComponent;
  'paymentPlan' = PaymentPlansComponent;
  'upload' = FileUploadComponent;
  'no-label-toggle' = NoLabelToggleComponent;
  'no-label-text-area' = NoLabelTextAreaComponent;
  'no-label-select-list' = NoLabelSelectListComponent;
  'ticket-image' = TicketImageComponent;
  'phone'=PhoneComponent;
}
