import { Injectable } from '@angular/core';
import constantURL from 'src/app/shared/constantURL';
import { BaseLoggedInPostService } from 'src/app/shared/services/base-logged-in-post.service';

@Injectable({
  providedIn: 'root'
})
export class PostBasicStepOneTemplateService extends BaseLoggedInPostService {
  _path = constantURL.product.add.stepOneTemplate;
}
