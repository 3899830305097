<app-header></app-header>

<div class="main-container">
  <app-public-rounded-container>
    <p class="text-danger" *ngIf="sendFailed">
      {{ "Verification.sendFailed" | translate }}
    </p>

    <div class="card-title text-center">
      {{ "Verification.Title" | translate }}
    </div>
    <div class="row align-items-center card-body mx-2">
      <form *ngIf="codeForm" [formGroup]="codeForm" class="login-form">
        <div class="col-md-12">
          <input type="text" formControlName="phone" class="form-control" />
          <span style="color: red" *ngIf="formValidator.formErrors.phone ">
            {{ formValidator.formErrors.phone }}
          </span>
        </div>
        <button class="col-12 btn btn-success my-2" (click)="changeMobile()">
          {{ "Seller.Products.changeMobile" | translate }}</button>
        <div class="col-md-12">
          <label class="form-label mb-2"> {{ "Verification.Code" | translate }}</label>
          <input type="text" formControlName="code" class="form-control" />
          <span style="color: red" *ngIf="formValidator.formErrors.code || invaild == true">
            {{ "Verification.InvalidCode" | translate }}

          </span>
        </div>
        <button class="col-12 btn btn-primary" [disabled]="!codeForm.valid" (click)="VerifyPhone()">
          {{ "Seller.Products.Verify" | translate }}
        </button>
        <button class="col-12 btn btn-success" [disabled]="sendCodeClicked" (click)="reSend()">{{
          "Seller.Products.Resend" | translate }}
          <ng-container *ngIf="sendCodeClicked">
            ({{ timer | async }})
          </ng-container>
        </button>
      </form>
    </div>
  </app-public-rounded-container>
</div>
