import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UpdateUserInfoService } from 'src/app/shared/components/change-password/update-user-info.service';
import { PhoneVerifyComponent } from 'src/app/shared/components/phone-verify/phone-verify.component';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomToastService } from 'src/app/shared/services/custom-toast.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { handleMobileVerificationService } from 'src/app/shared/services/handleMobileVerification.service';
import { Validators } from 'src/app/shared/validators';
import { FetchBasicUserInfoService } from '../seller/my-account/services/fetch-basic-user-info.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  phoneVerifyClicked: boolean = false;
  sendCodeClicked: boolean = false;
  timer: any;
  codeForm: FormGroup;
  user;
  userPhone;
  sendFailed = null;
  invalid = false;
  @ViewChild(PhoneVerifyComponent) phoneVerifyComponent: PhoneVerifyComponent;

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private formValidator: FormValidatorService,
    private translate: TranslateService,
    private handleMobileVerification: handleMobileVerificationService,
    private fetchInfo: FetchBasicUserInfoService,
    private updateUser: UpdateUserInfoService,
    private customToast:CustomToastService,

  ) {}

  async ngOnInit() {
    this.user = await this.fetchInfo.make();
    if (this.user.phone_verified) {
      let url = await this.auth.dashboardUrl();
      this.router.navigate([url]);
    } else {
      await this.handleMobileVerification.sendCode();
     
      this.userPhone=this.user.phone;
      this.codeForm = this.formValidator.createForm([
        {
          fieldName: 'phone',
          defaultValue: this.userPhone,
          validators: [
            {
              validatorFn: Validators.required,
              message: ''
            },
            {
              validatorFn: CustomValidators.customPattern(/(^[0-9\+]{3,}$)/),
              message: ''
            }
          ]
        },
        {
          fieldName: 'code',
          defaultValue: null,
          validators: [
            {
              validatorFn: Validators.required,
              message: '',
            },
            {
              validatorFn: Validators.pattern('^[0-9]+$'),
              message: '',
            },
          ],
        },
      ]);
      this.translateValidationMessages();
      this.translate.onLangChange.subscribe(() => {
        this.translateValidationMessages();
      });
    }
  }
  async reSend() {
    try {
      this.sendCodeClicked = true;
      await this.handleMobileVerification.sendCode();
      let count = 30;
      this.timer = timer(0, 1000).pipe(
        take(count),
        map(() => --count),
        tap((res) => {
          if (res == 0) this.sendCodeClicked = false;
        })
      );
    } catch (err) {
      console.log(err);
      this.sendFailed = err.message;
    }
  }
  async changeMobile(){
    try{
      if (this.userPhone == this.codeForm.get('phone').value) return;
      this.updateUser.setParams({ email: this.user.email, phone: this.codeForm.get('phone').value })
      await this.updateUser.make();
      this.userPhone=this.codeForm.get('phone').value;
      await this.reSend();
    }catch(err){
      const errObj=err.faultyRequest.error.error;
      this.formValidator.formErrors[errObj.field]=errObj.message;
      console.log(err);
    }
  }
  async VerifyPhone() {
    try {
      if (!this.codeForm.valid) {
        this.formValidator.forceShowErrors();
        return;
      }
      const result = await this.handleMobileVerification.verifyCode(
        this.codeForm.value.code
      );
      await this.reRoute(result.status);
    } catch (err) {
      this.invalid = true;
      this.showCodeFailedAlert();
    }
  }
  private async reRoute(result: boolean) {
    if (result) {
      this.auth.setPhoneverified(result);
      const url = await this.auth.dashboardUrl();
      this.router.navigate([url]);
    } else {
      this.invalid = true;
      this.showCodeFailedAlert();
    }
  }

  private showCodeFailedAlert(){
    this.translate.get("Verification.InvalidCode").subscribe(title=>
      this.customToast.addFailedToast(title)
      );
  }

  translateValidationMessages() {
    this.translate.get('FormValidation').subscribe(() => {
      this.formValidator.validationMessages = {
        code: {
          required: this.translate.instant('FormValidation.Empty', {
            field: 'Verification code',
            fieldAr: 'كود تفعيل',
          }),
          pattern: this.translate.instant('FormValidation.RequiredNumber', {
            field: 'Verification code',
            fieldAr: 'كود تفعيل',
          }),
        },
        "phone": {
          required: this.translate.instant('FormValidation.Empty', {
            field: 'Phone Number',
            fieldAr: 'رقم هاتف'
          }),
          requiredNumber: this.translate.instant('FormValidation.RequiredNumber'),
        },
      };
    });
  }
}
