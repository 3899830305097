import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent extends BaseField implements OnInit {
  isBuyerinfo: boolean = false;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    if (!this.formControlRef.value) {
      this.formControlRef.setValue(false);
    }
    this.isBuyerinfo = this.router.url.indexOf('buyerinfo') > -1;
  }

  translateToggleFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
