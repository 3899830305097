import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { BaseField } from '../../baseField';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ["./text.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TextComponent extends BaseField implements OnInit {
  isBuyerinfo: boolean = false;
  edit: boolean = false;
  mandatoryTexts: string[] = ['Ticket name', 'Price', 'Item Name', 'Product price'];
  mandatory: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private mandatoryFieldsService: MandatoryFieldsService,
    private router: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.isBuyerinfo = this.router.url.indexOf('buyerinfo') > -1;
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryTexts, this.fieldObject.fieldName) || false;
    await this.activatedRoute.params.subscribe(async a => {
      if (a['id']) {
        console.log(a['id']);
        this.edit = true;
      }
    });
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      if (fieldObject.fieldNameAr) {
        return fieldObject.fieldNameAr;
      } else {
        return fieldObject.displayName || fieldObject.fieldName;
      }
    } else {
      return fieldObject.displayName || fieldObject.fieldName;
    }
  }
}
